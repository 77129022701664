import React from 'react'
import {
  IAboutCompanyPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const AboutCompanyPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'about_index'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IAboutCompanyPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IAboutCompanyPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader title={'О компании'} extra={[<SiteLink key={1} entityType={'o-kompanii'} />]} />
      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Блок директора</Typography.Title>
            <ImageWithFallback src={data?.data?.director_avatar} height={75} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'director_avatar'}
              label={'Аватар директора'}
              initialPath={getStaticPageImagePath(data?.data?.director_avatar)}
            />
            <Form.Item
              label={'Фамилия, имя директора'}
              name={'director_name'}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={'Цитата'} name={'director_quote'} rules={[{ required: true }]}>
              <TextEditor />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>О нас в цифрах</Typography.Title>
            <Form.List name={'numbers'}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map(({ name, fieldKey, key }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['numbers', name, 'image']}
                        label={'Изображение'}
                        initialPath={getStaticPageImagePath(data?.data?.numbers[name].image)}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Заголовок'}
                        name={[name, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Подзаголовок'}
                        name={[name, 'subtitle']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))}

                  <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                    Добавить блок
                  </Button>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Наша история</Typography.Title>
            <ImageWithFallback src={data?.data?.history_mobile_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'history_mobile_image'}
              label={'Изображение (смартфоны)'}
              initialPath={getStaticPageImagePath(data?.data?.history_mobile_image)}
            />
            <ImageWithFallback src={data?.data?.history_tablet_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'history_tablet_image'}
              label={'Изображение (планшеты)'}
              initialPath={getStaticPageImagePath(data?.data?.history_tablet_image)}
            />
            <ImageWithFallback src={data?.data?.history_pc_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'history_pc_image'}
              label={'Изображение (ПК)'}
              initialPath={getStaticPageImagePath(data?.data?.history_pc_image)}
            />
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>География продаж</Typography.Title>
            <Form.Item name={'geography_text'} label={'Текст'}>
              <TextEditor />
            </Form.Item>
            <ImageWithFallback src={data?.data?.geography_image} preview height={75} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'geography_image'}
              label={'Изображение'}
              initialPath={getStaticPageImagePath(data?.data?.geography_image)}
            />
          </Col>
          <Col xs={24}>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default AboutCompanyPage
