import React from 'react'
import { DatePicker, Form } from 'antd'

interface props {
  name?: string | any[]
  label?: string
  required?: boolean
}

const FormDatePeriod: React.FC<props> = ({
  name = 'date',
  label = 'Период активности',
  required = false,
}) => {
  const style = { width: '100%' }
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <DatePicker.RangePicker allowClear={false} format={'DD-MM-YYYY'} style={style} />
    </Form.Item>
  )
}

export default FormDatePeriod
