import React from 'react'
import { usePromotions } from '@app/hooks/query/promotions'
import { PageHeader, Skeleton } from 'antd'
import { AddLink, ErrorBoundary, PageInfo, SiteLink, SpaceWithDivider } from '@app/shared'
import { PromotionsTable } from '@app/pages/promotions-page/components'

interface props {}

const PromotionsPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = usePromotions()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Промо акции'} />
      <PageHeader
        title={'Промо акции'}
        extra={[
          <SpaceWithDivider key={'1'}>
            <SiteLink entityType={'promotions'} />
            <AddLink route={'add_promotion'} />
          </SpaceWithDivider>,
        ]}
      />
      <PromotionsTable promotions={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default PromotionsPage
