import React from 'react'
import { useParams } from 'react-router-dom'
import { useProduct } from '@app/hooks/query/products'
import {
  BrandLink,
  CategoryList,
  ErrorBoundary,
  ImagesGrid,
  PageInfo,
  PromotagsList,
  SecondaryText,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  StatusTag,
  Subtitle,
} from '@app/shared'
import { Card, Col, Divider, Row, Skeleton, Space, Typography } from 'antd'
import {
  ProductAttributes,
  ProductParams,
  ProductPrices,
  ProductPromo,
  ProductProperties,
  ProductVariants,
} from '@app/pages/product-page/components'
import FormSelectVideoLayout from '@app/shared/form-select-video-layout/form-select-video-layout'

const { Title } = Typography

interface props {}

const ProductPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data: response, isLoading, isError } = useProduct(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const {
    title,
    active,
    slug,
    params,
    prices,
    brand,
    image_path,
    images_links,
    categories,
    sku,
    unit,
    promotags,
    page,
    promo_contents,
    attributes,
    stock,
    properties,
    video_layout,
  } = response!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={18}>
          <Card
            title={'Общая информация'}
            extra={[
              <Space key={1}>
                <StatusTag status={active} />
                <SiteLink slug={slug} entityType={'product'} />
              </Space>,
            ]}
          >
            <ImagesGrid imagesLinks={images_links} image={image_path} />
            <Title level={2}>{title}</Title>
            <SpaceWithDivider>
              <SecondaryText>ID: {id}</SecondaryText>
              <SecondaryText>Slug: {slug}</SecondaryText>
              {brand && <BrandLink brandName={brand.title} brandId={brand.id} withTitle={true} />}
              <SecondaryText>Единица измерения: {unit?.title}</SecondaryText>
              <SecondaryText>Код: {sku}</SecondaryText>
              <SecondaryText>В наличии: {stock?.stock}</SecondaryText>
            </SpaceWithDivider>
            <Divider />
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <ProductParams params={params} />
              </Col>
              <Col xs={24} lg={12}>
                <ProductPrices prices={prices} />
              </Col>
              <Col xs={24} lg={12}>
                <PromotagsList promotags={promotags} />
              </Col>
              <Col xs={24} lg={12}>
                <ProductAttributes attributes={attributes} />
              </Col>
              <Col xs={24}>
                <ProductProperties attributes={attributes} properties={properties} />
              </Col>
            </Row>
            <Divider />
            <Subtitle>Промо контент</Subtitle>
            <ProductPromo promo_contents={promo_contents} />
          </Card>
        </Col>
        <Col xs={24} xl={6}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
            </Col>
            <Col xs={24}>
              <CategoryList categories={categories} title={'Категории'} />
            </Col>
            <Col xs={24}>
              <Typography.Title level={4}>Макет видеоблока</Typography.Title>
              <FormSelectVideoLayout productId={id} defaultVideoLayout={video_layout} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <ProductVariants />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProductPage
