import React, { useContext, useState } from 'react'
import { Dropdown, Form, Image, Input, Menu, message } from 'antd'
import styles from './file-thumb.module.scss'
import { CheckCircleTwoTone, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import placeholder from '@assets/images/SM-placeholder-1024x512.jpg'
import { useMutation } from 'react-query'
import { FileManagerService } from '@app/api'
import { queryClient } from '@app/index'
import { FILE_MANAGER_KEY } from '@app/hooks/query/file-manager'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {
  file: string
}

const FileThumb: React.FC<props> = ({ file }) => {
  const {
    path,
    selectedFile,
    handleSelectFile: selectFile,
    params,
  } = useContext(FileManagerContext)
  const [defaultFileName] = file.match(/([^/]+$)/) || []
  const [editName, setEditName] = useState(false)
  const handleChangeEditName = () => setEditName(!editName)
  const handleSelectFile = () => selectFile(defaultFileName)

  const renameFile = useMutation(FileManagerService.renameFile, {
    onSuccess: () => {
      message.success('Файл успешно переименован')
      queryClient.invalidateQueries([FILE_MANAGER_KEY, { path, params }])
      handleChangeEditName()
    },
    onError: () => {
      message.error('Ошибка!')
    },
  })

  const deleteFile = useMutation(FileManagerService.deleteFile, {
    onSuccess: () => {
      message.success('Файл успешно удален')
      queryClient.invalidateQueries([FILE_MANAGER_KEY, { path, params }])
    },
    onError: () => {
      message.error('Ошибка удаления файла')
    },
  })

  const handleRename = (data: { newFilePath: string }) =>
    renameFile.mutateAsync({
      filePath: path + '/' + defaultFileName,
      newFilePath: path + '/' + data.newFilePath,
    })

  const handleDelete = () =>
    deleteFile.mutateAsync({
      filePath: path + '/' + defaultFileName,
    })

  const dropdownMenu = (
    <Menu>
      <Menu.Item key={'1'} onClick={handleChangeEditName} icon={<EditOutlined />}>
        Переименовать
      </Menu.Item>
      <Menu.Divider key={'divider'} />
      <Menu.Item
        key={'2'}
        danger
        icon={<DeleteOutlined />}
        disabled={deleteFile.isLoading}
        onClick={handleDelete}
      >
        Удалить
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={dropdownMenu} trigger={['contextMenu']} arrow placement={'bottomCenter'}>
      <div
        className={[styles.thumb, defaultFileName === selectedFile ? styles.active : null].join(
          ' ',
        )}
        onDoubleClick={handleSelectFile}
      >
        <CheckCircleTwoTone className={styles.check} onClick={handleSelectFile} />
        <div className={styles.image}>
          <Image src={file} preview={false} height={75} fallback={placeholder} />
        </div>

        <Form onFinish={handleRename}>
          <Form.Item noStyle initialValue={defaultFileName} name={'newFilePath'}>
            <Input
              bordered={editName}
              disabled={!editName || renameFile.isLoading}
              autoFocus
              size={'small'}
              suffix={editName && <CloseOutlined onClick={handleChangeEditName} />}
            />
          </Form.Item>
        </Form>
      </div>
    </Dropdown>
  )
}

export default React.memo(FileThumb)
