import React from 'react'
import { useParams } from 'react-router-dom'
import { PROMOTAGS_KEY, usePromotag } from '@app/hooks/query/promotags'
import { Card, Col, Divider, Row, Skeleton, Tag, Typography } from 'antd'
import {
  AttachPromotagDrawer,
  CategoryList,
  ErrorBoundary,
  ImageWithFallback,
  PageInfo,
  SecondaryText,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  Subtitle,
} from '@app/shared'
import { PromotagProductsTable } from '@app/pages/promotag-page/components'

const { Title } = Typography

interface props {}

const PromotagPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = usePromotag(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { title, color, slug, page, categories, image_url, image } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={18}>
          <Card
            title={'Общая информация'}
            extra={
              <SpaceWithDivider>
                <SiteLink entityType={'promotions/' + slug} />
                <AttachPromotagDrawer
                  initialPath={image?.path}
                  invalidateQueryKey={[PROMOTAGS_KEY, id]}
                  id={id}
                />
              </SpaceWithDivider>
            }
          >
            <Title level={2}>{title}</Title>
            <SpaceWithDivider>
              <SecondaryText>ID: {id}</SecondaryText>
              <SecondaryText>Slug: {slug}</SecondaryText>
              <SecondaryText>
                Цвет: <Tag color={color || 'default'}>{color ? color : 'Не указан'}</Tag>
              </SecondaryText>
            </SpaceWithDivider>
            <Divider />
            <ImageWithFallback src={image_url} preview={true} height={150} />
            <Divider />
            <Subtitle>Товары</Subtitle>
            <PromotagProductsTable />
          </Card>
        </Col>
        <Col xs={24} xl={6}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
            </Col>
            <Col xs={24}>
              <CategoryList categories={categories} title={'Категории'} />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PromotagPage
