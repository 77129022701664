import React from 'react'
import {
  IPressCenterPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const PressCenter: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'press_center_index'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IPressCenterPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IPressCenterPage) => update.mutateAsync({ data, pageType })

  return (
    <React.Fragment>
      <PageHeader title={'Пресс-центр'} extra={[<SiteLink key={1} entityType={'press-centr'} />]} />
      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24}>
            <ImageWithFallback src={data?.data?.image} preview height={50} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'image'}
              label={'Фотография'}
              initialPath={getStaticPageImagePath(data?.data?.image)}
            />
            <Form.Item name={'quote'} label={'Цитата'} rules={[{ required: true }]}>
              <TextEditor />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default PressCenter
