import React from 'react'
import { NEWS_KEY, useSingleNews } from '@app/hooks/query/news'
import { useHistory, useParams } from 'react-router-dom'
import { message, PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, NewsForm, PageInfo } from '@app/shared'
import { useMutation } from 'react-query'
import { NewsService } from '@app/api'
import { idType } from '@app/interfaces'
import { INews } from '@app/interfaces/news'
import { queryClient } from '@app/index'

interface props {}

const EditNewsPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useSingleNews(id, {
    refetchOnMount: false,
  })

  const { isLoading: isEditLoading, mutateAsync } = useMutation(NewsService.update, {
    onSuccess: () => {
      message.success('Новость успешно изменена')
      queryClient.invalidateQueries([NEWS_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая новость уже существует')
    },
  })

  const submitFunction = (news: INews, id?: idType) => mutateAsync({ news, id: id! })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { page } = data!

  return (
    <React.Fragment>
      <PageInfo title={page.title} />
      <PageHeader title={page.title} />
      <NewsForm news={data} isLoading={isEditLoading} submitFunction={submitFunction} />
    </React.Fragment>
  )
}

export default EditNewsPage
