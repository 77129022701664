import {
  AboutCompanyPage,
  AchievementPage,
  AchievementsPage,
  AddAchievementPage,
  AddAdvertisementPage,
  AddArticlePage,
  AddBannerPage,
  AddBannersGroupPage,
  AddNewsPage,
  AddProductSelectionPage,
  AddPromotionPage,
  AddSetPage,
  AddSlotPage,
  AddSmiPage,
  AdvertisementsPage,
  ArticlePage,
  ArticlesPage,
  AuthPage,
  BannerPage,
  BannersGroupPage,
  BannersGroupsPage,
  BannersPage,
  BrandPage,
  BrandsPage,
  CareServicePage,
  CategoriesPage,
  CategoryPage,
  ColorsPage,
  ContactsPage,
  DeliveryPaymentPage,
  EditAchievementPage,
  EditAdvertisementPage,
  EditArticlePage,
  EditBannerPage,
  EditBannersGroupPage,
  EditNewsPage,
  EditProductSelectionPage,
  EditPromotionPage,
  EditSetPage,
  EditSlotPage,
  EditSmiPage,
  ElasticPage,
  ExportsPage,
  FormsPage,
  GuaranteeCertificatesPage,
  HelpPage,
  HomePage,
  HtmoPage,
  LkBannersPage,
  LkManagersPage,
  LkUsersPage,
  NewsPage,
  NotFoundPage,
  OurBrandsPage,
  PressCenter,
  ProductPage,
  ProductSelectionPage,
  ProductsPage,
  PromotagPage,
  PromotagsPage,
  PromotionPage,
  PromotionsPage,
  RedirectsPage,
  RobotsFilePage,
  SetProductPage,
  SetsPage,
  SingleNewsPage,
  SizesPage,
  SlotsPage,
  SmiPage,
  StaticElementsPage,
  SyncPage,
  TemplatePages,
  VacanciesPage,
  ValuesMissionPage,
  ViewProductSelectionPage,
  ViewSetPage,
  WholesalePage,
} from '@app/pages'

export const privateRoutes = {
  home: {
    path: '/home',
    component: HomePage,
  },
  redirects: {
    path: '/redirects',
    component: RedirectsPage,
  },
  categories: {
    path: '/categories',
    component: CategoriesPage,
  },
  category: {
    path: '/categories/details/:id',
    component: CategoryPage,
  },

  products: {
    path: '/products',
    component: ProductsPage,
  },
  product: {
    path: '/products/details/:id',
    component: ProductPage,
  },

  articles: {
    path: '/articles',
    component: ArticlesPage,
  },
  article: {
    path: '/articles/details/:id',
    component: ArticlePage,
  },
  addArticle: {
    path: '/articles/add',
    component: AddArticlePage,
  },
  editArticle: {
    path: '/articles/edit/:id',
    component: EditArticlePage,
  },
  news: {
    path: '/news',
    component: NewsPage,
  },
  singleNews: {
    path: '/news/details/:id',
    component: SingleNewsPage,
  },
  addNews: {
    path: '/news/add',
    component: AddNewsPage,
  },
  editNews: {
    path: '/news/edit/:id',
    component: EditNewsPage,
  },
  promotags: {
    path: '/promotags',
    component: PromotagsPage,
  },
  promotag: {
    path: '/promotags/details/:id',
    component: PromotagPage,
  },
  brands: {
    path: '/brands',
    component: BrandsPage,
  },
  brand: {
    path: '/brands/details/:id',
    component: BrandPage,
  },

  banners: {
    path: '/banners',
    component: BannersPage,
  },
  view_banner: {
    path: '/banners/details/:id',
    component: BannerPage,
  },
  add_banner: {
    path: '/banners/add',
    component: AddBannerPage,
  },
  edit_banner: {
    path: '/banners/edit/:id',
    component: EditBannerPage,
  },

  banners_groups: {
    path: '/banners_groups',
    component: BannersGroupsPage,
  },
  view_banners_group: {
    path: '/banners_groups/details/:id',
    component: BannersGroupPage,
  },
  add_banners_group: {
    path: '/banners_groups/add',
    component: AddBannersGroupPage,
  },
  edit_banners_group: {
    path: '/banners_groups/edit/:id',
    component: EditBannersGroupPage,
  },

  promotions: {
    path: '/promotions',
    component: PromotionsPage,
  },
  view_promotion: {
    path: '/promotions/details/:id',
    component: PromotionPage,
  },
  add_promotion: {
    path: '/promotions/add',
    component: AddPromotionPage,
  },
  edit_promotion: {
    path: '/promotions/edit/:id',
    component: EditPromotionPage,
  },

  achievements: {
    path: '/achievements',
    component: AchievementsPage,
  },
  view_achievement: {
    path: '/achievements/details/:id',
    component: AchievementPage,
  },
  add_achievement: {
    path: '/achievements/add',
    component: AddAchievementPage,
  },
  edit_achievement: {
    path: '/achievements/edit/:id',
    component: EditAchievementPage,
  },

  // settings: {
  //   path: '/elastic',
  //   component: ElasticPage,
  // },

  slots: {
    path: '/slots',
    component: SlotsPage,
  },
  add_slot: {
    path: '/slots/add',
    component: AddSlotPage,
  },
  edit_slot: {
    path: '/slots/edit/:id',
    component: EditSlotPage,
  },
  forms: {
    path: '/forms',
    component: FormsPage,
  },
  advertisements: {
    path: '/advertisements',
    component: AdvertisementsPage,
  },
  addAdvertisement: {
    path: '/advertisements/add',
    component: AddAdvertisementPage,
  },
  editAdvertisement: {
    path: '/advertisements/edit/:id',
    component: EditAdvertisementPage,
  },
  // sync: {
  //   path: '/sync',
  //   component: SyncPage,
  // },
  exports: {
    path: '/exports',
    component: ExportsPage,
  },
  product_selection: {
    path: '/product-select',
    component: ProductSelectionPage,
  },
  view_product_selection: {
    path: '/product-select/details/:id',
    component: ViewProductSelectionPage,
  },
  add_product_selection: {
    path: '/product-select/add',
    component: AddProductSelectionPage,
  },
  edit_product_selection: {
    path: '/product-select/edit/:id',
    component: EditProductSelectionPage,
  },
  // robots: {
  //   path: '/robots',
  //   component: RobotsFilePage,
  // },
  smi: {
    path: '/smi',
    component: SmiPage,
  },
  add_smi: {
    path: '/smi/add',
    component: AddSmiPage,
  },
  edit_smi: {
    path: '/smi/edit/:id',
    component: EditSmiPage,
  },
  sizes: {
    path: '/sizes',
    component: SizesPage,
  },
  colors: {
    path: '/colors',
    component: ColorsPage,
  },
  static_elements: {
    path: '/static-elements',
    component: StaticElementsPage,
  },
  help_page: {
    path: '/help',
    component: HelpPage,
  },
  htmo_page: {
    path: '/htmo',
    component: HtmoPage,
  },
  delivery_payment: {
    path: '/delivery-payment',
    component: DeliveryPaymentPage,
  },
  guarantee_certificates: {
    path: '/guarantee-certificates-page',
    component: GuaranteeCertificatesPage,
  },
  care_service: {
    path: '/care-service',
    component: CareServicePage,
  },
  about_company: {
    path: '/about-company',
    component: AboutCompanyPage,
  },
  values_mission: {
    path: '/values-mission',
    component: ValuesMissionPage,
  },
  our_brands: {
    path: '/our-brands',
    component: OurBrandsPage,
  },
  vacancies: {
    path: '/vacancies',
    component: VacanciesPage,
  },
  contacts_page: {
    path: '/contacts-page',
    component: ContactsPage,
  },
  press_center: {
    path: '/press-center',
    component: PressCenter,
  },
  template_pages: {
    path: '/template-pages',
    component: TemplatePages,
  },
  lk_users: {
    path: '/lk/users',
    component: LkUsersPage,
  },
  lk_managers: {
    path: '/lk/managers',
    component: LkManagersPage,
  },
  lk_banners: {
    path: '/lk/banners',
    component: LkBannersPage,
  },
  wholesale: {
    path: '/wholesale',
    component: WholesalePage,
  },
  sets: {
    path: '/sets',
    component: SetsPage,
  },
  add_set: {
    path: '/sets/add',
    component: AddSetPage,
  },
  edit_set: {
    path: '/sets/edit/:id',
    component: EditSetPage,
  },
  view_set: {
    path: '/sets/details/:id',
    component: ViewSetPage,
  },
  set_product: {
    path: '/sets/details/:id/product/:productId',
    component: SetProductPage,
  },
}

export const publicRoutes = {
  auth: {
    path: '/auth',
    component: AuthPage,
  },
  notFound: {
    path: '/404',
    component: NotFoundPage,
  },
}
