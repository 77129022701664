import React from 'react'
import { Col, Row } from 'antd'
import { ImageWithFallback } from '@app/shared'

interface props {
  imagesLinks: string[]
  image: string | null
}

const ImagesGrid: React.FC<props> = ({ imagesLinks, image = '' }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={6}>
        <ImageWithFallback height={250} src={image!} />
      </Col>
      <Col xs={24} lg={18}>
        <Row gutter={[16, 16]}>
          {imagesLinks.map((image) => (
            <Col key={image} xs={4}>
              <ImageWithFallback height={125} src={image} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default ImagesGrid
