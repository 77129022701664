import React from 'react'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { useCategories } from '@app/hooks/query/categories'
import { CategoriesTable } from '@app/pages/categories-page/components'

interface props {}

const CategoriesPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useCategories()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const {data, ...pagination} = response!

  return (
    <React.Fragment>
      <PageInfo title={'Категории'} />
      <PageHeader title={'Категории'} />
      <CategoriesTable categories={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default CategoriesPage
