import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IBannersResponse } from '@app/interfaces/banners'
import { LkBannersService } from '@app/api'

export const LK_BANNERS_KEY = 'lk-banners-key'

export function useLkBanners(options?: UseQueryOptions<IBannersResponse, Error>) {
  return useQuery<IBannersResponse, Error>(
    LK_BANNERS_KEY,
    () =>
      LkBannersService.getAll().then((response: AxiosResponse<IBannersResponse>) => response.data),
    {
      ...options,
    },
  )
}
