import React from 'react'
import { useMutation } from 'react-query'
import { BannersService } from '@app/api'
import { message, Skeleton } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { IBanner } from '@app/interfaces/banners'
import { idType } from '@app/interfaces'
import { BannerForm, ErrorBoundary } from '@app/shared'
import {BANNERS_KEY, useBanner} from '@app/hooks/query/banners'
import {queryClient} from "@app/index"

interface props {}

const EditBannerPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useBanner(id, {
    refetchOnMount: false
  })
  const { isLoading: isEditLoading, mutateAsync } = useMutation(BannersService.update, {
    onSuccess: () => {
      message.success('Баннер успешно изменен')
      queryClient.invalidateQueries([BANNERS_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Проверьте данные и попробуйте снова')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const submitFunction = (banner: IBanner, id?: idType) => mutateAsync({ banner, id: id! })

  return <BannerForm submitFunction={submitFunction} isLoading={isEditLoading} banner={data} />
}

export default EditBannerPage
