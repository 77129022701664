import React from 'react'
import {
  BannerGroupLink,
  BannerPeriod,
  CategoryLink,
  DeleteModal,
  EditLink,
  Pagination,
  SearchInput,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'
import { BANNERS_KEY } from '@app/hooks/query/banners'
import { ColumnsType } from 'antd/es/table'
import { IBanner } from '@app/interfaces/banners'
import { idType, IPagination } from '@app/interfaces'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { Image, Table } from 'antd'
import { ICroppedCategory } from '@app/interfaces/category'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import moment from 'moment/moment'
import { BannersService } from '@app/api'
import { LK_BANNERS_KEY } from '@app/hooks/query/lk-banners'

export interface LkBannersTableProps {
  pagination: IPagination
  data: IBanner[]
}

const LkBannersTable: React.FC<LkBannersTableProps> = ({ pagination, data }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={BANNERS_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={BANNERS_KEY} />

  const columns: ColumnsType<IBanner> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: 'Заголовок',
      key: 'title',
      render: ({ link_title, id }: IBanner) => (
        <Link to={privateRoutes.banners.path + '/details/' + id}>
          {link_title ? link_title : 'Заголовок отсутствует'}
        </Link>
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'desktop',
      render: (link: string) => <Image src={link} height={75} />,
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (category: ICroppedCategory) =>
        category && <CategoryLink id={category.id} title={category.title} />,
    },
    {
      title: 'Группа баннеров',
      dataIndex: 'banner_group',
      render: ({ id, title }: IBannerGroup) => <BannerGroupLink title={title} id={id} />,
    },
    {
      title: 'Период',
      key: 'period',
      render: ({ date_start, date_finish }: IBanner) => (
        <BannerPeriod date_start={date_start} date_finish={date_finish} />
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text: string) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: ({ id }: IBanner) => (
        <SpaceWithDivider>
          <EditLink id={id} route={'banners'} />
          <DeleteModal
            id={id}
            invalidateQueryKey={LK_BANNERS_KEY}
            deleteFunction={BannersService.delete}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      bordered
      title={tableTitle}
      footer={tableFooter}
      scroll={{ x: true }}
      pagination={false}
      dataSource={data}
      columns={columns}
      rowKey={'id'}
    />
  )
}

export default LkBannersTable
