import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { ISet, ISetResponse } from '@app/interfaces/sets'
import { SetsService } from '@app/api'
import { idType } from '@app/interfaces'

export const SETS_QUERY_KEY = 'sets-query-key'

export function useSets(options?: UseQueryOptions<ISetResponse, Error>) {
  return useQuery<ISetResponse, Error>(
    [SETS_QUERY_KEY],
    () => SetsService.getAll().then((response: AxiosResponse<ISetResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useSet(id: idType, options?: UseQueryOptions<ISet, Error>) {
  return useQuery<ISet, Error>(
    [SETS_QUERY_KEY, id],
    () => SetsService.getById(id).then((response: AxiosResponse<ISet>) => response.data),
    {
      ...options,
    },
  )
}
