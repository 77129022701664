import { useQuery, UseQueryOptions } from 'react-query'
import { BannersService } from '@app/api'
import { AxiosResponse } from 'axios'
import { IBanner, IBannersResponse } from '@app/interfaces/banners'
import { idType } from '@app/interfaces'

export const BANNERS_KEY = 'banners_key'

export function useBanners(options?: UseQueryOptions<IBannersResponse, Error>) {
  return useQuery<IBannersResponse, Error>(
    BANNERS_KEY,
    () =>
      BannersService.getAll().then((response: AxiosResponse<IBannersResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useBanner(id: idType, options?: UseQueryOptions<IBanner, Error>) {
  return useQuery<IBanner, Error>(
    [BANNERS_KEY, id],
    () => BannersService.getById(id).then((response: AxiosResponse<IBanner>) => response.data),
    {
      ...options,
    },
  )
}
