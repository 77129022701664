import React from 'react'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, Typography } from 'antd'
import { useMutation } from 'react-query'
import { FormsService } from '@app/api'
import { queryClient } from '@app/index'
import { FORMS_KEY } from '@app/hooks/query/forms'
import { idType } from '@app/interfaces'

interface props {
  address: string

  id: idType
}

const FormAddress: React.FC<props> = ({ address, id }) => {
  const { isLoading, mutateAsync } = useMutation(FormsService.update, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(FORMS_KEY)
      message.success('Адрес успешно изменен')
    },
  })
  const handleEmailAddressChange = (to: string, id: idType) => mutateAsync({ to, id })

  return (
    <Typography.Text
      editable={{
        icon: isLoading ? <LoadingOutlined /> : <EditOutlined />,
        tooltip: 'Изменить адрес почты',
        onChange: (to: string) => handleEmailAddressChange(to, id),
      }}
    >
      {address}
    </Typography.Text>
  )
}

export default FormAddress
