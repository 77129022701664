import React from 'react'
import { Card, Col, Form, Input, Row } from 'antd'
import {
  FormButtons,
  FormDatePicker,
  FormFileManagerSelect,
  FormOrderInput,
  ImageWithFallback,
} from '@app/shared'
import { IAchievement } from '@app/interfaces/achievements'
import { idType } from '@app/interfaces'
import moment from 'moment'

interface props {
  submitFunction: (achievement: IAchievement, id?: idType) => void
  isLoading: boolean
  achievement?: IAchievement
}

const AchievementForm: React.FC<props> = ({ submitFunction, isLoading, achievement }) => {
  const [form] = Form.useForm()
  const onFinish = (values: any) => submitFunction(values, achievement?.id)

  const initialValues = {
    ...achievement,
    date: achievement?.date ? moment(achievement.date, 'YYYY-MM-DD') : undefined,
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={initialValues}>
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={12}>
          <Card title={'Общая информация'} bordered={false}>
            <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
              <Input placeholder={'Введите заголовок'} />
            </Form.Item>
            <FormOrderInput />
            <FormDatePicker
              label={'Выберите год'}
              datePickerProps={{ picker: 'year' }}
              format={'YYYY'}
              required={true}
            />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title={'Изображение'} bordered={false}>
            <ImageWithFallback src={achievement?.image_full} height={250} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['image', 'path']}
              filenameFieldName={['image', 'filename']}
              initialPath={achievement?.image.path || '/awards'}
              required
            />
          </Card>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default AchievementForm
