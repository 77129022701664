import React, { useEffect, useState } from 'react'
import { usePromotagsList } from '@app/hooks/query/promotags'
import { Form, FormInstance, Select, Typography } from 'antd'
import { IPromoTag } from '@app/interfaces/promotags'
import { SelectValue } from 'antd/es/select'
import promoTag from '@app/shared/promo-tag/promo-tag'
import { SiteLink } from '@app/shared'

const { Option } = Select

interface props {
  formInstance: FormInstance
  name?: string | (number | string)[]
  label?: string
  required?: boolean
}

const FormSelectPromotags: React.FC<props> = ({
  formInstance,
  name = 'promotag_id',
  label = 'Промотег',
  required = false,
}) => {
  const [selectedPromotag, setSelectedPromotag] = useState<undefined | IPromoTag>()
  const { isLoading, data } = usePromotagsList({
    refetchOnMount: false,
  })

  const handleChange = (value: SelectValue) => {
    setSelectedPromotag(data?.find((promoTag) => promoTag.id === value))
  }

  useEffect(() => {
    if (data) {
      setSelectedPromotag(data.find((promotag) => promotag.id === formInstance.getFieldValue(name)))
    }
  }, [data])

  return (
    <React.Fragment>
      <Form.Item name={name} label={label} rules={[{ required }]}>
        <Select
          showSearch={true}
          loading={isLoading}
          disabled={isLoading}
          allowClear={!required}
          placeholder={'Выберите промотег'}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
        >
          {data?.map(({ id, title }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {selectedPromotag && (
        <Form.Item>
          <SiteLink entityType={'promotions/' + selectedPromotag.slug} />
        </Form.Item>
      )}
    </React.Fragment>
  )
}

export default FormSelectPromotags
