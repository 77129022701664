import React from 'react'
import { Button, Col, Form, message, Row } from 'antd'
import { ProductsService } from '@app/api'
import { IProductVariant } from '@app/interfaces/product'
import { useMutation } from 'react-query'
import { idType } from '@app/interfaces'
import { DebounceSelect, FormSelectColor, FormSelectSize } from '@app/shared'
import { queryClient } from '@app/index'
import { PRODUCT_VARIANTS } from '@app/hooks/query/products'
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'

interface props {
  productId: idType
  variants: IProductVariant[]
}

type Fields = {
  variants: {
    product_id: { label: string; value: idType }
    color: string
    size: string
  }[]
}

const ProductVariantsForm: React.FC<props> = ({ productId, variants }) => {
  const [form] = Form.useForm<Fields>()
  const { isLoading, mutateAsync } = useMutation(ProductsService.editVariants, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PRODUCT_VARIANTS, productId])
      message.success('Варианты успешно изменены')
    },
    onError: () => {
      message.error('Ошибка! Попробуйте снова')
    },
  })

  const onFinish = (data: Fields) =>
    mutateAsync({
      productId,
      data: data.variants.map((v) => ({ ...v, product_id: v.product_id.value })),
    })

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onFinish}
      initialValues={{
        variants: variants.map((v) => ({
          ...v,
          product_id: { label: v.product.title, value: v.product_id },
          color: v.color?.title,
          size: v.size?.title,
        })),
      }}
    >
      <Form.List name={'variants'}>
        {(fields, { add, remove }) => (
          <React.Fragment>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Row gutter={16} align={'bottom'}>
                  <Col xs={11}>
                    <Form.Item
                      name={[field.name, 'product_id']}
                      label={'Поиск по названию либо артиклю'}
                      rules={[{ required: true }]}
                    >
                      <DebounceSelect
                        placeholder={'Поиск товара'}
                        allowClear={false}
                        showSearch
                        fetchOptions={ProductsService.search}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7}>
                    <FormSelectColor name={[field.name, 'color']} />
                  </Col>
                  <Col xs={5}>
                    <FormSelectSize name={[field.name, 'size']} />
                  </Col>
                  <Col xs={1}>
                    <Form.Item>
                      <Button icon={<MinusCircleOutlined />} onClick={() => remove(field.name)} />
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить вариант
              </Button>
            </Form.Item>
          </React.Fragment>
        )}
      </Form.List>

      <Form.Item>
        <Button
          block
          type={'primary'}
          htmlType={'submit'}
          disabled={isLoading}
          loading={isLoading}
          icon={<SaveOutlined />}
        >
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ProductVariantsForm
