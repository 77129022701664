import React from 'react'
import { Typography } from 'antd'

const { Title } = Typography

interface props {}

const Subtitle: React.FC<props> = ({ children }) => {
  return <Title level={3}>{children}</Title>
}

export default Subtitle
