import React from 'react'
import { IProductParams } from '@app/interfaces/product'
import { Descriptions, Typography } from 'antd'

interface props {
  params: IProductParams | null
}

const ProductParams: React.FC<props> = ({ params }) => {
  return (
    <Descriptions column={1} bordered title={'Параметры'} size={'small'}>
      {params ? (
        <React.Fragment>
          <Descriptions.Item label={'Количество в упаковке'}>
            {params.package_quantity}
          </Descriptions.Item>
          <Descriptions.Item label={'Объем'}>{params.volume}</Descriptions.Item>
          <Descriptions.Item label={'Вес'}>{params.weight}</Descriptions.Item>
        </React.Fragment>
      ) : (
        <Typography.Text>Данные отсутствуют</Typography.Text>
      )}
    </Descriptions>
  )
}

export default ProductParams
