import makeRequest from '@api/makeRequest'

const url = '/api/sync'

class SyncService {
  all() {
    return makeRequest({
      url: url + '/all',
      method: 'post',
    })
  }
  addresses() {
    return makeRequest({
      url: url + '/addresses',
      method: 'post',
    })
  }
  attributes() {
    return makeRequest({
      url: url + '/attributes',
      method: 'post',
    })
  }
  brands() {
    return makeRequest({
      url: url + '/brands',
      method: 'post',
    })
  }
  categories() {
    return makeRequest({
      url: url + '/categories',
      method: 'post',
    })
  }
  documentTypes() {
    return makeRequest({
      url: url + '/document-types',
      method: 'post',
    })
  }
  documents() {
    return makeRequest({
      url: url + '/documents',
      method: 'post',
    })
  }
  invoiceParameters() {
    return makeRequest({
      url: url + '/invoice-parameters',
      method: 'post',
    })
  }
  invoiceStatuses() {
    return makeRequest({
      url: url + '/invoice-statuses',
      method: 'post',
    })
  }
  managerRoles() {
    return makeRequest({
      url: url + '/manager-roles',
      method: 'post',
    })
  }
  orderStatuses() {
    return makeRequest({
      url: url + '/order-statuses',
      method: 'post',
    })
  }
  organizations() {
    return makeRequest({
      url: url + '/organizations',
      method: 'post',
    })
  }
  partners() {
    return makeRequest({
      url: url + '/partners',
      method: 'post',
    })
  }
  prices() {
    return makeRequest({
      url: url + '/prices',
      method: 'post',
    })
  }
  products() {
    return makeRequest({
      url: url + '/products',
      method: 'post',
    })
  }
  promotags() {
    return makeRequest({
      url: url + '/promotags',
      method: 'post',
    })
  }
  stocks() {
    return makeRequest({
      url: url + '/stocks',
      method: 'post',
    })
  }
  syncOrders() {
    return makeRequest({
      url: url + '/sync-orders',
      method: 'post',
    })
  }
  units() {
    return makeRequest({
      url: url + '/units',
      method: 'post',
    })
  }
  users() {
    return makeRequest({
      url: url + '/users',
      method: 'post',
    })
  }
  variants() {
    return makeRequest({
      url: url + '/variants',
      method: 'post',
    })
  }
}

export default new SyncService()
