import React from 'react'
import { List, Typography } from 'antd'
import { ImageWithFallback } from '@app/shared'
import { IPromoContent } from '@app/interfaces/product'

interface props {
  promo_contents: IPromoContent[]
}

const ProductPromo: React.FC<props> = ({ promo_contents }) => {
  return (
    <List
      itemLayout={'vertical'}
      dataSource={promo_contents}
      renderItem={({ title, description, image_full }) => (
        <List.Item extra={<ImageWithFallback width={150} src={image_full} />}>
          <List.Item.Meta title={title} />
          <Typography.Text>{description}</Typography.Text>
        </List.Item>
      )}
    />
  )
}

export default ProductPromo
