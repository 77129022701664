import React from 'react'
import { AdvertisementForm, ErrorBoundary, PageInfo } from '@app/shared'
import { message, PageHeader, Skeleton } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useAdvertisement } from '@app/hooks/query/advertisements'
import { useMutation } from 'react-query'
import { AdvertisementsService } from '@app/api'
import { IAdvertisement } from '@app/interfaces/advertisements'

interface props {}

const EditAdvertisementPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useAdvertisement(id, {
    refetchOnMount: false,
  })

  const { isLoading: isUpdateLoading, mutateAsync } = useMutation(AdvertisementsService.update, {
    onSuccess: () => {
      message.success('Рекламный материал успешно изменен')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так.')
    },
  })

  if (isLoading) return <Skeleton active={true} />
  if (isError) return <ErrorBoundary />

  const submitFunction = (ads: IAdvertisement) => mutateAsync(ads)

  const title = 'Изменить рекламный материал'
  return (
    <React.Fragment>
      <PageInfo title={title} />
      <PageHeader title={title} />
      <AdvertisementForm submitFunction={submitFunction} isLoading={isUpdateLoading} ads={data} />
    </React.Fragment>
  )
}

export default EditAdvertisementPage
