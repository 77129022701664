import React, { useState } from 'react'
import { IProductSize } from '@app/interfaces/product'
import { Button, Drawer, Form, Input, message, Space } from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { FormButtons } from '@app/shared'
import { useMutation } from 'react-query'
import { SizesService } from '@app/api'
import { queryClient } from '@app/index'
import { SIZES_KEY } from '@app/hooks/query/sizes'
import { idType } from '@app/interfaces'

interface props {
  size?: IProductSize
}

type Fields = {
  id: idType
  title: string
}
const SizeDrawer: React.FC<props> = ({ size }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleChangeVisible = () => setVisible(!visible)

  const onSuccess = async () => {
    await queryClient.invalidateQueries(SIZES_KEY)
    message.success('Изменения прошли успешно')
    handleChangeVisible()
  }
  const onError = () => {
    message.success('Произошла ошибка!')
  }

  const create = useMutation(SizesService.create, { onSuccess, onError })
  const update = useMutation(SizesService.update, { onSuccess, onError })

  const onFinish = (data: Fields) =>
    size ? update.mutateAsync(data) : create.mutateAsync(data.title)

  const isEditSize = !!size

  const title = isEditSize ? 'Изменить' : 'Добавить'
  return (
    <React.Fragment>
      <Button
        type={'primary'}
        icon={isEditSize ? <EditOutlined /> : <PlusCircleOutlined />}
        onClick={handleChangeVisible}
      >
        {title}
      </Button>
      <Drawer
        destroyOnClose={true}
        closable={true}
        footer={
          <Space>
            <Button
              type={'primary'}
              form={'sizes-form'}
              htmlType={'submit'}
              disabled={create.isLoading || update.isLoading}
              loading={create.isLoading || update.isLoading}
            >
              Сохранить
            </Button>
            <Button type={'default'} onClick={handleChangeVisible}>
              Отменить
            </Button>
          </Space>
        }
        visible={visible}
        onClose={handleChangeVisible}
        width={350}
        title={title}
      >
        <Form id={'sizes-form'} onFinish={onFinish} layout={'vertical'} initialValues={size}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
            <Input placeholder={'Введите заголовок'} />
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default SizeDrawer
