import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SETS_QUERY_KEY, useSet } from '@app/hooks/query/sets'
import {
  Button,
  Col,
  Descriptions,
  Divider,
  message,
  PageHeader,
  Row,
  Skeleton,
  Space,
  Table,
} from 'antd'
import { DeleteModal, EditLink, ErrorBoundary, SeoCard, SiteLink } from '@app/shared'
import { SetImportProducts, SetProductForm } from '@app/pages/view-set-page/components'
import { IProduct } from '@app/interfaces/product'
import { ISetProduct } from '@app/interfaces/sets'
import { SetProductsService } from '@app/api'
import { useMutation } from 'react-query'
import { DeleteOutlined } from '@ant-design/icons'
import { idType } from '@app/interfaces'

export interface ViewSetPageProps {}

const ViewSetPage: React.FC<ViewSetPageProps> = () => {
  const [selectedProducts, setSelectedProducts] = useState<idType[]>([])
  const onSelectItem = async (selectedRowKeys: React.ReactText[]) => {
    setSelectedProducts(selectedRowKeys)
  }
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data, refetch } = useSet(id)

  const deleteMultipleProducts = useMutation(SetProductsService.deleteMultiple, {
    onSuccess: () => {
      refetch()
      message.success('Товары успешно удалены')
      setSelectedProducts([])
    },
    onError: () => {
      message.error('Ошибка удаления товаров')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const handleDeleteAllProducts = () => deleteMultipleProducts.mutate(selectedProducts)

  return (
    <>
      <PageHeader
        title={data?.title}
        extra={[
          <SiteLink slug={'sets/' + data?.slug} key={0} />,
          <EditLink route={'sets'} id={id} key={1} />,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={16}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={'ID'}>{id}</Descriptions.Item>
            <Descriptions.Item label={'Slug'}>{data?.slug}</Descriptions.Item>
            <Descriptions.Item label={'Дата создания'}>
              {new Date(data!.created_at).toLocaleDateString()}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
        </Col>
        <Col xs={8}>
          {data?.page && (
            <SeoCard
              title={data.page.title}
              description={data.page.description}
              keywords={data.page.keywords}
            />
          )}
        </Col>
        <Col xs={24}>
          <PageHeader
            title={'Товары в подборке'}
            extra={
              <Space key={'1'}>
                <SetImportProducts />
                <SetProductForm setId={id} />
              </Space>
            }
          />
          <Table
            rowKey={'id'}
            dataSource={data?.set_products}
            title={() => (
              <Button
                disabled={!selectedProducts.length || deleteMultipleProducts.isLoading}
                loading={deleteMultipleProducts.isLoading}
                type={'primary'}
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteAllProducts}
              >
                Удалить несколько
              </Button>
            )}
            rowSelection={{
              selectedRowKeys: selectedProducts,
              onChange: onSelectItem,
            }}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
              },
              {
                title: 'ID продукта',
                dataIndex: 'product_id',
              },
              {
                title: 'Артикул',
                dataIndex: 'product',
                render: (product: IProduct) => product.sku,
              },
              {
                title: 'Наименование',
                dataIndex: 'product',
                render: (product: IProduct, entity) => (
                  <Link to={'/sets/details/' + id + '/product/' + entity.id}>{product.title}</Link>
                ),
              },
              {
                title: 'Старая цена',
                dataIndex: 'old_price',
              },
              {
                title: 'Новая цена',
                dataIndex: 'price',
              },
              {
                title: 'Порядковый номер',
                dataIndex: 'sort_order',
              },
              {
                title: 'Действия',
                align: 'right',
                key: 'actions',
                render: (entity: ISetProduct) => (
                  <Space>
                    <SetProductForm setId={id} product={entity} />
                    <DeleteModal
                      id={entity.id}
                      deleteFunction={SetProductsService.delete}
                      invalidateQueryKey={SETS_QUERY_KEY}
                    />
                  </Space>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  )
}

export default ViewSetPage
