import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'

const url = '/api/ads-types'

class AdsTypes {
  getAll() {
    return makeRequest({
      url,
      method: 'GET',
      params: queryString.parse(history.location.search),
    })
  }
}

export default new AdsTypes()
