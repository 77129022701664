import React from 'react'
import { useParams } from 'react-router-dom'
import { SELECTIONS_KEY, useSelection } from '@app/hooks/query/selections'
import { Card, Col, Divider, message, Row, Skeleton, Tabs, Typography } from 'antd'
import {
  CategoryLink,
  EditLink,
  ErrorBoundary,
  PageInfo,
  SecondaryText,
  SelectionImportProducts,
  SelectionProductsList,
  SelectionSelectedProductsList,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  Subtitle,
} from '@app/shared'
import { SelectionsService } from '@app/api'
import { useMutation } from 'react-query'
import { queryClient } from '@app/index'

interface props {}

const ViewProductSelectionPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useSelection(id)

  const onSuccess = () => {
    queryClient.invalidateQueries([SELECTIONS_KEY, id])
    message.success('Подборка успешно обновлена')
  }
  const onError = () => {
    message.error('Ошибка обновления подборки! Обратитесь к системному администратору')
  }

  const importMutation = useMutation(SelectionsService.importProducts, {
    onSuccess,
    onError,
  })
  const detachMutation = useMutation(SelectionsService.detachProducts, {
    onSuccess,
    onError,
  })
  const attachMutation = useMutation(SelectionsService.attachProducts, {
    onSuccess,
    onError,
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { title, slug, page, product_ids, products, category } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={15}>
          <Card
            title={'Общая информация'}
            extra={
              <SpaceWithDivider>
                <SiteLink entityType={'selection/' + data?.slug} />
                <EditLink id={id} route={'product_selection'} />
              </SpaceWithDivider>
            }
          >
            <Typography.Title level={2}>{title}</Typography.Title>
            <SpaceWithDivider>
              <SecondaryText>Slug: {slug}</SecondaryText>
              {category && (
                <SecondaryText>
                  <CategoryLink id={category.id} title={category.title} withTitle={true} />
                </SecondaryText>
              )}
            </SpaceWithDivider>
            <Divider />
            <Subtitle>Краткое описание</Subtitle>
            {page.short_content ? (
              <div dangerouslySetInnerHTML={{ __html: page.short_content }} />
            ) : (
              <Typography.Text type={'secondary'}>Контент отсутствует</Typography.Text>
            )}
            <Divider />
            <Subtitle>Контент</Subtitle>
            {page.content ? (
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            ) : (
              <Typography.Text type={'secondary'}>Контент отсутствует</Typography.Text>
            )}
          </Card>
        </Col>
        <Col xs={24} lg={9}>
          <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
        </Col>
        <Col xs={24}>
          <Subtitle>Товары</Subtitle>
          <Tabs
            defaultActiveKey={'selected'}
            tabBarExtraContent={{
              right: (
                <SelectionImportProducts
                  importHandler={importMutation.mutateAsync}
                  isLoading={importMutation.isLoading}
                  id={id}
                />
              ),
            }}
          >
            <Tabs.TabPane tab={'Выбранные товары'} key={'selected'}>
              <SelectionSelectedProductsList
                detachProductsHandler={detachMutation.mutateAsync}
                isLoading={detachMutation.isLoading}
                selectedProducts={products}
                id={id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Добавить из списка товаров'} key={'products'}>
              <SelectionProductsList
                attachProductsHandler={attachMutation.mutateAsync}
                isLoading={attachMutation.isLoading}
                productIds={product_ids}
                id={id}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ViewProductSelectionPage
