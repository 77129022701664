import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import { ISelection } from '@app/interfaces/selections'

const url = '/api/selections'

class SelectionsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: Partial<ISelection>) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: Partial<ISelection>) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  attachProducts(data: { id: idType; products: idType[] }) {
    return makeRequest({
      url: url + '/' + data.id + '/attach-products',
      method: 'POST',
      data: {
        products: data.products,
      },
    })
  }

  detachProducts(data: { id: idType; products: idType[] }) {
    return makeRequest({
      url: url + '/' + data.id + '/detach-products',
      method: 'POST',
      data: {
        products: data.products,
      },
    })
  }

  importProducts(data: { id: idType; file: FormData }) {
    return makeRequest({
      url: url + '/' + data.id + '/import',
      method: 'post',
      data: data.file,
    })
  }

  import(data: FormData) {
    return makeRequest({
      url: url + '/import',
      method: 'post',
      data,
    })
  }

  export() {
    return makeRequest({
      url: url + '/export',
      responseType: 'blob',
    })
  }
}

export default new SelectionsService()
