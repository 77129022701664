import React from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Skeleton,
  Tabs,
  Typography,
} from 'antd'
import {
  IStaticContentResponse,
  IWholesalePage,
  StaticContentType,
} from '@app/interfaces/static-content'
import { STATIC_CONTENT_KEY, useStaticContent } from '@app/hooks/query/static-content'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SelectionImportProducts,
  SelectionProductsList,
  SelectionSelectedProductsList,
  SiteLink,
} from '@app/shared'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'
import { PlusCircleOutlined } from '@ant-design/icons'
import { queryClient } from '@app/index'
import { useMutation } from 'react-query'
import { StaticContentService } from '@app/api'

export interface WholesalePageProps {}

const WholesalePage: React.FC<WholesalePageProps> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'wholesale_clients'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IWholesalePage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  const onSuccess = () => {
    message.success('Подборка успешно обновлена')
    queryClient.invalidateQueries([STATIC_CONTENT_KEY, pageType])
  }
  const onError = () => {
    message.error('Ошибка обновления подорки! Обратитесь к системному администратору')
  }
  const importMutation = useMutation(StaticContentService.importProducts, {
    onSuccess,
    onError,
  })
  const attachMutation = useMutation(StaticContentService.attachProducts, {
    onSuccess,
    onError,
  })
  const detachMutation = useMutation(StaticContentService.detachProducts, {
    onSuccess,
    onError,
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (values: IWholesalePage) => {
    values.slider_slot = {
      title: values.slider_slot?.title ?? '',
      products_ids: data?.data?.slider_slot?.products_ids ?? [],
      products: data?.data?.slider_slot?.products ?? [],
    }

    update.mutateAsync({
      pageType,
      data: values,
    })
  }

  return (
    <React.Fragment>
      <PageHeader
        title={'Оптовым клиентам'}
        extra={[<SiteLink key={1} entityType={'optovym-klientam'} />]}
      />
      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Баннер</Typography.Title>
            <Form.Item name={['banner', 'link']} label={'Ссылка'}>
              <Input placeholder={'Введите ссылку'} />
            </Form.Item>
            <ImageWithFallback src={data?.data?.banner.mobile_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['banner', 'mobile_image']}
              label={'Изображение (смартфоны)'}
              initialPath={getStaticPageImagePath(data?.data?.banner.mobile_image)}
              required={true}
            />
            <ImageWithFallback src={data?.data?.banner.tablet_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['banner', 'tablet_image']}
              label={'Изображение (планшеты)'}
              initialPath={getStaticPageImagePath(data?.data?.banner.tablet_image)}
              required={true}
            />
            <ImageWithFallback src={data?.data?.banner.pc_image} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['banner', 'pc_image']}
              label={'Изображение (ПК)'}
              initialPath={getStaticPageImagePath(data?.data?.banner.pc_image)}
              required={true}
            />
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Сетка</Typography.Title>
            <Form.Item name={['grid_slot', 'title']} label={'Заголовок'}>
              <Input placeholder={'Введите заголовок'} />
            </Form.Item>
            <Form.List name={['grid_slot', 'items']}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map(({ name, fieldKey, key }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['grid_slot', 'items', name, 'image']}
                        label={'Изображение'}
                        initialPath={getStaticPageImagePath(
                          data?.data?.grid_slot?.items[name]?.image,
                        )}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Заголовок'}
                        name={[name, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Ссылка'}
                        name={[name, 'link']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))}
                  <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                    Добавить блок
                  </Button>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Слайдер</Typography.Title>
            <Form.Item name={['slider_slot', 'title']} label={'Заголовок'}>
              <Input placeholder={'Введите заголовок'} />
            </Form.Item>

            <Tabs
              tabBarExtraContent={{
                right: (
                  <SelectionImportProducts
                    importHandler={importMutation.mutateAsync}
                    isLoading={importMutation.isLoading}
                    id={pageType}
                  />
                ),
              }}
            >
              <Tabs.TabPane tab={'Выбранные товары'} key={'selected'}>
                <SelectionSelectedProductsList
                  detachProductsHandler={detachMutation.mutateAsync}
                  isLoading={detachMutation.isLoading}
                  selectedProducts={data?.data?.slider_slot?.products ?? []}
                  id={pageType}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Добавить из списка товаров'} key={'products'}>
                <SelectionProductsList
                  attachProductsHandler={attachMutation.mutateAsync}
                  isLoading={attachMutation.isLoading}
                  productIds={data?.data?.slider_slot?.products_ids ?? []}
                  id={pageType}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default WholesalePage
