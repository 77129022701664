import React from 'react'
import { Form, Input, message } from 'antd'
import { FormButtons } from '@app/shared'
import { useMutation } from 'react-query'
import { RedirectsService } from '@app/api'
import { queryClient } from '@app/index'
import { REDIRECTS_KEY } from '@app/hooks/query/redirects'
import { IRedirect } from '@app/interfaces/redirects'

interface props {
  drawerHandler: () => void
  redirect?: IRedirect
}

const RedirectForm: React.FC<props> = ({ drawerHandler, redirect }) => {
  const [form] = Form.useForm()

  const onSuccess = () => {
    message.success('Изменения прошли успешно')
    queryClient.invalidateQueries(REDIRECTS_KEY)
    drawerHandler()
  }

  const onError = () => {
    message.error('Ошибка! Возможно, такой редирект уже существует')
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(RedirectsService.create, {
    onSuccess,
    onError,
  })

  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(RedirectsService.update, {
    onSuccess,
    onError,
  })

  const onFinish = (values: IRedirect) =>
    redirect ? update({ redirect: values, id: redirect!.id }) : create(values)

  return (
    <Form onFinish={onFinish} form={form} layout={'vertical'} initialValues={redirect}>
      <Form.Item name={'from'} label={'Откуда'} rules={[{ required: true }]}>
        <Input placeholder={'Введите откуда будет редирект'} />
      </Form.Item>
      <Form.Item name={'to'} label={'Куда'} rules={[{ required: true }]}>
        <Input placeholder={'Введите куда будет редирект'} />
      </Form.Item>
      <FormButtons isLoading={redirect ? isUpdateLoading : isCreateLoading} />
    </Form>
  )
}

export default RedirectForm
