import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { IAchievement } from '@app/interfaces/achievements'
import queryString from 'query-string'
import { history } from '@api/history'

const url = '/api/achievements'

class AchievementsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: IAchievement) {
    return makeRequest({
      url,
      method: 'post',
      data,
    })
  }

  update(data: { id: idType; achievement: IAchievement }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'patch',
      data: data.achievement,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }
}

export default new AchievementsService()
