import React from 'react'
import { useUsers } from '@app/hooks/query/users'
import { PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { LkUsersTable } from '@app/pages/lk-users-page/components'

export interface LkUsersPageProps {}

const LkUsersPage: React.FC<LkUsersPageProps> = () => {
  const { data: response, isLoading, isError } = useUsers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Пользователи ЛК'} />
      <PageHeader title={'Пользователи ЛК'} />
      <LkUsersTable pagination={pagination} data={data} />
    </React.Fragment>
  )
}

export default LkUsersPage
