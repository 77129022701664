import React, { useState } from 'react'
import { useProductVariants } from '@app/hooks/query/products'
import { Link, useParams } from 'react-router-dom'
import { Button, Col, List, Row, Typography } from 'antd'
import { ErrorBoundary, ImageWithFallback, Subtitle } from '@app/shared'
import { PlusCircleOutlined } from '@ant-design/icons'
import { ProductVariantsForm } from '@app/pages/product-page/components'

interface props {}

const ProductVariants: React.FC<props> = () => {
  const [handleChange, setHandleChange] = useState<boolean>(false)
  const changeHandler = () => setHandleChange(!handleChange)
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useProductVariants(id)

  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <List
        header={
          <Row gutter={[32, 32]} justify={'space-between'} align={'middle'}>
            <Col>
              <Subtitle>Варианты товара</Subtitle>
            </Col>
            <Col>
              <Button
                type={'primary'}
                danger={handleChange}
                icon={<PlusCircleOutlined />}
                onClick={changeHandler}
              >
                {handleChange ? 'Отменить' : 'Изменить'}
              </Button>
            </Col>
            {handleChange && (
              <Col xs={24}>
                <ProductVariantsForm productId={id} variants={data!.variants} />
              </Col>
            )}
          </Row>
        }
        loading={isLoading}
        dataSource={data?.variants}
        pagination={false}
        itemLayout={'vertical'}
        renderItem={(item) => (
          <List.Item
            key={item.product.id}
            actions={[
              <Typography.Text key={'color'}>
                Цвет: {item.color?.title || 'Не указано'}
              </Typography.Text>,
              <Typography.Text key={'size'}>
                Размер: {item.size?.title || 'Не указано'}
              </Typography.Text>,
            ]}
          >
            <List.Item.Meta
              title={<Link to={'/products/details/' + id}>{item.product.title}</Link>}
              description={item.product.sku}
              avatar={<ImageWithFallback width={100} src={item.product.image_path!} />}
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  )
}

export default ProductVariants
