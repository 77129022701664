import React from 'react'
import { Button, Card, Col, Drawer, Form, Input, message, Row } from 'antd'
import {
  DrawerButton,
  FormContentInput,
  FormDatePicker,
  FormFileManagerSelect,
  FormSeoInputs,
  FormTitleWithSlugInputs,
  ImageWithFallback,
  SpaceWithDivider,
} from '@app/shared'
import { useMutation } from 'react-query'
import { StaticPagesService } from '@app/api'
import { queryClient } from '@app/index'
import { STATIC_PAGES_KEY } from '@app/hooks/query/static-pages'
import moment from 'moment'
import { ITemplatePage } from '@app/interfaces/static-pages'

export interface TemplatePageActionDrawerProps {
  page?: ITemplatePage
}

const TemplatePageActionDrawer: React.FC<TemplatePageActionDrawerProps> = ({ page }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = React.useState<boolean>(false)
  const handleVisibleChange = () => setVisible(!visible)
  const formId = 'templatePageFormId'

  const onSuccess = () => {
    message.success('Страница была успешно создана')
    queryClient.invalidateQueries(STATIC_PAGES_KEY)
    handleVisibleChange()
  }
  const onError = () => {
    message.error('Ошибка при создании страницы!')
  }

  const create = useMutation(StaticPagesService.create, { onSuccess, onError })
  const update = useMutation(StaticPagesService.update, { onSuccess, onError })

  const onFinish = (values: ITemplatePage) => {
    const handler = page ? update.mutate : create.mutate
    handler(values)
  }

  const title = page ? 'Изменить страницу' : 'Создать страницу'
  return (
    <React.Fragment>
      <DrawerButton isEditing={!!page} clickHandler={handleVisibleChange} />
      <Drawer
        destroyOnClose={true}
        visible={visible}
        onClose={handleVisibleChange}
        title={title}
        width={'100%'}
        footer={
          <SpaceWithDivider>
            <Button type={'primary'} htmlType={'submit'} form={formId}>
              Сохранить
            </Button>
            <Button type={'default'} htmlType={'button'} onChange={handleVisibleChange}>
              Отменить
            </Button>
          </SpaceWithDivider>
        }
      >
        <Form
          initialValues={{
            ...page,
            date: page?.date ? moment(page?.date) : undefined,
          }}
          form={form}
          id={formId}
          onFinish={onFinish}
          layout={'vertical'}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Row gutter={[32, 32]}>
            <Col xs={14}>
              <Card title={'Общая информация'} bordered={false}>
                <FormTitleWithSlugInputs form={form} id={page?.id} />
                <FormDatePicker required={false} />
                <FormContentInput required={true} />
              </Card>
            </Col>
            <Col xs={10}>
              <Card title={'Изображение'} bordered={false}>
                <ImageWithFallback height={75} />
                <FormFileManagerSelect
                  formInstance={form}
                  pathFieldName={['image', 'path']}
                  filenameFieldName={['image', 'filename']}
                  label={'Изображение'}
                  initialPath={page?.image?.path}
                />
              </Card>
              <Card title={'SEO'} bordered={false}>
                <FormSeoInputs />
              </Card>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default TemplatePageActionDrawer
