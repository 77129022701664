import React from 'react'
import { Form, Input, PageHeader, Skeleton, Typography } from 'antd'
import {
  ICareServicePage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const CareServicePage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'help_care_service'
  const { isError, isLoading, data } =
    useStaticContent<IStaticContentResponse<ICareServicePage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: ICareServicePage) => update.mutateAsync({ pageType, data })

  return (
    <>
      <PageHeader
        title={'Служба заботы'}
        extra={[<SiteLink key={1} entityType={'servis/sluzhba-zaboty'} />]}
      />
      <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <ImageWithFallback src={data?.data?.photo} height={75} preview />
        <FormFileManagerSelect
          formInstance={form}
          pathFieldName={'photo'}
          label={'Фотография'}
          initialPath={getStaticPageImagePath(data?.data?.photo)}
        />
        <Form.Item label={'Цитата'} name={'quote'} rules={[{ required: true }]}>
          <TextEditor />
        </Form.Item>
        <Form.Item
          label={'Номер службы заботы BRADEX'}
          name={'care_phone'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Дополнительный текст'} name={'additional_text'}>
          <TextEditor />
        </Form.Item>
        <FeedbackFormRadio />
        <FormButtons isLoading={update.isLoading} />
      </Form>
    </>
  )
}

export default CareServicePage
