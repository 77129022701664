import React from 'react'
import { useColors } from '@app/hooks/query/colors'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Table } from 'antd'
import { IProductSize } from '@app/interfaces/product'
import moment from 'moment'
import { ColorDrawer } from '@app/pages/colors-page/components'

interface props {}

const ColorsPage: React.FC<props> = () => {
  const { data, isLoading, isError } = useColors()
  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Цвета'} />
      <PageHeader title={'Цвета'} extra={[<ColorDrawer key={'1'} />]} />
      <Table loading={isLoading} rowKey={'id'} pagination={false} dataSource={data}>
        <Table.Column title={'ID'} dataIndex={'id'} />
        <Table.Column title={'Заголовок'} dataIndex={'title'} />
        <Table.Column
          title={'Дата создания'}
          dataIndex={'created_at'}
          render={(createdAt: string) => <span>{moment(createdAt).format('DD-MM-YYYY')}</span>}
        />
        <Table.Column
          title={'Действия'}
          align={'right'}
          key={'actions'}
          render={(item: IProductSize) => <ColorDrawer color={item} />}
        />
      </Table>
    </React.Fragment>
  )
}

export default ColorsPage
