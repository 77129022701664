import React from 'react'
import { useBrands } from '@app/hooks/query/brands'
import { PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { BrandsForm, BrandsTable } from '@app/pages/brands-page/components'

interface props {}

const BrandsPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useBrands()

  if (isLoading) return <Skeleton active />

  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Бренды'} />
      <PageHeader title={'Бренды'} extra={[<BrandsForm key={'1'} />]} />
      <BrandsTable brands={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default BrandsPage
