import React from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'
import { useMutation } from 'react-query'
import { RedirectsService } from '@app/api'
import { queryClient } from '@app/index'
import { REDIRECTS_KEY } from '@app/hooks/query/redirects'
import { AxiosResponse } from 'axios'

interface props {}

const RedirectsImport: React.FC<props> = () => {
  const { isLoading, mutateAsync } = useMutation(RedirectsService.importRedirects)

  const beforeUpload = (file: RcFile) => {
    const isExcelFile =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'text/csv'

    if (!isExcelFile) {
      message.error('Ошибка! Можно загружать файлы с типом: .csv, .xls, .xlsx')
    }

    return isExcelFile || Upload.LIST_IGNORE
  }

  const uploadFile = async (file: RcFile) => {
    const data = new FormData()
    data.append('file', file)

    return mutateAsync(data)
      .then((response: AxiosResponse<any>) => {
        queryClient.invalidateQueries(REDIRECTS_KEY)
        message.success(response.data.message)
        return response.data.message
      })
      .catch((e) => {
        message.error(e.response.data.message)
        return e.response.data.message
      })
  }

  return (
    <Upload
      disabled={isLoading}
      action={uploadFile}
      name={'file'}
      multiple={false}
      showUploadList={false}
      maxCount={1}
      beforeUpload={beforeUpload}
    >
      <Button loading={isLoading} icon={<UploadOutlined />}>
        Импортировать из файла
      </Button>
    </Upload>
  )
}

export default RedirectsImport
