import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import {IPromotion} from "@app/interfaces/promotions"

const url = '/api/promotions'

class PromotionsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: any) {
    return makeRequest({
      url: url,
      method: 'post',
      data,
    })
  }

  update(data: { promotion:IPromotion, id:idType }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'patch',
      data: data.promotion,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }
}

export default new PromotionsService()
