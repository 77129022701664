import React, { useState } from 'react'
import { ISetProductPartnerLink } from '@app/interfaces/sets'
import { Button, Form, Input, message, Modal } from 'antd'
import { FormOrderInput, FormSelectPartners } from '@app/shared'
import { useMutation } from 'react-query'
import { SetProductsPartnersLinkService } from '@app/api'
import { idType } from '@app/interfaces'
import { queryClient } from '@app/index'
import { SET_PRODUCT_QUERY_KEY } from '@app/hooks/query/set-products'

export interface SetProductPartnerLinkFormProps {
  partnerLink?: ISetProductPartnerLink
  productId: idType
}

const SetProductPartnerLinkForm: React.FC<SetProductPartnerLinkFormProps> = ({
  productId,
  partnerLink,
}) => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)
  const onSuccess = () => {
    message.success('Данные успешно обновлены')
    queryClient.invalidateQueries(SET_PRODUCT_QUERY_KEY)
    handleChangeVisible()
  }
  const onError = () => {
    message.error('Произошла ошибка!')
  }
  const addPartner = useMutation(SetProductsPartnersLinkService.addLink, {
    onSuccess,
    onError,
  })

  const updatePartner = useMutation(SetProductsPartnersLinkService.editLink, {
    onSuccess,
    onError,
  })
  const title = partnerLink ? 'Изменить партнера' : 'Добавить партнера'

  return (
    <>
      <Button type={partnerLink ? 'primary' : 'default'} onClick={handleChangeVisible}>
        {title}
      </Button>
      <Modal
        visible={visible}
        destroyOnClose={true}
        onCancel={handleChangeVisible}
        title={title}
        confirmLoading={addPartner.isLoading || updatePartner.isLoading}
        okButtonProps={{ htmlType: 'submit', form: 'add-partner' }}
      >
        <Form
          onFinish={(data: any) =>
            partnerLink ? updatePartner.mutate(data) : addPartner.mutate(data)
          }
          layout={'vertical'}
          id={'add-partner'}
          initialValues={{
            set_product_id: productId,
            ...partnerLink,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'set_product_id'} hidden>
            <Input />
          </Form.Item>
          <FormSelectPartners required={true} />
          <Form.Item name={'link'} label={'Ссылка'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <FormOrderInput required={false} name={'order'} />
        </Form>
      </Modal>
    </>
  )
}

export default SetProductPartnerLinkForm
