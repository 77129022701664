import { ILkManager, ILkManagersResponse } from '@app/interfaces/managers'
import { ManagersService } from '@app/api'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { idType } from '@app/interfaces'

export const LK_MANAGERS_KEY = 'lk-managers-key'

export function useLkManagers(options?: UseQueryOptions<ILkManagersResponse, Error>) {
  return useQuery<ILkManagersResponse, Error>(
    LK_MANAGERS_KEY,
    () =>
      ManagersService.all().then((response: AxiosResponse<ILkManagersResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useLkManager(id: idType, options?: UseQueryOptions<ILkManager, Error>) {
  return useQuery<ILkManager, Error>(
    [LK_MANAGERS_KEY, id],
    () => ManagersService.byId(id).then((response: AxiosResponse<ILkManager>) => response.data),
    {
      ...options,
    },
  )
}
