import React from 'react'
import { Button, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { ImportOutlined } from '@ant-design/icons'
import { AxiosPromise } from 'axios'
import { SelectionImportDataType } from '@app/interfaces/selections'
import { idType } from '@app/interfaces'

interface props {
  importHandler: (data: SelectionImportDataType) => AxiosPromise
  isLoading: boolean
  id: idType
}

const SelectionImportProducts: React.FC<props> = ({ importHandler, isLoading, id }) => {
  const handleBeforeUpload = async (file: RcFile) => {
    const formData = new FormData()
    formData.append('file', file)
    await importHandler({ id, file: formData })

    return false
  }

  return (
    <Upload
      beforeUpload={handleBeforeUpload}
      showUploadList={false}
      disabled={isLoading}
      multiple={false}
      accept={
        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      }
    >
      <Button type={'dashed'} icon={<ImportOutlined />}>
        Импорт из .xls
      </Button>
    </Upload>
  )
}

export default SelectionImportProducts
