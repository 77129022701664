import React from 'react'
import { ILkManager } from '@app/interfaces/managers'
import { IPagination } from '@app/interfaces'
import { Pagination, SearchInput, SpaceWithDivider } from '@app/shared'
import { LK_MANAGERS_KEY } from '@app/hooks/query/managers'
import { Image, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { LkManagerDrawer } from '@app/pages/lk-managers-page/components'

export interface LkManagersTableProps {
  data: ILkManager[]
  pagination: IPagination
}

const LkManagersTable: React.FC<LkManagersTableProps> = ({ data, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={LK_MANAGERS_KEY} />
  const tableFooter = () => (
    <Pagination pagination={pagination} fetchingQueryKey={LK_MANAGERS_KEY} />
  )

  const columns: ColumnsType<ILkManager> = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'full_name',
      title: 'ФИО',
    },
    {
      dataIndex: 'image_url',
      title: 'Фотография',
      render: (image: string | null) => image && <Image src={image} width={50} height={50} />,
    },
    {
      dataIndex: 'created_at',
      title: 'Дата создания',
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      key: 'actions',
      align: 'right',
      render: (entity: ILkManager) => (
        <SpaceWithDivider>
          <LkManagerDrawer manager={entity} />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data}
      title={tableTitle}
      footer={tableFooter}
      pagination={false}
      scroll={{ x: true }}
      rowKey={'id'}
    />
  )
}

export default LkManagersTable
