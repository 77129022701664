import React, { useState } from 'react'
import { idType } from '@app/interfaces'
import { Button, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IProduct, IProductStock } from '@app/interfaces/product'
import { StatusTag } from '@app/shared'
import { DeleteOutlined } from '@ant-design/icons'
import { AxiosPromise } from 'axios'
import { SelectionAttachDetachDataType } from '@app/interfaces/selections'
import { IVideo } from '@app/interfaces/video'

interface props {
  selectedProducts: IProduct[] | IVideo[] | undefined
  detachProductsHandler: (data: SelectionAttachDetachDataType) => AxiosPromise
  isLoading: boolean
  id: idType
  columns?: ColumnsType<any>
}

const SelectionSelectedProductsList: React.FC<props> = ({
  selectedProducts,
  detachProductsHandler,
  isLoading,
  id,
  columns,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<idType[]>([])
  const handleCheck = (keys: idType[]) => setSelectedRowKeys(keys)

  const defaultColumns: ColumnsType<IProduct> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
    },
    {
      title: 'Артикул',
      dataIndex: 'sku',
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Наличие',
      dataIndex: 'stock',
      render: (stock: IProductStock) => (
        <Tag color={stock?.stock ? 'blue' : 'red'}>
          {stock?.stock ? 'В наличии' : 'Отсутствует'}
        </Tag>
      ),
    },
  ]

  const handleDelete = () => detachProductsHandler({ id, products: selectedRowKeys })

  const tableTitle = () => (
    <Button
      type={'primary'}
      loading={isLoading}
      disabled={!selectedRowKeys.length || isLoading}
      icon={<DeleteOutlined />}
      danger
      onClick={handleDelete}
    >
      Удалить товар(-ы) из подборки
    </Button>
  )

  return (
    <Table
      title={tableTitle}
      columns={columns || defaultColumns}
      dataSource={selectedProducts}
      rowKey={'id'}
      pagination={false}
      size={'small'}
      rowSelection={{
        selectedRowKeys,
        onChange: handleCheck,
      }}
    />
  )
}

export default SelectionSelectedProductsList
