import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ICroppedCategory } from '@app/interfaces/category'
import { idType, IPagination } from '@app/interfaces'
import {
  CategoryColor,
  CategoryLink,
  ImageWithFallback,
  Pagination,
  SearchInput,
  StatusTag,
} from '@app/shared'
import { CATEGORIES_KEY } from '@app/hooks/query/categories'

interface props {
  categories: ICroppedCategory[]
  pagination: IPagination
}

const CategoriesTable: React.FC<props> = ({ categories, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={CATEGORIES_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={CATEGORIES_KEY} />

  const columns: ColumnsType<ICroppedCategory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: 'Наименование',
      key: 'title',
      render: ({ title, id }: ICroppedCategory) => <CategoryLink title={title} id={id} />,
    },
    {
      title: 'Изображение',
      dataIndex: 'image_path',
      render: (image_path: string) => <ImageWithFallback src={image_path} width={75} />,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Цвет',
      dataIndex: 'color',
      render: (color: string) => <CategoryColor color={color} />,
    },
  ]

  return (
    <Table
      columns={columns}
      title={tableTitle}
      footer={tableFooter}
      bordered
      rowKey={'id'}
      scroll={{ x: true }}
      pagination={false}
      dataSource={categories}
    />
  )
}

export default CategoriesTable
