import { useQuery, UseQueryOptions } from 'react-query'
import { AuthService } from '@app/api'
import { AxiosResponse } from 'axios'
import { IUser } from '@app/interfaces/user'

export const AUTH_KEY = 'auth_key'

export function useAuthUserInformation(options?: UseQueryOptions<IUser, Error>) {
  return useQuery<IUser, Error>(
    AUTH_KEY,
    () => AuthService.me().then((response: AxiosResponse<IUser>) => response.data),
    {
      refetchOnMount: false,
      ...options,
    },
  )
}
