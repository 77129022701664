import React from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { SetsService } from '@app/api'
import { queryClient } from '@app/index'
import { SETS_QUERY_KEY } from '@app/hooks/query/sets'
import { useParams } from 'react-router-dom'

export interface SetImportProductsProps {}

const SetImportProducts: React.FC<SetImportProductsProps> = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <Upload
      accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
      showUploadList={false}
      action={(file): Promise<any> => {
        const formData = new FormData()
        formData.append('file', file)
        return SetsService.import({ id, data: formData })
          .then((response) => {
            if (response.status === 200) {
              queryClient.invalidateQueries([SETS_QUERY_KEY, id])
              message.success('Импорт успешно завершился')
            }
          })
          .catch((e) => {
            message.error('Ошибка импорта! Попробуйте снова')
          })
      }}
    >
      <Button icon={<UploadOutlined />}>Импорт товаров xlsx</Button>
    </Upload>
  )
}

export default SetImportProducts
