import React from 'react'
import { message, Select } from 'antd'
import { useMutation } from 'react-query'
import { ProductsService } from '@app/api'
import { useProductVideoLayouts } from '@app/hooks/query/products'
import { idType } from '@app/interfaces'

interface IFormSelectVideoLayoutProps {
  productId: idType
  defaultVideoLayout: string
}

const FormSelectVideoLayout: React.FC<IFormSelectVideoLayoutProps> = ({
  productId,
  defaultVideoLayout,
}) => {
  const { isLoading, isError, error, data } = useProductVideoLayouts()
  const { mutate, isLoading: isUpdating } = useMutation(ProductsService.setProductVideoLayout, {
    onSuccess: () => {
      message.success('Данные успешно обновлены')
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте снова')
    },
  })

  const handleMutate = (video_layout: string) => mutate({ id: productId, video_layout })

  if (isError) return <React.Fragment>Произошла ошибка! Попробуйте позже</React.Fragment>
  return (
    <Select
      defaultValue={defaultVideoLayout}
      onChange={handleMutate}
      disabled={isLoading}
      loading={isLoading || isUpdating}
      style={{ width: '100%' }}
      size={'large'}
      placeholder={'Выберите макет видеоблока (по умолчанию сетка)'}
      bordered={false}
    >
      {data?.map((item) => (
        <Select.Option value={item} key={item}>
          {item === 'default' ? 'Сетка (по умолчанию)' : 'Слайдер'}
        </Select.Option>
      ))}
    </Select>
  )
}

export default FormSelectVideoLayout
