import React, { useState } from 'react'
import { idType } from '@app/interfaces'
import { Button, Drawer, Form, Input, InputNumber, message, Space } from 'antd'
import { FormContentInput, FormOrderInput, FormSelectProduct, FormSeoInputs } from '@app/shared'
import { ISetProduct, ISetProductFormData } from '@app/interfaces/sets'
import { useMutation } from 'react-query'
import { SetProductsService } from '@app/api'
import { queryClient } from '@app/index'
import { SETS_QUERY_KEY } from '@app/hooks/query/sets'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'

export interface SetProductsProps {
  setId: idType
  product?: ISetProduct
}

const SetProductForm: React.FC<SetProductsProps> = ({ setId, product }) => {
  const [form] = Form.useForm<ISetProductFormData>()
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const onSuccess = () => {
    queryClient.invalidateQueries([SETS_QUERY_KEY])
    handleChangeVisible()
  }
  const onError = () => {
    message.error('Произошла ошибка! Попробуйте снова')
  }
  const create = useMutation(SetProductsService.add, { onSuccess, onError })
  const update = useMutation(SetProductsService.update, { onSuccess, onError })

  const onFinish = async (data: ISetProductFormData) => {
    let formData: ISetProductFormData

    if (typeof data.product_id !== 'object') {
      formData = { ...data, product_id: data.product_id as idType }
    } else {
      formData = { ...data, product_id: data.product_id.value }
    }

    product ? await update.mutateAsync(formData) : await create.mutateAsync(formData)
    form.resetFields()
  }

  const title = product ? 'Изменить товар ' + product.product.sku : 'Добавить товар'
  const loading = create.isLoading || update.isLoading

  return (
    <>
      <Button
        icon={product ? <EditOutlined /> : <PlusCircleOutlined />}
        type={product ? 'primary' : 'default'}
        onClick={handleChangeVisible}
      >
        {title}
      </Button>
      <Drawer
        visible={visible}
        title={title}
        width={'750px'}
        placement={'right'}
        onClose={handleChangeVisible}
        destroyOnClose={true}
        footer={
          <Space>
            <Button
              type={'primary'}
              htmlType={'submit'}
              onClick={form.submit}
              disabled={loading}
              loading={loading}
            >
              Сохранить
            </Button>
            <Button type={'default'} htmlType={'button'} onClick={handleChangeVisible}>
              Закрыть
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            set_id: setId,
            ...product,
          }}
          onFinish={onFinish}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'set_id'} hidden></Form.Item>
          <FormSelectProduct name={'product_id'} disabled={!!product} />
          <FormOrderInput name={'sort_order'} required={false} />
          <Form.Item name={['page', 'h1']} label={'Заголовок H1'}>
            <Input />
          </Form.Item>
          <FormSeoInputs isPageTitleRequired={false} />
          <FormContentInput />
          <Space size={18}>
            <Form.Item name={'old_price'} label={'Старая цена'} rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            -
            <Form.Item name={'price'} label={'Новая цена'} rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
          </Space>
        </Form>
      </Drawer>
    </>
  )
}

export default SetProductForm
