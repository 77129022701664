import React from 'react'
import { Form, Select, SelectProps, Space } from 'antd'
import { useCategoriesFilters } from '@app/hooks/query/categories'
import { SelectValue } from 'antd/es/select'
import { SiteLink } from '@app/shared'

const { Option } = Select

interface props {
  label?: string
  name?: string | any[]
  required?: boolean
  selectProps?: SelectProps<SelectValue>
}

const FormSelectCategories: React.FC<props> = ({
  label = 'Категория',
  name = 'category_id',
  required,
  selectProps,
}) => {
  const { isLoading: isCategoriesLoading, data } = useCategoriesFilters({
    refetchOnMount: false,
  })

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select
        showSearch={true}
        loading={isCategoriesLoading}
        disabled={isCategoriesLoading}
        allowClear={!required}
        placeholder={'Выберите категорию'}
        filterOption={(input, option) =>
          ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
        }
        {...selectProps}
      >
        {data?.map(({ id, title, slug }) => (
          <Option key={id} value={id} label={title}>
            <Space>
              {title}
              <SiteLink entityType={'category'} small={true} type={'link'} slug={slug} />
            </Space>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSelectCategories
