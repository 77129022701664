import React from 'react'
import styles from './category-color.module.scss'
import {Tag} from "antd"

interface props {
	color: string
}

const CategoryColor: React.FC<props> = ({color}) => {
	return <Tag className={styles.tag} color={color}/>
}

export default React.memo(CategoryColor)
