import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import queryString from 'query-string'
import { history } from '@api/history'
import { IBanner } from '@app/interfaces/banners'

const url = '/api/banners'

class BannersService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: IBanner) {
    return makeRequest({
      url,
      method: 'post',
      data,
    })
  }

  update(data: { banner: IBanner; id: idType }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'patch',
      data: data.banner,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }
}

export default new BannersService()
