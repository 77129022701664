import React from 'react'
import { Button, Card, Col, Form, FormInstance, Input, Row, Switch, Typography } from 'antd'
import { FormDatePeriod, FormFileManagerSelect, FormOrderInput } from '@app/shared'
import { MinusCircleOutlined } from '@ant-design/icons'
import FormBannerLink from '@app/shared/form-banner-link/form-banner-link'

interface props {
  formInstance: FormInstance
  remove: (n: number) => void
  name: number
}

const FormSlot: React.FC<props> = ({ formInstance, remove, name }) => {
  const handleRemoveSlot = () => remove(name)

  const handleLinkTypeChange = () => {
    formInstance.setFields([
      { name: ['slots', name, 'link_category_id'], value: null },
      { name: ['slots', name, 'link'], value: null },
      { name: ['slots', name, 'external_link'], value: false },
      { name: ['slots', name, 'promotag_id'], value: null },
    ])
  }

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24} xl={14}>
        <Card bordered={false} title={'Общая информация'}>
          <Form.Item name={[name, 'link_title']} label={'Заголовок'}>
            <Input placeholder={'Введите заголовок'} />
          </Form.Item>
          <FormBannerLink
            name={name}
            formInstance={formInstance}
            categoryLinkName={[name, 'link_category_id']}
            customLinkName={[name, 'link']}
            isExternalLinkName={[name, 'external_link']}
            promotagName={[name, 'promotag_id']}
            handleLinkTypeChange={handleLinkTypeChange}
          />
          <Row gutter={[16, 16]}>
            <Col flex={1}>
              <FormOrderInput name={[name, 'order']} />
            </Col>
            <Col>
              <FormDatePeriod name={[name, 'date']} required={false} />
            </Col>
          </Row>
          <Form.Item name={[name, 'active']} label={'Статус'} valuePropName={'checked'}>
            <Switch checkedChildren={'Включен'} unCheckedChildren={'Отключен'} />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} xl={10}>
        <Card
          bordered={false}
          title={
            <Typography.Paragraph>
              Изображения{' '}
              <Typography.Text type={'danger'}>
                (Эти поля обязательны для заполнения)
              </Typography.Text>
            </Typography.Paragraph>
          }
        >
          <FormFileManagerSelect
            label={'Мобильное изображение'}
            formInstance={formInstance}
            pathFieldName={['slots', name, 'mobile_image', 'path']}
            filenameFieldName={['slots', name, 'mobile_image', 'filename']}
          />
          <FormFileManagerSelect
            label={'Планшетное изображение'}
            formInstance={formInstance}
            pathFieldName={['slots', name, 'tablet_image', 'path']}
            filenameFieldName={['slots', name, 'tablet_image', 'filename']}
          />
          <FormFileManagerSelect
            label={'ПК изображение'}
            formInstance={formInstance}
            pathFieldName={['slots', name, 'desktop_image', 'path']}
            filenameFieldName={['slots', name, 'desktop_image', 'filename']}
          />
        </Card>
      </Col>
      <Col xs={24}>
        <Button
          onClick={handleRemoveSlot}
          block
          icon={<MinusCircleOutlined />}
          danger
          type={'dashed'}
        />
      </Col>
    </Row>
  )
}

export default FormSlot
