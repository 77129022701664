import React from 'react'
import { Form } from 'antd'
import { TextEditor } from '@app/shared'

interface props {
  name?: string | string[]
  label?: string
  required?: boolean
  placeholder?: string
}

const FormContentInput: React.FC<props> = ({
  name = ['page', 'content'],
  label = 'Контент',
  required = false,
  placeholder = 'Введите контент',
}) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <TextEditor placeholder={placeholder} />
    </Form.Item>
  )
}

export default FormContentInput
