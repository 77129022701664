import React from 'react'
import { AddLink, ErrorBoundary, ExportFile, ImportFromFile, PageInfo } from '@app/shared'
import { message, PageHeader, Row, Skeleton, Space } from 'antd'
import { ProductSelectionTable } from '@app/pages/product-selection-page/components'
import { useSelections } from '@app/hooks/query/selections'
import { useMutation } from 'react-query'
import { SelectionsService } from '@app/api'
import { RcFile } from 'antd/es/upload'

interface props {}

const ProductSelectionPage: React.FC<props> = () => {
  const { isLoading, isError, data, refetch } = useSelections()

  const importFromFile = useMutation(SelectionsService.import, {
    onSuccess: () => {
      refetch()
      message.success('Операция завершена успешно')
    },
    onError: () => {
      message.error('Ошибка импорта! Попробуйте снова')
    },
  })
  const handleImportFromFile = (file: RcFile) => {
    const formData = new FormData()
    formData.append('file', file)
    importFromFile.mutate(formData)
  }

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data: selections, ...pagination } = data!

  return (
    <React.Fragment>
      <PageInfo title={'Подборка товаров'} />
      <PageHeader
        title={'Подборка товаров'}
        extra={[<AddLink key={'1'} route={'add_product_selection'} />]}
      />
      <Row justify={'end'}>
        <Space>
          <ImportFromFile
            key={'2'}
            importFn={handleImportFromFile}
            isLoading={importFromFile.isLoading}
          />
          <ExportFile key={'3'} exportFn={SelectionsService.export} filename={'selections'} />
        </Space>
      </Row>
      <ProductSelectionTable data={selections} pagination={pagination} />
    </React.Fragment>
  )
}

export default ProductSelectionPage
