import React, { useState } from 'react'
import { Drawer, Form, Input, message, Switch } from 'antd'
import { IBrand } from '@app/interfaces/brands'
import {
  DrawerButton,
  FormButtons,
  FormFileManagerSelect,
  FormOrderInput,
  ImageWithFallback,
} from '@app/shared'
import { BrandsService } from '@app/api'
import { useMutation } from 'react-query'
import { queryClient } from '@app/index'
import { BRANDS_KEY } from '@app/hooks/query/brands'

interface props {
  brand?: IBrand
}

const BrandsForm: React.FC<props> = ({ brand }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState<boolean>(false)
  const handleChangeVisible = () => setVisible(!visible)

  const onSuccess = async () => {
    await queryClient.invalidateQueries(BRANDS_KEY)
    message.success(brand ? 'Бренд успешно отредактирован' : 'Бренд успешно добавлен')
    handleChangeVisible()
  }
  const onError = () => {
    message.error('Произошла ошибка! Попробуйте снова.')
  }
  const update = useMutation(BrandsService.update, { onSuccess, onError })
  const create = useMutation(BrandsService.create, { onSuccess, onError })

  const onFinish = (v: IBrand) => {
    const fieldValues = { ...v, active: v.active === undefined ? true : v.active }
    return brand ? update.mutateAsync(fieldValues) : create.mutateAsync(fieldValues)
  }

  const title = brand ? 'Изменить бренд' : 'Добавить бренд'
  return (
    <>
      <DrawerButton isEditing={!!brand} clickHandler={handleChangeVisible} />
      <Drawer
        width={450}
        title={title}
        visible={visible}
        onClose={handleChangeVisible}
        destroyOnClose={true}
        closable={true}
        footer={true}
      >
        <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={brand}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={'Назвние бренда'} rules={[{ required: true }]}>
            <Input placeholder={'Введите название'} />
          </Form.Item>
          <FormOrderInput required={false} />
          <FormFileManagerSelect
            formInstance={form}
            label={'Выберите файл'}
            pathFieldName={'path'}
            filenameFieldName={'filename'}
            initialPath={'/advertisements'}
          />
          <Form.Item label={'Изображение'}>
            <ImageWithFallback src={brand?.logo} height={150} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['image', 'path']}
              filenameFieldName={['image', 'filename']}
              initialPath={'/brands'}
              required
            />
          </Form.Item>
          <Form.Item name={'active'} valuePropName={'checked'} label={'Статус'}>
            <Switch
              defaultChecked={true}
              checkedChildren={'Включен'}
              unCheckedChildren={'Отключен'}
            />
          </Form.Item>
          <FormButtons isLoading={brand ? update.isLoading : create.isLoading} />
        </Form>
      </Drawer>
    </>
  )
}

export default BrandsForm
