import React from 'react'
import { Form, InputNumber } from 'antd'

interface props {
  name?: string | any[]
  required?: boolean
}

const FormOrderInput: React.FC<props> = ({ name = 'order', required = true }) => {
  const styles = { width: '100%' }
  return (
    <Form.Item name={name} label={'Порядковый номер'} rules={[{ required }]}>
      <InputNumber placeholder={'Введите порядковый номер'} style={styles} />
    </Form.Item>
  )
}

export default FormOrderInput
