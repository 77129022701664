import { useQuery, UseQueryOptions } from 'react-query'
import { NewsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { INews, INewsResponse } from '@app/interfaces/news'
import { idType } from '@app/interfaces'

export const NEWS_KEY = 'news_key'

export function useNews(options?: UseQueryOptions<INewsResponse, Error>) {
  return useQuery<INewsResponse, Error>(
    NEWS_KEY,
    () => NewsService.getAll().then((response: AxiosResponse<INewsResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useSingleNews(id: idType, options?: UseQueryOptions<INews, Error>) {
  return useQuery<INews, Error>(
    [NEWS_KEY, id],
    () => NewsService.getById(id).then((response) => response.data),
    {
      ...options,
    },
  )
}
