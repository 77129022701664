import React from 'react'
import { Card, Col, Form, Row } from 'antd'
import {
  FormButtons,
  FormContentInput,
  FormDatePicker,
  FormFileManagerSelect,
  FormSelectCategories,
  FormSelectTags,
  FormSeoInputs,
  FormTitleWithSlugInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { IArticle } from '@app/interfaces/articles'
import moment from 'moment'
import { idType } from '@app/interfaces'

interface props {
  article?: IArticle
  submitFunction: (article: IArticle, id?: idType) => void
  isLoading: boolean
}

const ArticleForm: React.FC<props> = ({ article, isLoading, submitFunction }) => {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const articleObject: IArticle = {
      ...values,
      date: moment(values.date).format('YYYY-MM-DD'),
    }

    return submitFunction(articleObject, article?.id)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{
        ...article,
        tags: article?.tags.map((tag) => tag.id),
        date: article?.date ? moment(article.date, 'DD-MM-YYYY') : undefined,
        categories: article?.categories?.map((cat) => cat.id),
      }}
    >
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={14}>
          <Card
            bordered={false}
            title={'Общая информация'}
            extra={article && <SiteLink entityType={'press-centr/blog/' + article.slug} />}
          >
            <FormTitleWithSlugInputs form={form} id={article?.id} />
            <FormSelectCategories
              label={'Категории'}
              name={'categories'}
              selectProps={{ mode: 'multiple' }}
            />
            <FormSelectCategories
              label={'Категория (устаревшее)'}
              name={'category_id'}
              required={false}
            />
            <FormSelectTags />
            <FormDatePicker required={false} />
            <Form.Item name={['page', 'short_content']} label={'Короткое описание'}>
              <TextEditor placeholder={'Введите короткое описание'} />
            </Form.Item>
            <FormContentInput required={true} />
          </Card>
        </Col>
        <Col xs={24} xl={10}>
          <Row gutter={[32, 32]}>
            <Col xs={24}>
              <Card bordered={false} title={'Изображение'}>
                <ImageWithFallback height={256} src={article?.image_path || 'error'} />
                <FormFileManagerSelect
                  formInstance={form}
                  pathFieldName={['image', 'path']}
                  filenameFieldName={['image', 'filename']}
                  initialPath={article?.image.path || '/articles'}
                  required
                />
              </Card>
            </Col>
            <Col xs={24}>
              <Card bordered={false} title={'SEO'}>
                <FormSeoInputs />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default ArticleForm
