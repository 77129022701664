import { Form, Radio } from 'antd'
import React from 'react'

interface props {}

const FeedbackFormRadio: React.FC<props> = () => {
  return (
    <Form.Item name={'feedback_form'} label={'Форма обратной связи'} rules={[{ required: true }]}>
      <Radio.Group>
        <Radio.Button value={false}>Отключена</Radio.Button>
        <Radio.Button value={true}>Включена</Radio.Button>
      </Radio.Group>
    </Form.Item>
  )
}

export default FeedbackFormRadio
