import { idType } from '@app/interfaces'
import makeRequest from '@api/makeRequest'
import { VideoData } from '@app/interfaces/video'

const url = '/api/videos'

class VideosService {
  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: VideoData) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }
  update(data: VideoData) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PUT',
      data: {
        title: data.title,
        link: data.link,
      },
    })
  }
  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }
}

export default new VideosService()
