import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import { VideoBlockService } from '@app/api'
import { IVideoBlock } from '@app/interfaces/video-block'

export const CATEGORY_VIDEO_BLOCK = 'category_video_block'

export function useCategoryVideoBlock(id: idType, options?: UseQueryOptions<IVideoBlock, Error>) {
  return useQuery<IVideoBlock, Error>(
    [CATEGORY_VIDEO_BLOCK, id],
    () =>
      VideoBlockService.getById(id).then((response: AxiosResponse<IVideoBlock>) => response.data),
    {
      ...options,
    },
  )
}
