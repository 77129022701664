import React, { useState } from 'react'
import { useCategoryFilterAttributes } from '@app/hooks/query/filter-attributes'
import { useParams } from 'react-router-dom'
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd'
import { ErrorBoundary } from '@app/shared'
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { FilterAttributesForm } from '@app/pages/category-page/components'

interface props {}

const FilterAttributes: React.FC<props> = () => {
  const [edit, setEdit] = useState<boolean>(false)
  const changeEditStatus = () => setEdit(!edit)

  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useCategoryFilterAttributes(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <Row>
      <Col xs={24}>
        <Card
          title={<Typography.Title level={3}>Фильтры</Typography.Title>}
          extra={[
            <Button
              type={'default'}
              shape={'round'}
              danger={edit}
              onClick={changeEditStatus}
              icon={edit ? <CloseCircleOutlined /> : <EditOutlined />}
              key={'1'}
            >
              {edit ? 'Отменить' : 'Изменить'}
            </Button>,
          ]}
        >
          <FilterAttributesForm
            changeEditStatus={changeEditStatus}
            edit={edit}
            data={data!}
            categoryId={id}
          />
        </Card>
      </Col>
    </Row>
  )
}

export default React.memo(FilterAttributes)



