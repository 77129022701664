import React from 'react'
import { useMutation } from 'react-query'
import { GenerateService } from '@app/api'
import { Button, message } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

interface props {}

const GenerateSitemapButton: React.FC<props> = () => {
  const { isLoading, mutateAsync } = useMutation(GenerateService.sitemap, {
    onSuccess: () => {
      message.success('Sitemap успешно сгенерирован')
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const handleButtonClick = () => mutateAsync()

  return (
    <Button
      icon={<SyncOutlined />}
      disabled={isLoading}
      loading={isLoading}
      onClick={handleButtonClick}
      type={'primary'}
    >
      Сгенерировать sitemap
    </Button>
  )
}

export default GenerateSitemapButton
