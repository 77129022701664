import { useQuery, UseQueryOptions } from 'react-query'
import { ArticlesService } from '@app/api'
import { AxiosResponse } from 'axios'
import { IArticle, IArticleResponse } from '@app/interfaces/articles'
import { idType } from '@app/interfaces'

export const ARTICLES_KEY = 'articles_key'

export function useArticles(options?: UseQueryOptions<IArticleResponse, Error>) {
  return useQuery<IArticleResponse, Error>(
    ARTICLES_KEY,
    () =>
      ArticlesService.getAll().then((response: AxiosResponse<IArticleResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useArticle(id: idType, options?: UseQueryOptions<IArticle, Error>) {
  return useQuery<IArticle, Error>(
    [ARTICLES_KEY, id],
    () => ArticlesService.getById(id).then((response: AxiosResponse<IArticle>) => response.data),
    {
      ...options,
    },
  )
}
