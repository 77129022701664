import { idType } from '@app/interfaces'
import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { INewSlot } from '@app/interfaces/slots'

const url = '/api/banner-slots'

class SlotsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: INewSlot) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  slotGroups() {
    return makeRequest({
      url: url + '/slot-groups',
    })
  }
}

export default new SlotsService()
