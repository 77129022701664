import React from 'react'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { Button, message } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import fileDownload from 'js-file-download'

export interface ExportFileProps {
  exportFn: () => Promise<AxiosResponse<Blob>>
  filename: string
  extension?: 'xlsx'
}

const ExportFile: React.FC<ExportFileProps> = ({ exportFn, filename, extension = 'xlsx' }) => {
  const { isLoading, mutate } = useMutation(exportFn, {
    onSuccess: (file) => {
      fileDownload(file.data, `${Date.now()}-${filename}.${extension}`)
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  const onClick = () => mutate()

  return (
    <Button
      type={'primary'}
      icon={<FileOutlined />}
      onClick={onClick}
      disabled={isLoading}
      loading={isLoading}
    >
      Экспортировать
    </Button>
  )
}

export default ExportFile
