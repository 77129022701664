import React from 'react'
import { BannerForm, PageInfo } from '@app/shared'
import { message, PageHeader } from 'antd'
import { useMutation } from 'react-query'
import { BannersService } from '@app/api'
import { useHistory } from 'react-router-dom'
import { IBanner } from '@app/interfaces/banners'
import { idType } from '@app/interfaces'

interface props {}

const AddBannerPage: React.FC<props> = () => {
  const {goBack} = useHistory()

  const {isLoading, mutateAsync} = useMutation(BannersService.create, {
    onSuccess: () => {
      message.success('Баннер успешно добавлен')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Проверьте данные и попробуйте снова')
    }
  })

  const submitFunction = (banner:IBanner, id?:idType) => mutateAsync(banner)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить баннер'} />
      <PageHeader title={'Добавить баннер'} />
      <BannerForm submitFunction={submitFunction} isLoading={isLoading} />
    </React.Fragment>
  )
}

export default AddBannerPage
