import { IForm } from '@app/interfaces/forms'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { FormsService } from '@app/api'

export const FORMS_KEY = 'forms_key'

export function useForms(options?: UseQueryOptions<IForm[], Error>) {
  return useQuery<IForm[], Error>(
    FORMS_KEY,
    () => FormsService.getAll().then((response: AxiosResponse<IForm[]>) => response.data),
    {
      ...options,
    },
  )
}
