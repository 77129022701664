import React from 'react'
import { Menu } from 'antd'
import {
  AppstoreOutlined,
  AuditOutlined,
  BgColorsOutlined,
  BookOutlined,
  BuildOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DeliveredProcedureOutlined,
  DesktopOutlined,
  ExportOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  GlobalOutlined,
  GroupOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  InsertRowLeftOutlined,
  InteractionOutlined,
  MedicineBoxOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PercentageOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RobotOutlined,
  SafetyCertificateOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  StarOutlined,
  SyncOutlined,
  TabletOutlined,
  TagsOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UngroupOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { privateRoutes } from '@app/routes'
import { Link, useLocation } from 'react-router-dom'

const { SubMenu } = Menu

interface props {}

const menu = [
  {
    title: 'Главная',
    path: privateRoutes.home.path,
    icon: <HomeOutlined />,
  },
  {
    title: 'Статические элементы',
    path: privateRoutes.static_elements.path,
    icon: <DesktopOutlined />,
  },
  {
    title: 'Категории',
    path: privateRoutes.categories.path,
    icon: <ShopOutlined />,
  },
  {
    title: 'Баннеры-заглушки',
    path: privateRoutes.slots.path,
    icon: <FileImageOutlined />,
  },
  {
    title: 'Товары',
    path: privateRoutes.products.path,
    icon: <ShoppingOutlined />,
  },
  {
    title: 'Баннеры',
    path: privateRoutes.banners.path,
    icon: <UngroupOutlined />,
  },
  {
    title: 'Промо акции',
    path: privateRoutes.promotions.path,
    icon: <PercentageOutlined />,
  },
  {
    title: 'Подборка товаров',
    path: privateRoutes.product_selection.path,
    icon: <ShoppingCartOutlined />,
  },
  {
    title: 'Шаблонные страницы',
    path: privateRoutes.template_pages.path,
    icon: <InsertRowLeftOutlined />,
  },
  {
    title: 'Контент страницы',
    icon: <AppstoreOutlined />,
    children: [
      {
        title: 'Статьи',
        path: privateRoutes.articles.path,
        icon: <BookOutlined />,
      },
      {
        title: 'Новости',
        path: privateRoutes.news.path,
        icon: <ContainerOutlined />,
      },
      {
        title: 'Награды и достижения',
        path: privateRoutes.achievements.path,
        icon: <AuditOutlined />,
      },
      {
        title: 'Рекламные материалы',
        path: privateRoutes.advertisements.path,
        icon: <ReconciliationOutlined />,
      },
      {
        title: 'СМИ о нас',
        path: privateRoutes.smi.path,
        icon: <GlobalOutlined />,
      },
      {
        title: 'Помощь',
        path: privateRoutes.help_page.path,
        icon: <InfoCircleOutlined />,
      },
      {
        title: 'Как сделать заказ',
        path: privateRoutes.htmo_page.path,
        icon: <OrderedListOutlined />,
      },
      {
        title: 'Доставка и оплата',
        path: privateRoutes.delivery_payment.path,
        icon: <DeliveredProcedureOutlined />,
      },
      {
        title: 'Гарантия и сертификаты',
        path: privateRoutes.guarantee_certificates.path,
        icon: <SafetyCertificateOutlined />,
      },
      {
        title: 'Служба заботы',
        path: privateRoutes.care_service.path,
        icon: <MedicineBoxOutlined />,
      },
      {
        title: 'О компании',
        path: privateRoutes.about_company.path,
        icon: <TeamOutlined />,
      },
      {
        title: 'Ценности и миссия',
        path: privateRoutes.values_mission.path,
        icon: <ThunderboltOutlined />,
      },
      {
        title: 'Наши бренды',
        path: privateRoutes.our_brands.path,
        icon: <StarOutlined />,
      },
      {
        title: 'Вакансии',
        path: privateRoutes.vacancies.path,
        icon: <UserAddOutlined />,
      },
      {
        title: 'Контакты',
        path: privateRoutes.contacts_page.path,
        icon: <ContactsOutlined />,
      },
      {
        title: 'Пресс-центр',
        path: privateRoutes.press_center.path,
        icon: <NotificationOutlined />,
      },
      {
        title: 'Оптовые продажи',
        path: privateRoutes.wholesale.path,
        icon: <BuildOutlined />,
      },
      {
        title: 'Оптовые подборки',
        path: privateRoutes.sets.path,
        icon: <BuildOutlined />,
      },
    ],
  },
  {
    title: 'Справочники',
    icon: <ReadOutlined />,
    children: [
      {
        title: 'Промотеги',
        path: privateRoutes.promotags.path,
        icon: <TagsOutlined />,
      },
      {
        title: 'Бренды',
        path: privateRoutes.brands.path,
        icon: <StarOutlined />,
      },
      {
        title: 'Группы баннеров',
        path: privateRoutes.banners_groups.path,
        icon: <GroupOutlined />,
      },
      {
        title: 'Размеры',
        path: privateRoutes.sizes.path,
        icon: <FontSizeOutlined />,
      },
      {
        title: 'Цвета',
        path: privateRoutes.colors.path,
        icon: <BgColorsOutlined />,
      },
    ],
  },
  {
    title: 'Настройки',
    icon: <SettingOutlined />,
    children: [
      {
        title: 'Редиректы',
        path: privateRoutes.redirects.path,
        icon: <InteractionOutlined />,
      },
      {
        title: 'Формы',
        path: privateRoutes.forms.path,
        icon: <FormOutlined />,
      },
      // {
      //   title: 'Elastic Search',
      //   path: privateRoutes.settings.path,
      //   icon: <SecurityScanOutlined />,
      // },
      // {
      //   title: 'Синхронизация',
      //   path: privateRoutes.sync.path,
      //   icon: <SyncOutlined />,
      // },
      // {
      //   title: 'Robots.txt',
      //   path: privateRoutes.robots.path,
      //   icon: <RobotOutlined />,
      // },
    ],
  },
  {
    title: 'Экспорт',
    path: privateRoutes.exports.path,
    icon: <ExportOutlined />,
  },
  {
    title: 'Управление ЛК',
    icon: <TabletOutlined />,
    children: [
      {
        title: 'Пользователи',
        path: privateRoutes.lk_users.path,
        icon: <React.Fragment />,
      },
      {
        title: 'Менеджеры',
        path: privateRoutes.lk_managers.path,
        icon: <React.Fragment />,
      },
      {
        title: 'Баннеры',
        path: privateRoutes.lk_banners.path,
      },
    ],
  },
]

const LayoutMenu: React.FC<props> = () => {
  const { pathname } = useLocation()

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[pathname]}>
      {menu.map(({ title, icon, children, path }) => {
        if (children) {
          return (
            <SubMenu key={title} icon={icon} title={title}>
              {children.map(({ title, icon, path }) => (
                <Menu.Item key={path} icon={icon}>
                  <Link to={path}>{title}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        }

        return (
          <Menu.Item key={path} icon={icon}>
            <Link to={path!}>{title}</Link>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default LayoutMenu
