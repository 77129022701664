import makeRequest from '@api/makeRequest'

const url = '/api/partners'

class PartnersService {
  list() {
    return makeRequest({ url: url + '/list' })
  }
}

export default new PartnersService()
