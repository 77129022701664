import React, { useContext } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { SpaceWithDivider } from '@app/shared'
import { CheckCircleOutlined } from '@ant-design/icons'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {}

const Footer: React.FC<props> = () => {
  const { path, handleCancel, selectedFile, onSelectFile } = useContext(FileManagerContext)

  return (
    <Row gutter={[16, 16]} align={'middle'} justify={'space-between'}>
      <Col>
        <Typography.Text type={'secondary'}>Путь: Корневая папка{path}</Typography.Text>
      </Col>
      <Col>
        <SpaceWithDivider>
          <Button onClick={handleCancel}>Отменить</Button>
          <Button
            disabled={!selectedFile}
            onClick={onSelectFile}
            type={'primary'}
            icon={<CheckCircleOutlined />}
          >
            Выбрать
          </Button>
        </SpaceWithDivider>
      </Col>
    </Row>
  )
}

export default Footer
