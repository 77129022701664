import React, { useContext, useState } from 'react'
import { CloseOutlined, DeleteOutlined, EditOutlined, FolderOutlined } from '@ant-design/icons'
import { FolderButton } from '@app/shared/file-manager/components'
import { Dropdown, Form, Input, Menu, message } from 'antd'
import styles from './folder.module.scss'
import { useMutation } from 'react-query'
import { FileManagerService } from '@app/api'
import { queryClient } from '@app/index'
import { FILE_MANAGER_KEY } from '@app/hooks/query/file-manager'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {
  folder: string
}

const Folder: React.FC<props> = ({ folder }) => {
  const { path, handleShowNextPath, isFetching, params } = useContext(FileManagerContext)
  const [rename, setRename] = useState(false)
  const handleRename = () => setRename(!rename)
  const handleClick = () => handleShowNextPath(folder)

  const renameDirectory = useMutation(FileManagerService.renameDirectory, {
    onSuccess: () => {
      message.success('Директория успешно переименована')
      handleRename()
      queryClient.invalidateQueries([FILE_MANAGER_KEY, { path, params }])
    },
    onError: () => {
      message.error('Произошла ошибка!')
    },
  })

  const deleteDirectory = useMutation(FileManagerService.deleteDirectory, {
    onSuccess: () => {
      message.success('Директория успешно удалена')
      queryClient.invalidateQueries([FILE_MANAGER_KEY, { path, params }])
    },
    onError: () => {
      message.error('Ошибка удаления директории')
    },
  })

  const handleDelete = () => deleteDirectory.mutateAsync(path + '/' + folder)

  const menu = (
    <Menu>
      <Menu.Item key={'1'} icon={<EditOutlined />} onClick={handleRename}>
        Переименовать
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={'2'}
        danger
        icon={<DeleteOutlined />}
        disabled={deleteDirectory.isLoading}
        onClick={handleDelete}
      >
        Удалить
      </Menu.Item>
    </Menu>
  )

  const onRename = (data: { newPath: string }) =>
    renameDirectory.mutateAsync({
      currentPath: path + '/' + folder,
      newPath: path + '/' + data.newPath,
    })

  return (
    <Dropdown
      className={styles.dropdown}
      overlay={menu}
      trigger={['contextMenu']}
      placement={'bottomLeft'}
    >
      {rename ? (
        <Form onFinish={onRename}>
          <Form.Item initialValue={folder} name={'newPath'} rules={[{ required: true }]} noStyle>
            <Input
              autoFocus
              placeholder={'Введите название папки'}
              prefix={<FolderOutlined />}
              suffix={<CloseOutlined onClick={handleRename} />}
            />
          </Form.Item>
        </Form>
      ) : (
        <FolderButton
          key={folder}
          onClick={handleClick}
          icon={<FolderOutlined />}
          disabled={isFetching}
        >
          {folder}
        </FolderButton>
      )}
    </Dropdown>
  )
}

export default React.memo(Folder)
