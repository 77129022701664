import React from 'react'
import { useParams } from 'react-router-dom'
import { useBannerGroup } from '@app/hooks/query/banner-groups'
import { Card, Divider, Skeleton, Space, Typography } from 'antd'
import { EditLink, ErrorBoundary, PageInfo } from '@app/shared'
import moment from 'moment'

interface props {}

const BannersGroupPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data: response } = useBannerGroup(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { title, name, created_at } = response!
  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Card
        title={'Общая информация'}
        extra={[<EditLink id={id} route={'banners_groups'} key={'1'} />]}
      >
        <Typography.Title level={2}>{title}</Typography.Title>
        <Space split={<Divider />}>
          <Typography.Text type={'secondary'}>ID: {id}</Typography.Text>
          <Typography.Text type={'secondary'}>Наименование: {name}</Typography.Text>
          <Typography.Text type={'secondary'}>
            Дата создания: {moment(created_at).format('DD-MM-YYYY')}
          </Typography.Text>
        </Space>
      </Card>
    </React.Fragment>
  )
}

export default BannersGroupPage
