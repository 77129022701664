import React from 'react'
import { CategoryPageUpdateData, ICategoryTextField } from '@app/interfaces/category'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, message, Popconfirm, Typography } from 'antd'
import { useMutation } from 'react-query'
import { CategoriesService } from '@app/api'
import { queryClient } from '@app/index'
import { CATEGORIES_KEY } from '@app/hooks/query/categories'
import { useParams } from 'react-router-dom'
import { SpaceWithDivider, TextEditor } from '@app/shared'

interface props {
  textField: ICategoryTextField | null
}

const CategoryTextField: React.FC<props> = ({ textField }) => {
  const [form] = Form.useForm()

  const { id } = useParams<{ id: string }>()
  const { isLoading, mutateAsync } = useMutation(CategoriesService.updatePageContent, {
    onSuccess: () => {
      message.success('Контент успешно обновлен')
      queryClient.invalidateQueries([CATEGORIES_KEY, id])
    },
    onError: () => {
      message.error('Ошибка обновления контента')
    },
  })

  const createTextFieldHandler = () =>
    mutateAsync({
      id,
      title: 'Полезная информация',
      content: '',
    })

  const deleteTextFieldHandler = () => {
    mutateAsync({
      id,
      title: null,
      content: null,
    })
  }

  if (!textField || (!textField.title && !textField.content)) {
    return (
      <Button
        type={'dashed'}
        block
        icon={<EditOutlined />}
        onClick={createTextFieldHandler}
        loading={isLoading}
        disabled={isLoading}
      >
        Создать текстовый блок "Полезная информация"
      </Button>
    )
  }

  const handleChangeTitle = (title: string) =>
    mutateAsync({
      id,
      title,
      content: textField.content,
    })

  const onFinish = (data: Pick<CategoryPageUpdateData, 'content'>) =>
    mutateAsync({
      id,
      title: textField.title,
      content: data.content,
    })

  return (
    <React.Fragment>
      <Form.Item>
        <SpaceWithDivider>
          <Typography.Title
            editable={{ onChange: handleChangeTitle }}
            level={3}
            style={{ marginBottom: 0 }}
          >
            {textField.title}
          </Typography.Title>
          <Popconfirm title={'Вы уверены?'} onConfirm={deleteTextFieldHandler}>
            <Button
              type={'primary'}
              danger
              htmlType={'button'}
              icon={<DeleteOutlined />}
              loading={isLoading}
              disabled={isLoading}
              size={'small'}
            />
          </Popconfirm>
        </SpaceWithDivider>
      </Form.Item>

      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item name={'content'} initialValue={textField.content}>
          <TextEditor placeholder={'Введите контент'} />
        </Form.Item>
        <Button
          type={'primary'}
          htmlType={'submit'}
          icon={<SaveOutlined />}
          loading={isLoading}
          disabled={isLoading}
          block
        >
          Сохранить изменения
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default CategoryTextField
