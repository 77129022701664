import { Form, Select } from 'antd'
import React from 'react'
import { useAdvertisementTypes } from '@app/hooks/query/ads-types'

interface props {
  name?: string
  label?: string
  required?: boolean
}

const FormAdsTypesSelect: React.FC<props> = ({
  name = 'type_id',
  label = 'Выберите категорию рекламных материалов',
  required = true,
}) => {
  const { isFetching, data } = useAdvertisementTypes()

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select disabled={isFetching} loading={isFetching}>
        {data?.map((at) => (
          <Select.Option value={at.id} key={at.id}>
            {at.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormAdsTypesSelect
