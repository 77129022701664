import React from 'react'
import { Col, Form, Input, Row, Switch } from 'antd'
import { FormListMovingArrows } from '@app/shared'

interface props {}

const StaticFormSocials: React.FC<props> = () => {
  return (
    <Form.List name="socials">
      {(fields, { move }) => (
        <>
          {fields.map((field) => (
            <Row key={field.name} gutter={[12, 12]} align={'middle'}>
              <Col>
                <FormListMovingArrows move={move} name={field.name} />
              </Col>
              <Col>
                <Form.Item {...field} label={'Slug'} name={[field.name, 'slug']}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item {...field} label="Заголовок" name={[field.name, 'title']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item
                  {...field}
                  label="Ссылка на соц. сеть"
                  name={[field.name, 'url']}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  {...field}
                  label={'Статус'}
                  name={[field.name, 'active']}
                  valuePropName={'checked'}
                >
                  <Switch checkedChildren={'Включена'} unCheckedChildren={'Отключена'} />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </>
      )}
    </Form.List>
  )
}

export default StaticFormSocials
