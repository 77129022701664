import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import { IFullImage } from '@app/interfaces/smi'

const url = '/api/promotags'

class PromotagsSerivce {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  getProducts(id: idType) {
    return makeRequest({
      url: url + '/' + id + '/products',
      params: queryString.parse(history.location.search),
    })
  }

  getPromotagsList() {
    return makeRequest({
      url: url + '/list',
    })
  }

  attachImage(data: { id: idType; data: Pick<IFullImage, 'path' | 'filename'> }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data: {
        image: data.data,
      },
    })
  }
}

export default new PromotagsSerivce()
