import React from 'react'
import {ARTICLES_KEY, useArticle} from '@app/hooks/query/articles'
import {useHistory, useParams} from 'react-router-dom'
import {message, PageHeader, Skeleton} from 'antd'
import {ArticleForm, ErrorBoundary, PageInfo} from '@app/shared'
import {useMutation} from "react-query"
import {ArticlesService} from "@app/api"
import {IArticle} from "@app/interfaces/articles"
import {idType} from "@app/interfaces"
import {queryClient} from "@app/index"

interface props {}

const EditArticlePage: React.FC<props> = () => {
  const {goBack} = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useArticle(id, {
    refetchOnMount: false,
  })

  const {isLoading: isEditLoading, mutateAsync} = useMutation(ArticlesService.update, {
    onSuccess: () => {
      message.success('Статья успешно изменена')
      queryClient.invalidateQueries([ARTICLES_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая статья уже существует')
    }
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />


  const submitFunction = (article: IArticle, id?:idType) => mutateAsync({article, id: id!})

  return (
    <React.Fragment>
      <PageInfo title={data!.page.title} />
      <PageHeader title={data!.page.title} />
      <ArticleForm isLoading={isEditLoading} article={data} submitFunction={submitFunction}/>
    </React.Fragment>
  )
}

export default EditArticlePage
