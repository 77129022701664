import React from 'react'
import {
  AddLink,
  DeleteModal,
  EditLink,
  ErrorBoundary,
  ImageWithFallback,
  PageInfo,
  Pagination,
  SearchInput,
  SiteLink,
  SpaceWithDivider,
} from '@app/shared'
import { PageHeader, Skeleton, Table, Tabs } from 'antd'
import { SMI_LIST_KEY, useSmiList } from '@app/hooks/query/smi'
import { ISmi } from '@app/interfaces/smi'
import moment from 'moment'
import { SmiService } from '@app/api'
import { SmiPageEditor } from '@app/pages/smi-page/components'

interface props {}

const SmiPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useSmiList()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const tableTitle = () => <SearchInput fetchingQueryKey={SMI_LIST_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={SMI_LIST_KEY} />

  return (
    <React.Fragment>
      <PageInfo title={'СМИ о нас'} />
      <PageHeader
        title={'СМИ о нас'}
        extra={
          <SpaceWithDivider>
            <SiteLink entityType={'press-centr/smi'} />
            <AddLink key={'1'} route={'add_smi'} />
          </SpaceWithDivider>
        }
      />
      <Tabs defaultActiveKey={'table'}>
        <Tabs.TabPane key={'table'} tab={'Таблица'}>
          <Table<ISmi>
            dataSource={data}
            title={tableTitle}
            footer={tableFooter}
            pagination={false}
            rowKey={'id'}
          >
            <Table.Column<ISmi> key={'id'} title={'ID'} dataIndex={'id'} />
            <Table.Column<ISmi> key={'title'} title={'Заголовок'} dataIndex={'title'} />
            <Table.Column<ISmi>
              key={'order'}
              dataIndex={'order'}
              title={'Порядковый номер'}
              render={(order: number | null) => (order ? order : 'Не указано')}
            />
            <Table.Column<ISmi>
              key={'image_url'}
              title={'Превью'}
              dataIndex={'image_url'}
              render={(src: string) => (
                <ImageWithFallback src={src} alt={''} preview={true} width={150} />
              )}
            />
            <Table.Column<ISmi>
              key={'full_image_url'}
              title={'Полное изображение'}
              dataIndex={'full_image_url'}
              render={(src: string) => (
                <ImageWithFallback src={src} alt={''} preview={true} width={150} />
              )}
            />
            <Table.Column<ISmi>
              key={'created_at'}
              title={'Дата создания'}
              dataIndex={'created_at'}
              render={(createdAt: string) => <span>{moment(createdAt).format('DD-MM-YYYY')}</span>}
            />
            <Table.Column<ISmi>
              key={'actions'}
              title={'Действия'}
              render={(record: ISmi) => (
                <SpaceWithDivider>
                  <EditLink route={'smi'} id={record.id} />
                  <DeleteModal
                    deleteFunction={SmiService.delete}
                    invalidateQueryKey={SMI_LIST_KEY}
                    id={record.id}
                  />
                </SpaceWithDivider>
              )}
            />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key={'editor'} tab={'Редактор страницы'}>
          <SmiPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default SmiPage
