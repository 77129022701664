import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { FileManagerService } from '@app/api'
import { IFileManagerData, IFileManagerResponse } from '@app/interfaces/file-manager'

export const FILE_MANAGER_KEY = 'file-manager-key'

export function useFileManager(
  data: IFileManagerData,
  options?: UseQueryOptions<IFileManagerResponse, Error>,
) {
  return useQuery<IFileManagerResponse, Error>(
    [FILE_MANAGER_KEY, data],
    () =>
      FileManagerService.getPath(data).then(
        (response: AxiosResponse<IFileManagerResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
