import { ISetProduct } from '@app/interfaces/sets'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { SetProductsService } from '@app/api'
import { idType } from '@app/interfaces'

export const SET_PRODUCT_QUERY_KEY = 'set-product-query-key'

export function useSetProduct(id: idType, options?: UseQueryOptions<ISetProduct, Error>) {
  return useQuery<ISetProduct, Error>(
    [SET_PRODUCT_QUERY_KEY],
    () =>
      SetProductsService.getById(id).then((response: AxiosResponse<ISetProduct>) => response.data),
    {
      ...options,
    },
  )
}
