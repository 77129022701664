import React from 'react'
import { AddLink, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { SlotsTable } from '@app/pages/slots-page/components'
import { useSlots } from '@app/hooks/query/slots'

interface props {}

const SlotsPage: React.FC<props> = () => {
  const { isLoading, isError, data: response } = useSlots()
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Баннеры-заглушки'} />
      <PageHeader title={'Баннеры-заглушки'} extra={[<AddLink route={'add_slot'} key={'1'} />]} />
      <SlotsTable data={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default SlotsPage
