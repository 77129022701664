import React from 'react'
import { IAdvertisement } from '@app/interfaces/advertisements'
import { Button, Form, Input, Table } from 'antd'
import { DeleteModal, EditLink, ImageWithFallback, Pagination, SpaceWithDivider } from '@app/shared'
import { ADVERTISEMENTS_KEY } from '@app/hooks/query/advertisements'
import { ColumnsType } from 'antd/lib/table'
import { IAdvertisementType } from '@app/interfaces/ads-types'
import { AdvertisementsService } from '@app/api'
import { IPagination } from '@app/interfaces'
import { useHistory, useLocation } from 'react-router-dom'
import { FilterOutlined } from '@ant-design/icons'
import FormSelectAdsType from '../../../../shared/form-select-ads-type/form-select-ads-type'
import { serializeQuery } from '@app/utils/serializeQuery'
import { queryClient } from '@app/index'

interface props {
  data: IAdvertisement[]
  pagination: IPagination
}

const AdvertisementsTable: React.FC<props> = ({ data, pagination }) => {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const handleFilter = (data: { q: string; type_id: string }) => {
    push({
      pathname,
      search: serializeQuery(data),
    })

    queryClient.invalidateQueries(ADVERTISEMENTS_KEY)
  }

  const handleReset = () => {
    push({
      pathname,
      search: '',
    })
    queryClient.invalidateQueries(ADVERTISEMENTS_KEY)
  }

  const tableTitle = () => {
    return (
      <Form onFinish={handleFilter} layout={'inline'}>
        <Form.Item name={'q'} label={'Поиск'}>
          <Input />
        </Form.Item>
        <FormSelectAdsType />
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} icon={<FilterOutlined />}>
            Фильтрация
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleReset} htmlType={'reset'} type={'default'}>
            Сбросить
          </Button>
        </Form.Item>
      </Form>
    )
  }
  const tableFooter = () => (
    <Pagination pagination={pagination} fetchingQueryKey={ADVERTISEMENTS_KEY} />
  )

  const columns: ColumnsType<IAdvertisement> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (text: string) => <>{text ? text : 'Отсутствует'}</>,
    },
    {
      title: 'Изображение',
      dataIndex: 'image_path',
      render: (path: string) => <ImageWithFallback src={path} width={100} />,
    },
    {
      title: 'Категория рекламных материалов',
      dataIndex: 'type',
      render: (type: IAdvertisementType) => <>{type.title}</>,
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      render: (text: number) => <>{text ? text : '-'}</>,
    },
    {
      title: 'Для взрослых',
      dataIndex: 'adult',
      render: (adult: boolean) => <>{adult ? 'Да' : 'Нет'}</>,
    },
    {
      title: 'Прикрепленный файл',
      dataIndex: 'file',
      render: (text?: string) =>
        text ? (
          <a href={text} rel={'noopener noreferrer'} target={'_blank'}>
            Открыть
          </a>
        ) : (
          'Отсутствует'
        ),
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (record: IAdvertisement) => (
        <SpaceWithDivider>
          <EditLink id={record.id} route={'advertisements'} />
          <DeleteModal
            id={record.id}
            deleteFunction={AdvertisementsService.delete}
            invalidateQueryKey={ADVERTISEMENTS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      title={tableTitle}
      footer={tableFooter}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      rowKey={'id'}
      bordered
    />
  )
}

export default AdvertisementsTable
