import React from 'react'
import { IForm } from '@app/interfaces/forms'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'antd'
import { FormAddress } from '../index'

interface props {
  data: IForm[]
}

const FormsTable: React.FC<props> = ({ data }) => {
  const columns: ColumnsType<IForm> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Название формы',
      dataIndex: 'subject',
    },
    {
      title: 'Адрес почты',
      dataIndex: 'to',
      width: 450,
      render: (text: string, { id }: IForm) => <FormAddress address={text} id={id} />,
    },
  ]

  return <Table columns={columns} dataSource={data} rowKey={'id'} />
}

export default FormsTable
