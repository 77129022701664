import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { FormListMovingArrows } from '@app/shared'

interface props {
  name: string
}

const StaticFormLinks: React.FC<props> = ({ name }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove, move }) => (
        <>
          {fields.map((field) => (
            <Row key={field.name} gutter={[12, 12]} align={'middle'}>
              <Col>
                <FormListMovingArrows move={move} name={field.name} />
              </Col>
              <Col flex={1}>
                <Form.Item
                  {...field}
                  label="Заголовок"
                  name={[field.name, 'label']}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item
                  {...field}
                  label="Адрес ссылки"
                  name={[field.name, 'url']}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Col>
            </Row>
          ))}

          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Добавить ссылку
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default StaticFormLinks
