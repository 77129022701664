import React, { useState } from 'react'
import { Button, Form, message, Modal, Select } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { FormDatePeriod } from '@app/shared'
import { useForms } from '@app/hooks/query/forms'
import { useMutation } from 'react-query'
import { FormsService } from '@app/api'
import { AxiosResponse } from 'axios'
import FileDownload from 'js-file-download'
import { idType } from '@app/interfaces'

interface props {}

const ExportFeedbackForms: React.FC<props> = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState<boolean>(false)
  const handleVisibleChange = () => setVisible(!visible)
  const { isLoading, data } = useForms()

  const exportForms = useMutation(FormsService.export)

  const onFinish = (data: { forms: idType[]; date: string[] }) =>
    exportForms
      .mutateAsync({
        forms: data.forms.join(','),
        from: data.date[0],
        to: data.date[1],
      })
      .then((response: AxiosResponse<File>) => {
        FileDownload(response.data, 'Feedback-forms-' + new Date().toLocaleDateString() + '.xlsx')
        message.success('Данные экспортированы')
      })
      .catch((e) => {
        message.error(e.response.data.message)
      })

  const title = 'Выгрузка информации из форм обратной связи'
  return (
    <React.Fragment>
      <Button icon={<FormOutlined />} onClick={handleVisibleChange}>
        {title}
      </Button>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleVisibleChange}
        okButtonProps={{ htmlType: 'submit', form: 'export-feedback-forms' }}
        okText={'Экспорт'}
      >
        <Form onFinish={onFinish} form={form} id={'export-feedback-forms'} layout={'vertical'}>
          <FormDatePeriod required={true} label={'Выберите период'} />
          <Form.Item name={'forms'} rules={[{ required: true }]}>
            <Select
              mode={'multiple'}
              loading={isLoading}
              disabled={isLoading}
              allowClear={false}
              placeholder={'Выберите формы для экспорта'}
            >
              {data?.map((form) => (
                <Select.Option value={form.id} key={form.id}>
                  {form.subject}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ExportFeedbackForms
