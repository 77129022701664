import React from 'react'
import { Tag } from 'antd'

interface props {
  status: boolean
}

const StatusTag: React.FC<props> = ({ status }) => {
  return <Tag color={status ? 'green' : 'volcano'}>{status ? 'Включен' : 'Отключен'}</Tag>
}

export default React.memo(StatusTag)
