import React from 'react'
import { IAchievement } from '@app/interfaces/achievements'
import { idType, IPagination } from '@app/interfaces'
import { DeleteModal, EditLink, ImageWithFallback, Pagination, SpaceWithDivider } from '@app/shared'
import { ACHIEVEMENTS_KEY } from '@app/hooks/query/achievements'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { AchievementsService } from '@app/api'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'

interface props {
  achievements: IAchievement[]
  pagination: IPagination
}

const AchievementsTable: React.FC<props> = ({ achievements, pagination }) => {
  const tableFooter = () => (
    <Pagination pagination={pagination} fetchingQueryKey={ACHIEVEMENTS_KEY} />
  )

  const columns: ColumnsType<IAchievement> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Заголовок',
      key: 'title',
      render: ({ id, title }: IAchievement) => (
        <Link to={privateRoutes.achievements.path + '/details/' + id}>{title}</Link>
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'image_w400',
      render: (image: string) => <ImageWithFallback src={image} height={75} />,
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (created_at: string) => (
        <React.Fragment>{new Date(created_at).toLocaleDateString()}</React.Fragment>
      ),
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: ({ id }: IAchievement) => (
        <SpaceWithDivider>
          <EditLink id={id} route={'achievements'} />
          <DeleteModal
            id={id}
            invalidateQueryKey={ACHIEVEMENTS_KEY}
            deleteFunction={AchievementsService.delete}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      footer={tableFooter}
      bordered
      rowKey={'id'}
      scroll={{ x: true }}
      pagination={false}
      dataSource={achievements}
    />
  )
}

export default AchievementsTable
