import React from 'react'
import { useBannerGroupsFilter } from '@app/hooks/query/banner-groups'
import { Form, Select } from 'antd'

interface props {
  name?: string | any[]
  required?: boolean
}

const FormSelectBannerGroups: React.FC<props> = ({ name = 'banner_group_id', required = true }) => {
  const { data, isLoading } = useBannerGroupsFilter({
    refetchOnMount: false,
  })

  return (
    <Form.Item name={name} label={'Группа баннеров'} rules={[{ required }]}>
      <Select
        showSearch={true}
        placeholder={'Выберите группу'}
        allowClear={false}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={isLoading}
        loading={isLoading}
      >
        {data?.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSelectBannerGroups
