import makeRequest from '@api/makeRequest'
import { ISetProductFormData } from '@app/interfaces/sets'
import { idType } from '@app/interfaces'

const url = '/api/setProducts'

class SetProductsService {
  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }
  add(data: ISetProductFormData) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: ISetProductFormData) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  deleteMultiple(ids: idType[]) {
    return makeRequest({
      url: url + '/delete',
      method: 'DELETE',
      data: { ids },
    })
  }
}

export default new SetProductsService()
