import { SlotsService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IBannersResponse } from '@app/interfaces/banners'
import { IBannerGroup } from '@app/interfaces/banner-groups'

export const SLOTS_KEY = 'slots_key'
export const SLOTS_BANNERS_KEY = 'slots_banners_key'

export function useSlots(options?: UseQueryOptions<IBannersResponse, Error>) {
  return useQuery<IBannersResponse, Error>(
    SLOTS_KEY,
    () => SlotsService.getAll().then((response: AxiosResponse<IBannersResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useSlotsBanners(options?: UseQueryOptions<IBannerGroup[], Error>) {
  return useQuery<IBannerGroup[], Error>(
    SLOTS_BANNERS_KEY,
    () =>
      SlotsService.slotGroups().then((response: AxiosResponse<IBannerGroup[]>) => response.data),
    {
      ...options,
    },
  )
}
