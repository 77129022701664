import React from 'react'
import { Form } from 'antd'
import { ProductsService } from '@app/api'
import { DebounceSelect } from '@app/shared'

export interface FormSelectProductProps {
  name?: string | [number, string]
  label?: string
  required?: boolean
  disabled?: boolean
}

const FormSelectProduct: React.FC<FormSelectProductProps> = ({
  name = 'product_id',
  label = 'Поиск по названию либо артиклю',
  required = true,
  disabled,
}) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <DebounceSelect
        placeholder={'Поиск товара'}
        allowClear={false}
        showSearch
        fetchOptions={ProductsService.search}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export default FormSelectProduct
