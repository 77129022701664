import React from 'react'
import { AddLink, ErrorBoundary, PageInfo, SiteLink } from '@app/shared'
import { PageHeader, Skeleton, Tabs } from 'antd'
import { AchievementPageEditor, AchievementsTable } from '@app/pages/achievements-page/components'
import { useAchievements } from '@app/hooks/query/achievements'

interface props {}

const AchievementsPage: React.FC<props> = () => {
  const { isLoading, isError, data: response } = useAchievements()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Награды и достижения'} />
      <PageHeader
        title={'Награды и достижения'}
        extra={[<AddLink key={'1'} route={'add_achievement'} />]}
      />
      <Tabs
        defaultActiveKey={'1'}
        tabBarExtraContent={{
          right: <SiteLink key={1} entityType={'o-kompanii/nagrady-i-dostizheniya'} />,
        }}
      >
        <Tabs.TabPane key={'1'} tab={'Таблица'}>
          <AchievementsTable achievements={data} pagination={pagination} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'2'} tab={'Редактор страницы'}>
          <AchievementPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default AchievementsPage
