import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import { FormSelectPromotags } from '@app/shared'
import { IProductBlock, IProductBlockData } from '@app/interfaces/product-block'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { ProductBlocksService } from '@app/api'
import { queryClient } from '@app/index'
import { EditOutlined, EditTwoTone, PlusCircleOutlined } from '@ant-design/icons'
import { CATEGORIES_KEY } from '@app/hooks/query/categories'
import { CATEGORY_PRODUCTS_BLOCK } from '@app/hooks/query/product-blocks'

interface props {
  block?: IProductBlock
}

const CategoryBlockModal: React.FC<props> = ({ block }) => {
  const [form] = Form.useForm<IProductBlockData>()
  const { id } = useParams<{ id: string }>()
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const onSuccess = () => {
    message.success('Изменения прошли успешно')
    handleChangeVisible()
    queryClient.invalidateQueries([CATEGORIES_KEY, id])
    queryClient.invalidateQueries([CATEGORY_PRODUCTS_BLOCK, block?.id])
  }
  const onError = () => {
    message.error('Ошибка создания блока')
  }

  const create = useMutation(ProductBlocksService.create, { onSuccess, onError })
  const update = useMutation(ProductBlocksService.update, { onSuccess, onError })
  const onFinish = (data: IProductBlockData) => {
    const handler = block ? update.mutate : create.mutate
    handler({
      ...data,
      promotag_id: data.promotag_id ? data.promotag_id : null,
    })
  }

  return (
    <>
      <Button
        type={block ? 'link' : 'dashed'}
        icon={
          block ? (
            <EditTwoTone style={block ? { fontSize: 24 } : undefined} />
          ) : (
            <PlusCircleOutlined />
          )
        }
        onClick={handleChangeVisible}
        block={!block}
      >
        {!block && 'Создать блок Хиты продаж'}
      </Button>
      <Modal
        visible={visible}
        onCancel={handleChangeVisible}
        title={'Добавить блок'}
        confirmLoading={create.isLoading}
        destroyOnClose
        okText={'Добавить'}
        okButtonProps={{
          onClick: form.submit,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{
            category_id: id,
            title: 'Хиты продаж',
            ...block,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'category_id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
            <Input placeholder={'Введите название блока'} />
          </Form.Item>
          <FormSelectPromotags formInstance={form} />
        </Form>
      </Modal>
    </>
  )
}

export default CategoryBlockModal
