import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { SelectionAttachDetachDataType, SelectionImportDataType } from '@app/interfaces/selections'
import { IVideoBlockData } from '@app/interfaces/video-block'

const url = '/api/video-blocks'

class VideoBlocksService {
  getAll() {
    return makeRequest({
      url,
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: IVideoBlockData) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: IVideoBlockData) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  importProducts(data: SelectionImportDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/importProducts',
      method: 'POST',
      data: data.file,
    })
  }

  attachProducts(data: SelectionAttachDetachDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/attachProducts',
      method: 'POST',
      data: {
        products: data.products,
      },
    })
  }

  detachVideos(data: SelectionAttachDetachDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/detachVideos',
      method: 'DELETE',
      data: {
        videos: data.products,
      },
    })
  }

  updateProduct(data: { productId: idType; videoId: idType; title: string }) {
    return makeRequest({
      url: url + '/' + data.videoId + '/product/' + data.productId,
      method: 'PUT',
      data: {
        title: data.title,
      },
    })
  }
}

export default new VideoBlocksService()
