import makeRequest from '@api/makeRequest'
import { IProductBlockData } from '@app/interfaces/product-block'
import { idType } from '@app/interfaces'
import { SelectionAttachDetachDataType, SelectionImportDataType } from '@app/interfaces/selections'

const url = '/api/product-blocks'

class ProductBlocks {
  getAll() {
    return makeRequest({ url })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: IProductBlockData) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: IProductBlockData) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  attachProducts(data: SelectionAttachDetachDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/attachProducts',
      method: 'POST',
      data: {
        products: data.products,
      },
    })
  }

  dettachProducts(data: SelectionAttachDetachDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/detachProducts',
      method: 'DELETE',
      data: {
        products: data.products,
      },
    })
  }

  importProducts(data: SelectionImportDataType) {
    return makeRequest({
      url: url + '/' + data.id + '/importProducts',
      method: 'POST',
      data: data.file,
    })
  }
}

export default new ProductBlocks()
