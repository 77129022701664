import React from 'react'
import { Button } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'

interface props {
  isEditing: boolean
  clickHandler: () => void
}

const DrawerButton: React.FC<props> = ({ isEditing, clickHandler }) => {
  return (
    <Button
      type={'primary'}
      icon={isEditing ? <EditOutlined /> : <PlusOutlined />}
      onClick={clickHandler}
      shape={!isEditing ? 'round' : undefined}
      size={!isEditing ? 'large' : 'middle'}
      ghost={!isEditing}
    >
      {isEditing ? 'Изменить' : 'Добавить'}
    </Button>
  )
}

export default DrawerButton
