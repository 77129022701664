import React from 'react'
import { AddLink, ErrorBoundary, PageInfo, SiteLink, SpaceWithDivider } from '@app/shared'
import { PageHeader, Skeleton, Tabs } from 'antd'
import { useAdvertisements } from '@app/hooks/query/advertisements'
import {
  AdvertisementsPageEditor,
  AdvertisementsTable,
} from '@app/pages/advertisements-page/components'

interface props {}

const AdvertisementsPage: React.FC<props> = () => {
  const { isLoading, isError, data } = useAdvertisements()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data: advertisements, ...pagination } = data!

  return (
    <React.Fragment>
      <PageInfo title={'Рекламные материалы'} />
      <PageHeader
        title={'Рекламные материалы'}
        extra={
          <SpaceWithDivider>
            <SiteLink entityType={'press-centr/reklamnye-materialy'} />
            <AddLink route={'addAdvertisement'} key={'1'} />
          </SpaceWithDivider>
        }
      />
      <Tabs defaultActiveKey={'table'}>
        <Tabs.TabPane key={'table'} tab={'Таблица'}>
          <AdvertisementsTable data={advertisements} pagination={pagination} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'editor'} tab={'Редактор страницы'}>
          <AdvertisementsPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default AdvertisementsPage
