import React from 'react'
import { useLkBanners } from '@app/hooks/query/lk-banners'
import { PageHeader, Skeleton } from 'antd'
import { AddLink, ErrorBoundary, PageInfo } from '@app/shared'
import { LkBannersTable } from './components'

export interface LkBannersPageProps {}

const LkBannersPage: React.FC<LkBannersPageProps> = () => {
  const { data: response, isLoading, isError } = useLkBanners()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!
  return (
    <React.Fragment>
      <PageInfo title={'ЛК Баннеры'} />
      <PageHeader title={'ЛК Баннеры'} extra={[<AddLink route={'add_banner'} key={'1'} />]} />
      <LkBannersTable data={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default LkBannersPage
