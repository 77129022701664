import React from 'react'
import { Card, Checkbox, Col, Form, Input, Row } from 'antd'
import {
  FormAdsTypesSelect,
  FormButtons,
  FormFileManagerSelect,
  FormOrderInput,
  ImageWithFallback,
} from '@app/shared'
import { IAdvertisement } from '@app/interfaces/advertisements'

interface props {
  submitFunction: (ads: IAdvertisement) => void
  isLoading: boolean
  ads?: IAdvertisement
}

const AdvertisementForm: React.FC<props> = ({ submitFunction, isLoading, ads }) => {
  const [form] = Form.useForm()
  const onFinish = (v: any) => {
    const fieldValues = {
      ...v,
      path: v.path || null,
      filename: v.filename || null,
      adult: v.adult || false,
    }

    submitFunction(fieldValues)
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={ads}>
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <Card title={'Общая информация'} bordered={false}>
            <Form.Item name={'id'} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={'title'}>
              <Input placeholder={'Введите заголовок'} />
            </Form.Item>
            <FormOrderInput required={false} />
            <FormAdsTypesSelect />
            <FormFileManagerSelect
              formInstance={form}
              label={'Выберите файл'}
              pathFieldName={'path'}
              filenameFieldName={'filename'}
              initialPath={'/advertisements'}
            />
            <Form.Item name={'adult'} valuePropName={'checked'}>
              <Checkbox>Для взрослых (18+)</Checkbox>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title={'Изображение'} bordered={false}>
            <ImageWithFallback src={ads?.image_path} />
            <FormFileManagerSelect
              formInstance={form}
              filenameFieldName={['image', 'filename']}
              pathFieldName={['image', 'path']}
              initialPath={ads?.image.path || '/advertisements'}
              required
            />
          </Card>
        </Col>
        <Col xs={24}>
          <FormButtons isLoading={isLoading} />
        </Col>
      </Row>
    </Form>
  )
}

export default AdvertisementForm
