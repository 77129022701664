import React from 'react'
import { Card, Col, Form, Row } from 'antd'
import moment from 'moment'
import { INews } from '@app/interfaces/news'
import {
  FormButtons,
  FormContentInput,
  FormDatePicker,
  FormFileManagerSelect,
  FormSeoInputs,
  FormTitleWithSlugInputs,
  ImageWithFallback,
  TextEditor,
} from '@app/shared'
import { idType } from '@app/interfaces'

interface props {
  news?: INews
  submitFunction: (data: INews, id?: idType) => void
  isLoading: boolean
}

const NewsForm: React.FC<props> = ({ news, submitFunction, isLoading }) => {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const newsObject: INews = {
      ...values,
      date: moment(values.date).format('YYYY-MM-DD'),
    }

    return submitFunction(newsObject, news?.id)
  }

  return (
    <Form
      initialValues={{
        ...news,
        date: news?.date ? moment(news?.date, 'DD-MM-YYYY') : undefined,
      }}
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={14}>
          <Card bordered={false} title={'Общая информация'}>
            <FormTitleWithSlugInputs form={form} id={news?.id} />
            <FormDatePicker required={false} />
            <Form.Item name={['page', 'short_content']} label={'Короткое описание'}>
              <TextEditor placeholder={'Введите короткое описание'} />
            </Form.Item>
            <FormContentInput required={true} />
          </Card>
        </Col>
        <Col xs={24} xl={10}>
          <Row gutter={[32, 16]}>
            <Col xs={24}>
              <Card bordered={false} title={'Изображение'}>
                <ImageWithFallback height={256} src={news?.image_path || 'error'} />
                <FormFileManagerSelect
                  initialPath={news?.image.path}
                  formInstance={form}
                  pathFieldName={['image', 'path']}
                  filenameFieldName={['image', 'filename']}
                />
              </Card>
            </Col>
            <Col xs={24}>
              <Card bordered={false} title={'SEO'}>
                <FormSeoInputs />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default NewsForm
