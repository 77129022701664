import React from 'react'
import { PageHeader } from 'antd'
import { SetForm } from '@app/shared'

export interface AddSetPageProps {}

const AddSetPage: React.FC<AddSetPageProps> = () => {
  return (
    <>
      <PageHeader title={'Добавить подборку'} />
      <SetForm />
    </>
  )
}

export default AddSetPage
