import React from 'react'
import { Button, Card, DatePicker, Form, message } from 'antd'
import { useMutation } from 'react-query'
import { ExportService } from '@app/api'
import FileDownload from 'js-file-download'
import { AxiosResponse } from 'axios'
import { MailOutlined } from '@ant-design/icons'

interface props {}

const ExportMailingList: React.FC<props> = () => {
  const [form] = Form.useForm()
  const { isLoading, mutateAsync } = useMutation(ExportService.subscribers)

  const handleExportMailingList = (data: { range: string[] }) => {
    mutateAsync({
      from: data.range[0],
      to: data.range[1],
    })
      .then((response: AxiosResponse<File>) => {
        FileDownload(response.data, 'Mailing-list-' + new Date().toLocaleDateString() + '.xlsx')
        message.success('Данные экспортированы')
      })
      .catch((e) => {
        message.error(e.response.data.message)
      })
  }

  return (
    <Card title={'Экспортировать почты рассылки'}>
      <Form onFinish={handleExportMailingList} layout={'vertical'}>
        <Form.Item name={'range'} label={'Период'} rules={[{ required: true }]}>
          <DatePicker.RangePicker picker={'date'} style={{ width: '100%' }} />
        </Form.Item>
        <Button
          type={'default'}
          htmlType={'submit'}
          loading={isLoading}
          disabled={isLoading}
          icon={<MailOutlined />}
          block
        >
          Экспорт
        </Button>
      </Form>
    </Card>
  )
}

export default ExportMailingList
