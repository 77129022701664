import React, { useState } from 'react'
import { Button, Col, Row, Skeleton, Table, Tag } from 'antd'
import { idType } from '@app/interfaces'
import { PRODUCTS_KEY, useProducts } from '@app/hooks/query/products'
import { ErrorBoundary, Pagination, SearchInput, StatusTag } from '@app/shared'
import { ColumnsType } from 'antd/lib/table'
import { ICroppedProduct, IProductStock } from '@app/interfaces/product'
import { PlusCircleOutlined } from '@ant-design/icons'
import { AxiosPromise } from 'axios'
import { SelectionAttachDetachDataType } from '@app/interfaces/selections'

interface props {
  productIds: idType[] | undefined
  attachProductsHandler: (data: SelectionAttachDetachDataType) => AxiosPromise
  isLoading: boolean
  id: idType
}

const SelectionProductsList: React.FC<props> = ({
  productIds,
  attachProductsHandler,
  isLoading: isAttachLoading,
  id,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<idType[]>([])
  const onSelectItem = async (selectedRowKeys: React.ReactText[]) => {
    setSelectedProducts(selectedRowKeys)
  }

  const addMultiple = () =>
    attachProductsHandler({
      id,
      products: selectedProducts,
    }).then(() => setSelectedProducts([]))
  const addSingle = (productId: idType) => attachProductsHandler({ id, products: [productId] })

  const { isLoading, isError, data } = useProducts()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const columns: ColumnsType<ICroppedProduct> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string) => <span># {text}</span>,
    },
    {
      title: 'Наименование товара',
      dataIndex: 'title',
    },
    {
      title: 'Артикул',
      dataIndex: 'sku',
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Наличие',
      dataIndex: 'stock',
      render: (stock: IProductStock) => (
        <Tag color={stock?.stock ? 'blue' : 'red'}>
          {stock?.stock ? 'В наличии' : 'Отсутствует'}
        </Tag>
      ),
    },
    {
      title: 'Действия',
      align: 'right',
      dataIndex: 'id',
      render: (id: idType) => (
        <Button
          type={'primary'}
          size={'small'}
          icon={<PlusCircleOutlined />}
          disabled={productIds?.includes(id) || isAttachLoading}
          loading={isAttachLoading}
          onClick={() => addSingle(id)}
        >
          Добавить
        </Button>
      ),
    },
  ]

  const { data: products, ...pagination } = data!
  const tableTitle = () => (
    <Row gutter={[32, 32]}>
      <Col xs={24} lg={12}>
        <Button
          icon={<PlusCircleOutlined />}
          loading={isAttachLoading}
          disabled={isAttachLoading || !selectedProducts.length}
          onClick={addMultiple}
        >
          Добавить несколько
        </Button>
      </Col>
      <Col xs={24} lg={12}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SearchInput fetchingQueryKey={PRODUCTS_KEY} />
        </div>
      </Col>
    </Row>
  )
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={PRODUCTS_KEY} />

  return (
    <Table
      size={'small'}
      title={tableTitle}
      footer={tableFooter}
      columns={columns}
      loading={isAttachLoading}
      dataSource={products}
      rowKey={'id'}
      scroll={{ x: true }}
      pagination={false}
      rowSelection={{
        selectedRowKeys: selectedProducts,
        onChange: onSelectItem,
        getCheckboxProps: (record: ICroppedProduct) => ({
          disabled: productIds?.includes(record.id),
        }),
      }}
    />
  )
}

export default SelectionProductsList
