import React from 'react'

interface props {}

const PromotionPage: React.FC<props> = () => {
  // const { id } = useParams<{ id: string }>()
  //
  // const { data, isLoading, isError } = usePromotion(id)
  //
  // if (isLoading) return <Skeleton active />
  // if (isError) return <ErrorBoundary />

  return <>PromotionPage</>
}

export default PromotionPage
