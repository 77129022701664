import { useQuery, UseQueryOptions } from 'react-query'
import { PromotagsService } from '@app/api'
import { idType } from '@app/interfaces'
import { AxiosResponse } from 'axios'
import { IPromoTag, IPromoTagsResponse, ISinglePromoTag } from '@app/interfaces/promotags'
import { IProductResponse } from '@app/interfaces/product'

export const PROMOTAGS_KEY = 'promotags_key'
export const PROMOTAGS_LIST_KEY = 'promotags_list_key'
export const PROMOTAG_PRODUCTS_KEY = 'promotags_products_key'

export function usePromotags(options?: UseQueryOptions<IPromoTagsResponse, Error>) {
  return useQuery<IPromoTagsResponse, Error>(
    PROMOTAGS_KEY,
    () =>
      PromotagsService.getAll().then(
        (response: AxiosResponse<IPromoTagsResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function usePromotag(id: idType, options?: UseQueryOptions<ISinglePromoTag, Error>) {
  return useQuery<ISinglePromoTag, Error>(
    [PROMOTAGS_KEY, id],
    () =>
      PromotagsService.getById(id).then(
        (response: AxiosResponse<ISinglePromoTag>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function usePromotagProducts(
  id: idType,
  options?: UseQueryOptions<IProductResponse, Error>,
) {
  return useQuery<IProductResponse, Error>(
    [PROMOTAG_PRODUCTS_KEY, id],
    () =>
      PromotagsService.getProducts(id).then(
        (response: AxiosResponse<IProductResponse>) => response.data,
      ),
    { ...options },
  )
}

export function usePromotagsList(options?: UseQueryOptions<IPromoTag[], Error>) {
  return useQuery<IPromoTag[], Error>(
    PROMOTAGS_LIST_KEY,
    () =>
      PromotagsService.getPromotagsList().then(
        (response: AxiosResponse<IPromoTag[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
