import { ISelection, ISelectionResponse } from '@app/interfaces/selections'
import { SelectionsService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'

export const SELECTIONS_KEY = 'selections_key'

export function useSelections(options?: UseQueryOptions<ISelectionResponse, Error>) {
  return useQuery<ISelectionResponse, Error>(
    SELECTIONS_KEY,
    () =>
      SelectionsService.getAll().then(
        (response: AxiosResponse<ISelectionResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useSelection(id: idType, options?: UseQueryOptions<ISelection, Error>) {
  return useQuery<ISelection, Error>(
    [SELECTIONS_KEY, id],
    () =>
      SelectionsService.getById(id).then((response: AxiosResponse<ISelection>) => response.data),
    {
      ...options,
    },
  )
}
