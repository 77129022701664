import React from 'react'
import { MinusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

interface props {
  remove: (name: number) => void
  name: number
}

const DeleteFormListBlock: React.FC<props> = ({ remove, name }) => {
  const onClick = () => remove(name)
  return (
    <Button type={'dashed'} size={'small'} onClick={onClick} icon={<MinusCircleOutlined />}>
      Удалить блок
    </Button>
  )
}

export default DeleteFormListBlock
