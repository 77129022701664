import React from 'react'
import { AutoComplete, Form } from 'antd'
import { useColors } from '@app/hooks/query/colors'

interface props {
  name?: string | [number, string]
  label?: string
  required?: boolean
}

const FormSelectColor: React.FC<props> = ({ name = 'color_id', label = 'Цвет', required }) => {
  const { isLoading, data } = useColors()

  const style = { width: '100%' }
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <AutoComplete
        disabled={isLoading}
        style={style}
        options={data?.map((color) => ({ value: color.title }))}
        placeholder="Введите цвет или выберите из списка ниже"
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  )
}

export default FormSelectColor
