import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { IChangeLkUserData } from '@app/interfaces/users'
import queryString from 'query-string'
import { history } from '@api/history'

const url = '/api/users'

class UsersService {
  all() {
    return makeRequest({
      url: url,
      params: queryString.parse(history.location.search),
    })
  }

  byId(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  update(data: IChangeLkUserData) {
    return makeRequest({
      method: 'PATCH',
      url: url + '/' + data.id,
      data,
    })
  }
}

export default new UsersService()
