import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'

const url = '/api/sizes'

class SizesService {
  list() {
    return makeRequest({
      url,
    })
  }

  create(title: string) {
    return makeRequest({
      url,
      method: 'POST',
      data: { title },
    })
  }

  update(data: { id: idType; title: string }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data: { title: data.title },
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }
}

export default new SizesService()
