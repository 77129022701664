import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IProductBlockWithProducts } from '@app/interfaces/product-block'
import { ProductBlocksService } from '@app/api'
import { idType } from '@app/interfaces'

export const CATEGORY_PRODUCTS_BLOCK = 'category_products_block'

export function useCategoryProductsBlock(
  id: idType,
  options?: UseQueryOptions<IProductBlockWithProducts, Error>,
) {
  return useQuery<IProductBlockWithProducts, Error>(
    [CATEGORY_PRODUCTS_BLOCK, id],
    () =>
      ProductBlocksService.getById(id).then(
        (response: AxiosResponse<IProductBlockWithProducts>) => response.data,
      ),
    {
      ...options,
    },
  )
}
