import React from 'react'
import { Form, Input, PageHeader, Skeleton, Typography } from 'antd'
import {
  IOurBrandsPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormSeoInputs,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'

interface props {}

const OurBrandsPage: React.FC<props> = () => {
  const pageType: StaticContentType = 'about_brands'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IOurBrandsPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IOurBrandsPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Наши бренды'}
        extra={[<SiteLink key={1} entityType={'o-kompanii/nashi-brendy'} />]}
      />
      <Form layout={'vertical'} onFinish={onFinish} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Form.Item label={'Заголовок слайдера'} name={'carousel_title'}>
          <Input placeholder={'Введите заголовок слайдера'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Form.Item label={'Текст вверху страницы'} name={'top_text'}>
          <TextEditor />
        </Form.Item>
        <FeedbackFormRadio />
        <FormButtons isLoading={update.isLoading} />
      </Form>
    </React.Fragment>
  )
}

export default OurBrandsPage
