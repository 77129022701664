import React from 'react'
import {
  DeleteModal,
  Pagination,
  RedirectsDrawer,
  SearchInput,
  SpaceWithDivider,
} from '@app/shared'
import { IRedirect } from '@app/interfaces/redirects'
import { idType, IPagination } from '@app/interfaces'
import { REDIRECTS_KEY } from '@app/hooks/query/redirects'
import { ColumnsType } from 'antd/es/table'
import { RedirectsService } from '@app/api'
import { Table } from 'antd'

interface props {
  redirects: IRedirect[]
  pagination: IPagination
}

const RedirectsTable: React.FC<props> = ({ redirects, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={REDIRECTS_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={REDIRECTS_KEY} />

  const columns: ColumnsType<IRedirect> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Откуда',
      dataIndex: 'from',
    },
    {
      title: 'Куда',
      dataIndex: 'to',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (created_at: string) => (
        <React.Fragment>{new Date(created_at).toLocaleDateString()}</React.Fragment>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (redirect: IRedirect) => (
        <SpaceWithDivider>
          <RedirectsDrawer redirect={redirect} />
          <DeleteModal
            id={redirect.id}
            deleteFunction={RedirectsService.delete}
            invalidateQueryKey={REDIRECTS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={redirects}
      scroll={{ x: true }}
      pagination={false}
      title={tableTitle}
      footer={tableFooter}
      rowKey={'id'}
    />
  )
}

export default RedirectsTable
