import React from 'react'
import { Tag } from 'antd'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { idType } from '@app/interfaces'
import styles from './promo-tag.module.scss'

interface props {
  color: string | undefined | null
  id: idType
  title: string
}

const PromoTag: React.FC<props> = ({ color, id, title }) => {
  return (
    <Tag color={color || 'default'}>
      <Link className={styles.link} to={privateRoutes.promotags.path + '/details/' + id}>
        {title}
      </Link>
    </Tag>
  )
}

export default React.memo(PromoTag)
