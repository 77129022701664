import React, { useContext, useState } from 'react'
import { Folder, FolderButton } from '@app/shared/file-manager/components'
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { FileManagerService } from '@app/api'
import { Divider, Form, Input, message } from 'antd'
import styles from './folders.module.scss'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {}

const Folders: React.FC<props> = () => {
  const { handleShowNextPath, path, isFetching, handleShowPrevPath, directories, pathFilter } =
    useContext(FileManagerContext)
  const [form] = Form.useForm()
  const [createDirectory, setCreateDirectory] = useState(false)
  const handleChangeCreateDirectory = () => setCreateDirectory(!createDirectory)

  const create = useMutation(FileManagerService.createDirectory, {
    onSuccess: () => {
      message.success('Директория успешно создана')
      handleShowNextPath(form.getFieldValue('dir'))
      form.resetFields()
      handleChangeCreateDirectory()
    },
    onError: () => {
      message.error('Ошибка создания директории')
    },
  })

  const onFinish = (data: { dir: string }) => create.mutateAsync(path + '/' + data.dir)

  return (
    <>
      {path.includes('/') && (
        <FolderButton disabled={isFetching} onClick={handleShowPrevPath}>
          ...
        </FolderButton>
      )}
      {directories
        ?.filter((val) => val.toLowerCase().includes(pathFilter.toLowerCase()))
        .map((folder, index) => (
          <Folder folder={folder} key={index} />
        ))}

      <Divider className={styles.divider} />
      {createDirectory ? (
        <Form form={form} onFinish={onFinish}>
          <Form.Item noStyle rules={[{ required: true }]} name={'dir'}>
            <Input
              disabled={create.isLoading}
              autoFocus
              placeholder={'Введите название директории'}
              bordered={false}
              suffix={<CloseOutlined onClick={handleChangeCreateDirectory} />}
            />
          </Form.Item>
        </Form>
      ) : (
        <FolderButton onClick={handleChangeCreateDirectory} icon={<PlusCircleOutlined />}>
          Создать директорию здесь
        </FolderButton>
      )}
    </>
  )
}

export default Folders
