import React from 'react'
import { IFIleManagerQueryParams } from '@app/interfaces/file-manager'

export interface IFileManagerContent {
  path: string
  pathFilter: string
  files: string[] | undefined
  handleCancel: () => void
  selectedFile: string | undefined
  handleSelectFile: (file: string) => void
  onSelectFile: () => void
  directories: string[] | undefined
  handleShowNextPath: (path: string) => void
  handleShowPrevPath: () => void
  handleChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeParams: (params: IFIleManagerQueryParams) => void
  isFetching: boolean
  params: IFIleManagerQueryParams
}

export const FileManagerContext = React.createContext<IFileManagerContent>({
  directories: undefined,
  files: undefined,
  selectedFile: undefined,
  handleCancel(): void {},
  handleChangeFilter(e: React.ChangeEvent<HTMLInputElement>): void {},
  handleSelectFile(file: string): void {},
  handleShowNextPath(path: string): void {},
  handleShowPrevPath(): void {},
  handleChangeParams(): void {},
  onSelectFile(): void {},
  isFetching: false,
  params: {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  },
  path: '',
  pathFilter: '',
})
