import React from 'react'
import { PageInfo } from '@app/shared'
import { PageHeader } from 'antd'
import { AddSlotForm } from '@app/pages/add-slot-page/components'

interface props {}

const AddSlotPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <PageInfo title={'Добавить баннеры-заглушки'} />
      <PageHeader title={'Добавить баннеры-заглушки'} />
      <AddSlotForm />
    </React.Fragment>
  )
}

export default AddSlotPage
