import React from 'react'
import { Button, Divider, Form, message, Typography } from 'antd'
import { FormButtons, FormSelectCategories } from '@app/shared'
import { useMutation } from 'react-query'
import { SlotsService } from '@app/api'
import { useHistory } from 'react-router-dom'
import { FormSlotGroupSelect } from '@app/pages/add-slot-page/components'
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import FormSlot from '@app/pages/add-slot-page/components/form-slot/form-slot'

interface props {}

const AddSlotForm: React.FC<props> = () => {
  const [form] = Form.useForm()
  const { goBack } = useHistory()

  const { isLoading, mutateAsync } = useMutation(SlotsService.create, {
    onSuccess: () => {
      message.success('Баннеры-заглушки успешно добавлены')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Проверьте данные и попробуйте снова')
    },
  })

  const onFinish = (values: any) => {
    const fieldValues: any = {
      ...values,
      slots: values.slots.map((slot: any) => {
        const temp = {
          ...slot,
          active: slot.active || false,
          link: slot.link || null,
          link_category_id: slot.link_category_id || null,
          promotag_id: slot.promotag_id || null,
          external_link: slot.external_link || false,
          date_start: slot['date'] && moment(slot['date'][0]).format('YYYY-MM-DD'),
          date_finish: slot['date'] && moment(slot['date'][1]).format('YYYY-MM-DD'),
        }
        delete temp['date']
        return temp
      }),
    }

    mutateAsync(fieldValues)
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <FormSelectCategories required={true} />
      <FormSlotGroupSelect name={'banner_group_id'} />
      <Typography.Title level={4}>Баннеры-заглушки</Typography.Title>
      <Form.List name="slots">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <FormSlot formInstance={form} name={name} remove={remove} key={key} />
            ))}
            <Divider />
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить баннер-заглушку
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default AddSlotForm
