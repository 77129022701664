import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import 'react-quill/dist/quill.snow.css'
import htmlEditButton from 'quill-html-edit-button'
import styles from './text-editor.module.scss'

Quill.register('modules/htmlEditButton', htmlEditButton)

//@ts-ignore
window.katex = katex

const modules = {
  htmlEditButton: {},
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

interface OnChangeHandler {
  (e: any): void
}

type Props = {
  value?: string
  placeholder?: string
  onChange?: OnChangeHandler
}

const TextEditor: React.FC<Props> = ({ value = '', onChange, placeholder }) => {
  return (
    <ReactQuill
      className={styles.editor}
      modules={modules}
      theme={'snow'}
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default TextEditor
