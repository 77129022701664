import React from 'react'
import { Table } from 'antd'
import { IPagination } from '@app/interfaces'
import { ILKUser } from '@app/interfaces/users'
import { Pagination, SearchInput, SpaceWithDivider, StatusTag } from '@app/shared'
import { LK_USERS_KEY } from '@app/hooks/query/users'
import { ColumnsType } from 'antd/es/table'
import { LkUserDrawer } from '@app/pages/lk-users-page/components'

export interface LkUsersTableProps {
  pagination: IPagination
  data: ILKUser[]
}

const LkUsersTable: React.FC<LkUsersTableProps> = ({ pagination, data }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={LK_USERS_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={LK_USERS_KEY} />

  const columns: ColumnsType<ILKUser> = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'name',
      title: 'ФИО',
    },
    {
      dataIndex: 'email',
      title: 'Email',
    },
    {
      dataIndex: 'position',
      title: 'Должность',
    },
    {
      dataIndex: 'phone',
      title: 'Номер телефона',
    },
    {
      dataIndex: 'created_at',
      title: 'Дата создания',
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      dataIndex: 'active',
      title: 'Статус',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      key: 'actions',
      align: 'right',
      render: (entity: ILKUser) => (
        <SpaceWithDivider>
          <LkUserDrawer user={entity} />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      bordered
      rowKey={'id'}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      title={tableTitle}
      footer={tableFooter}
    />
  )
}

export default LkUsersTable
