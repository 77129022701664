import React from 'react'
import { useBanner } from '@app/hooks/query/banners'
import { useParams } from 'react-router-dom'
import { Card, Col, Divider, Row, Skeleton, Typography } from 'antd'
import {
  BannerGroupLink,
  BannerPeriod,
  CategoryLink,
  EditLink,
  ErrorBoundary,
  PageInfo,
  PromoTag,
  SecondaryText,
  SiteLink,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'
import { BannerImage } from '@app/pages/banner-page/components'

const { Title } = Typography

interface props {}

const BannerPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useBanner(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const {
    category,
    banner_group,
    link_title,
    desktop,
    desktop_image,
    mobile,
    mobile_image,
    tablet,
    tablet_image,
    promotag,
    date_start,
    date_finish,
    active,
  } = data!

  return (
    <React.Fragment>
      <PageInfo title={link_title || ''} />
      <Card
        title={'Общая информация'}
        extra={[
          <SpaceWithDivider key={'1'}>
            {category && <SiteLink entityType={'category/' + category.slug} />}
            <EditLink id={id} route={'banners'} key={'1'} />
          </SpaceWithDivider>,
        ]}
      >
        <Title level={2}>{link_title ? link_title : 'Заголовок отсутствует'}</Title>
        <SpaceWithDivider>
          <SecondaryText>ID: {id}</SecondaryText>
          {category && <CategoryLink id={category.id} title={category.title} withTitle={true} />}
          <SecondaryText>
            Промотег:{' '}
            {promotag ? (
              <PromoTag title={promotag.title} color={promotag.color} id={promotag.id} />
            ) : (
              'Данные отсутствуют'
            )}
          </SecondaryText>
          <SecondaryText>
            Группа баннеров: <BannerGroupLink title={banner_group.title} id={banner_group.id} />
          </SecondaryText>
          <SecondaryText>
            Период активности: <BannerPeriod date_start={date_start} date_finish={date_finish} />
          </SecondaryText>
          <StatusTag status={active} />
        </SpaceWithDivider>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={8}>
            <BannerImage
              title={'Мобильное изображение'}
              imageSrc={mobile}
              imageDetails={mobile_image}
            />
          </Col>
          <Col xs={24} xl={8}>
            <BannerImage
              title={'Планшетное изображение'}
              imageSrc={tablet}
              imageDetails={tablet_image}
            />
          </Col>
          <Col xs={24} xl={8}>
            <BannerImage title={'ПК изображение'} imageSrc={desktop} imageDetails={desktop_image} />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}

export default BannerPage
