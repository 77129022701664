import { idType } from '@app/interfaces'
import { useQuery, UseQueryOptions } from 'react-query'
import { FilterAttributesService } from '@app/api'
import { AxiosResponse } from 'axios'
import { ICategoryFilterAttributesResponse } from '@app/interfaces/filter-attributes'

export const CATEGORY_FILTER_ATTRIBUTES_KEY = 'CATEGORY_FILTER_ATTRIBUTES_KEY'

export function useCategoryFilterAttributes(
  id: idType,
  options?: UseQueryOptions<ICategoryFilterAttributesResponse, Error>,
) {
  return useQuery<ICategoryFilterAttributesResponse, Error>(
    [CATEGORY_FILTER_ATTRIBUTES_KEY, id],
    () =>
      FilterAttributesService.getCategoryFilterAttributes(id).then(
        (response: AxiosResponse<ICategoryFilterAttributesResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
