import React, { useState } from 'react'
import {
  IProductAttribute,
  IProductProperty,
  IProductPropertyFormData,
} from '@app/interfaces/product'
import { Button, Col, Form, Input, message, Modal, Row, Typography } from 'antd'
import { useMutation } from 'react-query'
import { PropertiesService } from '@app/api'
import { useParams } from 'react-router-dom'
import { queryClient } from '@app/index'
import { PRODUCTS_KEY } from '@app/hooks/query/products'
import { FormOrderInput } from '@app/shared'

export interface ProductPropertiesFormProps {
  property?: IProductProperty
  attributes: IProductAttribute[]
}

const ProductPropertiesForm: React.FC<ProductPropertiesFormProps> = ({ property, attributes }) => {
  const { id } = useParams<{ id: string }>()
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)
  const [form] = Form.useForm<IProductPropertyFormData>()
  const formId = 'product-property-form'

  const onSuccess = () => {
    queryClient.invalidateQueries([PRODUCTS_KEY, id])
    form.resetFields()
    handleChangeVisible()
  }
  const onError = () => {
    message.error('Произошла ошибка! Попробуйте снова')
  }
  const create = useMutation(PropertiesService.create, { onSuccess, onError })
  const update = useMutation(PropertiesService.update, { onSuccess, onError })

  const onFinish = (data: IProductPropertyFormData) =>
    property ? update.mutate(data) : create.mutate(data)

  const handleSelectAttribute = (data: { title: string; value: string }) => {
    form.setFields([
      {
        name: 'title',
        value: data.title,
      },
      {
        name: 'value',
        value: data.value,
      },
    ])
  }

  const loading = create.isLoading || update.isLoading
  const title = property ? 'Изменить' : 'Добавить свойство'
  return (
    <React.Fragment>
      <Button type={'primary'} onClick={handleChangeVisible}>
        {title}
      </Button>
      <Modal
        width={850}
        visible={visible}
        destroyOnClose={true}
        onCancel={handleChangeVisible}
        title={title}
        confirmLoading={loading}
        okButtonProps={{ htmlType: 'submit', form: formId }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={12}>
            <Form
              form={form}
              id={formId}
              onFinish={onFinish}
              layout={'vertical'}
              initialValues={{ product_id: id, ...property }}
            >
              <Form.Item name={'id'} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={'product_id'} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={'value'} label={'Значение'} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <FormOrderInput required={false} name={'sort'} />
            </Form>
          </Col>
          <Col xs={12}>
            <Typography.Title level={5}>Выбрать из списка атрибутов товара</Typography.Title>
            <Row gutter={[12, 12]}>
              {attributes.map((attribute) => (
                <Col key={attribute.id}>
                  <Button
                    onClick={() =>
                      handleSelectAttribute({ title: attribute.option, value: attribute.value })
                    }
                  >
                    {attribute.option}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

export default ProductPropertiesForm
