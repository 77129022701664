import React from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  IStaticContentResponse,
  IValuesMissionPage,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const ValuesMissionPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'about_mission'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IValuesMissionPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IValuesMissionPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Ценности и миссия'}
        extra={[<SiteLink key={1} entityType={'o-kompanii/cennosti-i-missiya'} />]}
      />
      <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Слайдер</Typography.Title>
            <Form.List name={'slides'}>
              {(fields, { add, move, remove }) => (
                <React.Fragment>
                  {fields.map(({ name, key, fieldKey }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} preview />
                      </Form.Item>
                      <FormFileManagerSelect
                        formInstance={form}
                        pathFieldName={['slides', name, 'image']}
                        label={'Изображение'}
                        fieldKey={fieldKey}
                        initialPath={getStaticPageImagePath(data?.data?.slides[name]?.image)}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'quote']}
                        label={'Цитата'}
                        rules={[{ required: true }]}
                      >
                        <TextEditor />
                      </Form.Item>
                    </React.Fragment>
                  ))}

                  <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                    Добавить слайд
                  </Button>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Наши преимущества</Typography.Title>
            <Form.List name={'advantages'}>
              {(fields, { add, move, remove }) => (
                <React.Fragment>
                  {fields.map(({ name, fieldKey, key }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['advantages', name, 'image']}
                        label={'Изображение'}
                        initialPath={getStaticPageImagePath(data?.data?.advantages[name]?.image)}
                      />

                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'top_text']}
                        label={'Верхний текст'}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item fieldKey={fieldKey} name={[name, 'title']} label={'Заголовок'}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'bottom_text']}
                        label={'Нижний текст'}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))}
                  <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                    Добавить преимущество
                  </Button>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Корпоративные ценности</Typography.Title>
            <Form.List name={'values'}>
              {(fields, { add, move, remove }) => (
                <Row gutter={[24, 24]}>
                  {fields.map(({ name, fieldKey, key }) => (
                    <Col xs={24} xl={8} key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['values', name, 'image']}
                        label={'Изображение'}
                        initialPath={getStaticPageImagePath(data?.data?.values[name]?.image)}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'label']}
                        label={'Заголовок'}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.List name={[name, 'blocks']}>
                        {(fields1, { add, remove, move }) => (
                          <React.Fragment>
                            {fields1.map(({ name, fieldKey, key }) => (
                              <Row key={key} gutter={[12, 12]}>
                                <Col>
                                  <FormListMovingArrows move={move} name={name} />
                                </Col>
                                <Col flex={1}>
                                  <Form.Item fieldKey={fieldKey} name={name}>
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <MinusCircleOutlined onClick={() => remove(name)} />
                                </Col>
                              </Row>
                            ))}
                            <Button
                              type={'dashed'}
                              block
                              onClick={() => add()}
                              icon={<PlusCircleOutlined />}
                            >
                              Добавить блок
                            </Button>
                          </React.Fragment>
                        )}
                      </Form.List>
                    </Col>
                  ))}
                  <Col xs={24}>
                    <Button
                      type={'dashed'}
                      block
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Добавить ценность
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default ValuesMissionPage
