import React from 'react'
import { Divider, PageHeader, Typography } from 'antd'
import { GenerateSitemapButton, PageInfo, SpaceWithDivider } from '@app/shared'
import env from '@sanchik97/react-dotenv-public-url'
import { HomePageEditor } from '@app/pages/home-page/components'

interface props {}

const HomePage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <PageInfo title={'Главная'} />
      <PageHeader title={'Панель администратора!'} extra={[<HomePageEditor key={'1'} />]} />
      <Divider />
      <Typography.Title level={5}>Sitemap</Typography.Title>
      <SpaceWithDivider>
        <GenerateSitemapButton />
        <a
          className={'ant-btn'}
          href={env.SHOP_URL + '/sitemap.xml'}
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          Открыть sitemap
        </a>
      </SpaceWithDivider>
    </React.Fragment>
  )
}

export default HomePage
