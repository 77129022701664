import React from 'react'
import { IProductPrice } from '@app/interfaces/product'
import { Descriptions, Typography } from 'antd'

interface props {
  prices: IProductPrice[]
}

const ProductPrices: React.FC<props> = ({ prices }) => {
  return (
    <Descriptions column={1} bordered title={'Цены'} size={'small'}>
      {prices && prices.length ? (
        prices.map(({ price, old_price, id, price_type }) => (
          <Descriptions.Item label={price_type.title} key={id}>
            {price} руб.{' '}
            {old_price ? (
              <Typography.Text delete disabled>
                Старая цена: {old_price} руб.
              </Typography.Text>
            ) : null}
          </Descriptions.Item>
        ))
      ) : (
        <Typography.Text>Данные отсутствуют</Typography.Text>
      )}
    </Descriptions>
  )
}

export default ProductPrices
