import makeRequest from '@api/makeRequest'

const url = '/api/generate'

class GenerateService {
  sitemap() {
    return makeRequest({
      url: url + '/sitemap',
      method: 'post',
    })
  }
}

export default new GenerateService()
