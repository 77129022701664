import React from 'react'
import { AddLink, ErrorBoundary, ExportFile, ImportFromFile, PageInfo, SiteLink } from '@app/shared'
import { message, PageHeader, Row, Skeleton, Space, Tabs } from 'antd'
import { useArticles } from '@app/hooks/query/articles'
import { ArticlesPageEditor, ArticlesTable } from '@app/pages/articles-page/components'
import { useMutation } from 'react-query'
import { ArticlesService } from '@app/api'
import { RcFile } from 'antd/es/upload'

interface props {}

const ArticlesPage: React.FC<props> = () => {
  const { data: response, isLoading, isError, refetch } = useArticles()
  const importFromFile = useMutation(ArticlesService.import, {
    onSuccess: () => {
      refetch()
      message.success('Операция завершена успешно')
    },
    onError: () => {
      message.error('Ошибка импорта! Попробуйте снова')
    },
  })

  const handleImportFromFile = (file: RcFile) => {
    const formData = new FormData()
    formData.append('file', file)
    importFromFile.mutate(formData)
  }

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Статьи'} />
      <PageHeader title={'Статьи'} extra={[<AddLink key={'1'} route={'addArticle'} />]} />
      <Row justify={'end'}>
        <Space key={1}>
          <SiteLink entityType={'press-centr/blog'} />
          <ImportFromFile importFn={handleImportFromFile} isLoading={importFromFile.isLoading} />
          <ExportFile exportFn={ArticlesService.export} filename={'articles'} />
        </Space>
      </Row>
      <Tabs defaultActiveKey={'1'}>
        <Tabs.TabPane key={'1'} tab={'Таблица'}>
          <ArticlesTable articles={data} pagination={pagination} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'2'} tab={'Редактор страницы'}>
          <ArticlesPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default ArticlesPage
