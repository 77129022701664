import React from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Switch, Typography } from 'antd'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  IGuaranteeCertificatesPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const GuaranteeCertificatesPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'help_guarantee'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IGuaranteeCertificatesPage>>(pageType)
  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IGuaranteeCertificatesPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Гарантия и сертификаты'}
        extra={[<SiteLink key={1} entityType={'servis/garantiya-i-sertifikaty'} />]}
      />
      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Гарантии покупателю</Typography.Title>
            <Form.List name={'guarantees'}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map(({ name, key, fieldKey }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['guarantees', name, 'image']}
                        initialPath={getStaticPageImagePath(data?.data?.guarantees[name]?.image)}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Заголовок'}
                        name={[name, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Подзаголовок'}
                        name={[name, 'subtitle']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'title_on_top']}
                        valuePropName={'checked'}
                        initialValue={false}
                      >
                        <Switch
                          checkedChildren={'Заголовок сверху'}
                          unCheckedChildren={'Заголовок снизу'}
                        />
                      </Form.Item>
                    </React.Fragment>
                  ))}

                  <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                    Добавить блок
                  </Button>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Дополнительный текст</Typography.Title>
            <Form.Item name={'additional_text'} rules={[{ required: true }]}>
              <TextEditor />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Обслуживание и сервис</Typography.Title>
            <Form.Item name={'maintenance_service'} rules={[{ required: true }]}>
              <TextEditor />
            </Form.Item>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default GuaranteeCertificatesPage
