import React from 'react'
import {Result} from "antd"

interface props {

}

const ErrorBoundary: React.FC<props> = () => {
	return (
		<Result
			status="error"
			title="Ошибка!"
			subTitle="Простите, что-то пошло не так. В скором времени мы постараемся исправить данную проблему!"
		/>
	)
}

export default ErrorBoundary
