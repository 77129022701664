import React from 'react'
import { Image } from 'antd'
import fallback from '@assets/images/SM-placeholder-1024x512.jpg'
import styles from './image-with-fallback.module.scss'

interface props {
  src?: string
  width?: number | string
  height?: number | string
  preview?: boolean
  alt?: string
}

const ImageWithFallback: React.FC<props> = ({
  src = 'error',
  width,
  height,
  preview = true,
  alt,
}) => {
  const style = { marginBottom: 25, width, height }

  return (
    <div className={styles.wrapper} style={style}>
      <Image className={styles.image} src={src} preview={preview} fallback={fallback} alt={alt} />
    </div>
  )
}

export default ImageWithFallback
