import axios from 'axios'
import env from '@sanchik97/react-dotenv-public-url'
import { queryClient } from '@app/index'
import { IUser } from '@app/interfaces/user'
import { AUTH_KEY } from '@app/hooks/query/auth'

const url = env.SHOP_URL + '/api/robots'

class RobotsService {
  getRobots() {
    return axios({
      url,
      headers: {
        token: queryClient.getQueryData<IUser>(AUTH_KEY)?.id,
      },
    })
  }

  setRobots(content: string) {
    return axios({
      url,
      method: 'PUT',
      headers: {
        token: queryClient.getQueryData<IUser>(AUTH_KEY)?.id,
      },
      data: { content },
    })
  }
}

export default new RobotsService()
