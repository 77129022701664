import React from 'react'
import { Form, Select } from 'antd'
import { useAdvertisementTypes } from '@app/hooks/query/ads-types'

export interface FormSelectAdvTypeProps {}

const FormSelectAdsType: React.FC<FormSelectAdvTypeProps> = () => {
  const { data, isLoading } = useAdvertisementTypes()
  return (
    <Form.Item name={'type_id'} label={'Тип рекламного материала'}>
      <Select
        disabled={isLoading}
        loading={isLoading}
        placeholder={'Выберите тип рекламного материала'}
      >
        {data?.map((adsType) => (
          <Select.Option value={adsType.id.toString()}>{adsType.title}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSelectAdsType
