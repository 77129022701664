import React, { useState } from 'react'
import { idType } from '@app/interfaces'
import { Button, Drawer, Form, message, Space } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { FormFileManagerSelect } from '@app/shared'
import { useMutation } from 'react-query'
import { PromotagsService } from '@app/api'
import { queryClient } from '@app/index'

interface props {
  initialPath: string | undefined
  invalidateQueryKey: string | string[]
  id: idType
}

const AttachPromotagDrawer: React.FC<props> = ({ initialPath, invalidateQueryKey, id }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const handleVisibleChange = () => setVisible(!visible)
  const { isLoading, mutateAsync } = useMutation(PromotagsService.attachImage, {
    onSuccess: () => {
      message.success('Изображение успешно прикреплено')
      queryClient.invalidateQueries(invalidateQueryKey)
      handleVisibleChange()
    },
    onError: () => {
      message.error('Ошибка прикрепления изображения')
    },
  })

  const onFinish = (data: any) =>
    mutateAsync({
      id,
      data,
    })

  return (
    <React.Fragment>
      <Button type={'primary'} onClick={handleVisibleChange} icon={<FileImageOutlined />}>
        Прикрепить изображение
      </Button>
      <Drawer
        destroyOnClose={true}
        title={'Прикрепить изображение'}
        visible={visible}
        onClose={handleVisibleChange}
        width={450}
        footer={
          <Space>
            <Button onClick={form.submit} type={'primary'} disabled={isLoading} loading={isLoading}>
              Сохранить
            </Button>
            <Button type={'default'}>Отменить</Button>
          </Space>
        }
      >
        <Form form={form} onFinish={onFinish} layout={'vertical'}>
          <FormFileManagerSelect
            initialPath={initialPath}
            formInstance={form}
            pathFieldName={'path'}
            filenameFieldName={'filename'}
            label={'Изображение'}
            required
          />
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default AttachPromotagDrawer
