import React from 'react'
import { Card, Typography } from 'antd'

interface props {
  title: string
  description: string
  keywords: string
}

const SeoCard: React.FC<props> = ({ title, description, keywords }) => {
  return (
    <Card title={'SEO'}>
      <Typography.Title level={4}>Meta заголовок</Typography.Title>
      <p>{title}</p>
      <Typography.Title level={4}>Meta описание</Typography.Title>
      <p>{description}</p>
      <Typography.Title level={4}>Meta ключевые слова</Typography.Title>
      <p>{keywords}</p>
    </Card>
  )
}

export default SeoCard
