import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

interface props {}

const SecondaryText: React.FC<props> = ({ children }) => {
  return <Text type={'secondary'}>{children}</Text>
}

export default SecondaryText
