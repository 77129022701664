import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'

const url = '/api/redirects'

class RedirectsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: any) {
    return makeRequest({
      url,
      method: 'post',
      data,
    })
  }

  update(data: { redirect: any; id: idType }) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'patch',
      data: data.redirect,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }

  importRedirects(file: FormData) {
    return makeRequest({
      url: url + '/import',
      method: 'post',
      data: file,
    })
  }
}

export default new RedirectsService()
