import queryString from 'query-string'
import { history } from '@api/history'
import { queryKeys } from '@app/constants/query-keys'

export const serializeQuery = (query: string | object, value?: any) => {
  const {
    location: { search },
  } = history
  let qs = queryString.parse(search)
  delete qs['page']

  typeof query === 'string' ? (qs[query] = value) : (qs = Object.assign({}, qs, query))

  const newSearch = Object.entries(qs)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    .join('&')

  return `?${new URLSearchParams(newSearch)}`
}
