import React from 'react'
import {Card, Descriptions, Divider} from 'antd'
import { ImageWithFallback } from '@app/shared'
import styles from './banner-image.module.scss'
import {IImage} from "@app/interfaces"

interface props {
  title: string
  imageSrc: string
  imageDetails: IImage
}

const BannerImage: React.FC<props> = ({ title, imageSrc, imageDetails}) => {
  const {path, filename} = imageDetails

  return (
    <Card title={title} className={styles.card}>
      <ImageWithFallback src={imageSrc} preview={true} />
      <Divider/>
      <Descriptions title={'Информация об изображении'} column={1}>
        <Descriptions.Item label={<strong>Путь</strong>}>{path}</Descriptions.Item>
        <Descriptions.Item label={<strong>Название файла</strong>}>{filename}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default BannerImage
