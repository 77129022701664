import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { SelectionsService } from '@app/api'
import { useSelection } from '@app/hooks/query/selections'
import { message, PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo, ProductSelectionForm, SiteLink } from '@app/shared'
import { ISelection } from '@app/interfaces/selections'

interface props {}

const EditProductSelectionPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useSelection(id)
  const { isLoading: isUpdateLoading, mutateAsync } = useMutation(SelectionsService.update, {
    onSuccess: () => {
      message.success('Подборка успешно изменена')
      goBack()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const submitFunction = (selection: Partial<ISelection>) => mutateAsync(selection)

  return (
    <React.Fragment>
      <PageInfo title={'Изменить подборку товаров'} />
      <PageHeader
        title={'Изменить подборку товаров'}
        extra={<SiteLink entityType={'selection/' + data?.slug} />}
      />
      <ProductSelectionForm
        isLoading={isUpdateLoading}
        submitFunction={submitFunction}
        selection={data}
      />
    </React.Fragment>
  )
}

export default EditProductSelectionPage
