import { IProductColor } from '@app/interfaces/product'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { ColorsService } from '@app/api'

export const COLORS_KEY = 'colors_key'

export function useColors(options?: UseQueryOptions<IProductColor[], Error>) {
  return useQuery<IProductColor[], Error>(
    COLORS_KEY,
    () => ColorsService.list().then((response: AxiosResponse<IProductColor[]>) => response.data),
    {
      ...options,
    },
  )
}
