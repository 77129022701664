import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SMI_KEY, useSmi } from '@app/hooks/query/smi'
import { useMutation } from 'react-query'
import { SmiService } from '@app/api'
import { message, PageHeader, Skeleton } from 'antd'
import { privateRoutes } from '@app/routes'
import { AxiosError } from 'axios'
import { ISmi } from '@app/interfaces/smi'
import { ErrorBoundary, PageInfo, SmiForm } from '@app/shared'
import { queryClient } from '@app/index'

interface props {}

const EditSmiPage: React.FC<props> = () => {
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useSmi(id)

  const update = useMutation(SmiService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries([SMI_KEY, id])
      message.success('Запись успешно обновлена')
      push(privateRoutes.smi.path)
    },
    onError: (e: AxiosError) => {
      message.error(e.message)
    },
  })
  const handleSubmit = (smi: Partial<ISmi>) => update.mutateAsync(smi)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const smi = data!
  return (
    <React.Fragment>
      <PageInfo title={`Изменить ${smi.title}`} />
      <PageHeader title={`Изменить ${smi.title}`} />
      <SmiForm handleSubmit={handleSubmit} isLoading={update.isLoading} smi={smi} />
    </React.Fragment>
  )
}

export default EditSmiPage
