import React from 'react'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  IHtmoPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormListMovingArrows,
  FormSeoInputs,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'

interface props {}

const HtmoPage: React.FC<props> = () => {
  const pageType: StaticContentType = 'help_order'
  const { isLoading, isError, data } = useStaticContent<IStaticContentResponse<IHtmoPage>>(pageType)
  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IHtmoPage) => update.mutateAsync({ pageType, data })

  return (
    <>
      <PageHeader
        title={'Как сделать заказ'}
        extra={[<SiteLink key={1} entityType={'servis/kak-sdelat-zakaz'} />]}
      />
      <Form onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Как зарегистрироваться на сайте</Typography.Title>
            <Form.List name={'register_steps'}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map((field) => {
                    const { name, ...rest } = field

                    return (
                      <Row key={name} gutter={[12, 12]} align={'middle'}>
                        <Col>
                          <FormListMovingArrows move={move} name={name} />
                        </Col>
                        <Col flex={1}>
                          <Form.Item
                            {...rest}
                            label={`Шаг ${name + 1}`}
                            name={name}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    )
                  })}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} block>
                      Добавить шаг
                    </Button>
                  </Form.Item>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Как оформить заказ</Typography.Title>
            <Form.List name={'order_steps'}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map((field) => {
                    const { name, ...rest } = field

                    return (
                      <Row key={name} gutter={[12, 12]} align={'middle'}>
                        <Col xs={24}>
                          <Row justify={'space-between'} align={'middle'}>
                            <FormListMovingArrows move={move} name={name} />
                            <DeleteFormListBlock remove={remove} name={name} />
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            {...rest}
                            label={`Шаг ${name + 1}`}
                            name={[name, 'title']}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            {...rest}
                            label={`Описание ${name + 1} шага`}
                            name={[name, 'description']}
                          >
                            <TextEditor placeholder={'Введите короткое описание'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  })}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} block>
                      Добавить шаг
                    </Button>
                  </Form.Item>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Текстовая подсказка</Typography.Title>
            <Form.Item name={'prompt_text'}>
              <TextEditor placeholder={'Введите текстовую подсказку'} />
            </Form.Item>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default HtmoPage
