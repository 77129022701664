import React, { useContext } from 'react'
import { FileThumb } from '@app/shared/file-manager/components'
import { Divider, Empty, message, Typography, Upload } from 'antd'
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './files.module.scss'
import { useMutation } from 'react-query'
import { FileManagerService } from '@app/api'
import { RcFile } from 'antd/es/upload'
import { queryClient } from '@app/index'
import { FILE_MANAGER_KEY } from '@app/hooks/query/file-manager'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {}

const Files: React.FC<props> = () => {
  const { path, files, params } = useContext(FileManagerContext)
  const { isLoading, mutateAsync } = useMutation(FileManagerService.uploadFile, {
    onSuccess: () => {
      message.success('Файл успешно загружен')
      queryClient.invalidateQueries([FILE_MANAGER_KEY, { path, params }])
    },
    onError: () => {
      message.error('Ошибка загрузки файла')
    },
  })

  const handleUpload = async (file: RcFile) => {
    const formData = new FormData()

    formData.set('file', file)
    formData.set('dir', path)

    await mutateAsync(formData)
    return false
  }

  return (
    <div>
      <Upload.Dragger
        name={'file'}
        multiple={false}
        height={58}
        beforeUpload={handleUpload}
        disabled={isLoading}
        showUploadList={false}
      >
        <Typography.Text type={'secondary'}>
          {isLoading ? <LoadingOutlined /> : <InboxOutlined />} Нажмите, либо перетащите файл сюда
          для загрузки в текущую папку
        </Typography.Text>
      </Upload.Dragger>
      <Divider />
      {files?.length ? (
        <div className={styles.files}>
          {files.map((item) => (
            <FileThumb file={item} key={item} />
          ))}
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
}

export default React.memo(Files)
