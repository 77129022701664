import { SETS_QUERY_KEY, useSets } from '@app/hooks/query/sets'
import React from 'react'
import { PageHeader, Skeleton, Space, Table } from 'antd'
import { AddLink, DeleteModal, EditLink, ErrorBoundary, Pagination } from '@app/shared'
import { ISet } from '@app/interfaces/sets'
import { SetsService } from '@app/api'
import { Link } from 'react-router-dom'

export interface SetsPageProps {}

const SetsPage: React.FC<SetsPageProps> = () => {
  const { isLoading, isError, data: response } = useSets()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={SETS_QUERY_KEY} />
  return (
    <>
      <PageHeader title={'Оптовые подборки'} extra={<AddLink route={'add_set'} />} />
      <Table
        rowKey={'id'}
        pagination={false}
        footer={tableFooter}
        dataSource={data}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Заголовок',
            dataIndex: 'title',
            render: (title: string, record: ISet) => (
              <Link to={'/sets/details/' + record.id}>{title}</Link>
            ),
          },
          {
            title: 'Slug',
            dataIndex: 'slug',
          },
          {
            title: 'Действия',
            key: 'actions',
            align: 'right',
            render: (set: ISet) => (
              <Space>
                <EditLink route={'sets'} id={set.id} />
                <DeleteModal
                  id={set.id}
                  deleteFunction={SetsService.delete}
                  invalidateQueryKey={SETS_QUERY_KEY}
                />
              </Space>
            ),
          },
        ]}
      />
    </>
  )
}

export default SetsPage
