import React from 'react'
import { ArticleForm, PageInfo } from '@app/shared'
import { message, PageHeader } from 'antd'
import { useMutation } from 'react-query'
import { ArticlesService } from '@app/api'
import { IArticle } from '@app/interfaces/articles'
import { idType } from '@app/interfaces'
import { useHistory } from 'react-router-dom'

interface props {}

const AddArticlePage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { isLoading, mutateAsync } = useMutation(ArticlesService.create, {
    onSuccess: () => {
      message.success('Статья была успешно добавлена')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая статья уже существует')
    }
  })

  const submitFunction = (article: IArticle, id?: idType) => mutateAsync(article)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить статью'} />
      <PageHeader title={'Добавить статью'} />
      <ArticleForm isLoading={isLoading} submitFunction={submitFunction} />
    </React.Fragment>
  )
}

export default AddArticlePage
