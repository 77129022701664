import { AdsTypesService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IAdvertisementType } from '@app/interfaces/ads-types'

export const ADVERTISEMENT_TYPES_KEY = 'advertisement-types-key'

export function useAdvertisementTypes(options?: UseQueryOptions<IAdvertisementType[], Error>) {
  return useQuery<IAdvertisementType[], Error>(
    ADVERTISEMENT_TYPES_KEY,
    () =>
      AdsTypesService.getAll().then(
        (response: AxiosResponse<IAdvertisementType[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
