import React from 'react'
import { useMutation } from 'react-query'
import { AchievementsService } from '@app/api'
import { message, PageHeader, Skeleton } from 'antd'
import { queryClient } from '@app/index'
import { ACHIEVEMENTS_KEY, useAchievement } from '@app/hooks/query/achievements'
import { useHistory, useParams } from 'react-router-dom'
import { AchievementForm, ErrorBoundary, PageInfo } from '@app/shared'
import { IAchievement } from '@app/interfaces/achievements'
import { idType } from '@app/interfaces'

interface props {}

const EditAchievementPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useAchievement(id, {
    refetchOnMount: false,
  })
  const { isLoading: isEditLoading, mutateAsync } = useMutation(AchievementsService.update, {
    onSuccess: () => {
      message.success('Достижение успешно изменено')
      queryClient.invalidateQueries([ACHIEVEMENTS_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Что-то пошло не так')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const submitFunction = (achievement: IAchievement, id?: idType) =>
    mutateAsync({ achievement, id: id! })

  return (
    <React.Fragment>
      <PageInfo title={'Изменить достижение'} />
      <PageHeader title={'Изменить достижение'} />
      <AchievementForm
        submitFunction={submitFunction}
        isLoading={isEditLoading}
        achievement={data}
      />
    </React.Fragment>
  )
}

export default EditAchievementPage
