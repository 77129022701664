import React from 'react'
import { ISelection } from '@app/interfaces/selections'
import { IPagination } from '@app/interfaces'
import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
  CategoryLink,
  DeleteModal,
  EditLink,
  Pagination,
  SearchInput,
  SpaceWithDivider,
} from '@app/shared'
import { SELECTIONS_KEY } from '@app/hooks/query/selections'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { SelectionsService } from '@app/api'
import { ICategory } from '@app/interfaces/category'

interface props {
  data: ISelection[]
  pagination: IPagination
}

const ProductSelectionTable: React.FC<props> = ({ data, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={SELECTIONS_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={SELECTIONS_KEY} />

  const columns: ColumnsType<ISelection> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (text: string, record: ISelection) => (
        <Link to={privateRoutes.product_selection.path + '/details/' + record.id}>{text}</Link>
      ),
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (category: ICategory) =>
        category ? (
          <CategoryLink id={category.id} title={category.title} withTitle={false} />
        ) : (
          'Категория не указана'
        ),
    },
    {
      title: 'Ссылка',
      dataIndex: 'link',
      render: (link: string) => (
        <Typography.Link href={link} target={'_blank'} rel={'noopener noreferrer'} copyable={true}>
          {link}
        </Typography.Link>
      ),
    },
    {
      title: 'Действия',
      align: 'right',
      key: 'actions',
      render: (record: ISelection) => (
        <SpaceWithDivider>
          <EditLink id={record.id} route={'product_selection'} />
          <DeleteModal
            invalidateQueryKey={SELECTIONS_KEY}
            deleteFunction={SelectionsService.delete}
            id={record.id}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      title={tableTitle}
      footer={tableFooter}
      dataSource={data}
      rowKey={'id'}
      scroll={{ x: true }}
      columns={columns}
      pagination={false}
    />
  )
}

export default ProductSelectionTable
