import makeRequest from '@api/makeRequest'

const url = '/api/elastic'

class ElasticService {
  healthCheck() {
    return makeRequest({
      url: url + '/healthcheck',
      method: 'post',
    })
  }

  refreshCategories() {
    return makeRequest({
      url: url + '/refresh/categories',
      method: 'post',
    })
  }

  refreshProducts() {
    return makeRequest({
      url: url + '/refresh/products',
      method: 'post',
    })
  }
}

export default new ElasticService()
