import React from 'react'
import { IBrand } from '@app/interfaces/brands'
import { idType, IPagination } from '@app/interfaces'
import {
  BrandLink,
  DeleteModal,
  Pagination,
  SearchInput,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'
import { BRANDS_KEY } from '@app/hooks/query/brands'
import { ColumnsType } from 'antd/es/table'
import { Image, Table } from 'antd'
import { BrandsForm } from '@app/pages/brands-page/components'
import { BrandsService } from '@app/api'
import env from '@sanchik97/react-dotenv-public-url'

interface props {
  brands: IBrand[]
  pagination: IPagination
}

const BrandsTable: React.FC<props> = ({ brands, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={BRANDS_KEY} />
  const tableFooter = () => <Pagination fetchingQueryKey={BRANDS_KEY} pagination={pagination} />

  const columns: ColumnsType<IBrand> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Наименование',
      key: 'title',
      render: ({ id, title }: IBrand) => (
        <BrandLink brandId={id} brandName={title} withTitle={false} />
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'logo',
      render: (logo: string) => <Image src={logo} height={50} />,
    },
    {
      title: 'Файл',
      key: 'filename',
      render: (brand: IBrand) =>
        brand.path ? (
          <a target={'_blank'} href={env.SHOP_URL + brand.path + '/' + brand.filename}>
            Открыть файл
          </a>
        ) : (
          'Файл отсутствует'
        ),
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (brand: IBrand) => (
        <SpaceWithDivider>
          <BrandsForm brand={brand} />
          <DeleteModal
            id={brand.id}
            deleteFunction={BrandsService.delete}
            invalidateQueryKey={BRANDS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      scroll={{ x: true }}
      pagination={false}
      title={tableTitle}
      footer={tableFooter}
      rowKey={'id'}
      columns={columns}
      dataSource={brands}
    />
  )
}

export default BrandsTable
