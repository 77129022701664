import React from 'react'
import { Divider, Space, Table } from 'antd'
import { DeleteModal, EditLink, Pagination, SearchInput } from '@app/shared'
import { BANNER_GROUPS_KEY } from '@app/hooks/query/banner-groups'
import { idType, IPagination } from '@app/interfaces'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { BannerGroupsService } from '@app/api'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'

interface props {
  pagination: IPagination
  banner_groups: IBannerGroup[]
}

const BannerGroupsTable: React.FC<props> = ({ pagination, banner_groups }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={BANNER_GROUPS_KEY} />
  const tableFooter = () => (
    <Pagination pagination={pagination} fetchingQueryKey={BANNER_GROUPS_KEY} />
  )

  const columns: ColumnsType<IBannerGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (title: string, record: IBannerGroup) => (
        <Link to={privateRoutes.banners_groups.path + '/details/' + record.id}>{title}</Link>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text: string) => (
        <React.Fragment>{moment(text).format('DD-MM-YYYY')}</React.Fragment>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (record: IBannerGroup) => (
        <Space split={<Divider />}>
          <EditLink id={record.id} route={'banners_groups'} />
          <DeleteModal
            id={record.id}
            invalidateQueryKey={BANNER_GROUPS_KEY}
            deleteFunction={BannerGroupsService.delete}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table
      bordered
      columns={columns}
      dataSource={banner_groups}
      rowKey={'id'}
      scroll={{ x: true }}
      pagination={false}
      title={tableTitle}
      footer={tableFooter}
    />
  )
}

export default BannerGroupsTable
