import React from 'react'
import { DatePicker, DatePickerProps, Form } from 'antd'

interface props {
  name?: string
  label?: string
  required?: boolean
  format?: string
  allowClear?: boolean
  placeholder?: string
  datePickerProps?: DatePickerProps
}

const FormDatePicker: React.FC<props> = ({
  name = 'date',
  label = 'Дата создания',
  required = false,
  format = 'DD-MM-YYYY',
  allowClear = false,
  placeholder = 'Выберите дату',
  datePickerProps,
}) => {
  const width = { width: '100%' }

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <DatePicker
        format={format}
        style={width}
        allowClear={allowClear}
        placeholder={placeholder}
        {...datePickerProps}
      />
    </Form.Item>
  )
}

export default FormDatePicker
