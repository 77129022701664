import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import ru_Ru from 'antd/es/locale/ru_RU'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'moment/locale/ru'
import { history } from '@api/history'
import ScrollToTop from '@app/hoc/scroll-to-top'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <ConfigProvider locale={ru_Ru}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
