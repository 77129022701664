import React from 'react'
import { Button, Card, Col, Form, Input, message, Row } from 'antd'
import { ISet, ISetFormData } from '@app/interfaces/sets'
import { useMutation } from 'react-query'
import { SetsService } from '@app/api'
import {
  FormButtons,
  FormContentInput,
  FormFileManagerSelect,
  FormSeoInputs,
  ImageWithFallback,
} from '@app/shared'
import { useGoBack } from '@app/hooks/useGoBack'
import { IImage } from '@app/interfaces'

export interface SetFormProps {
  set?: ISet
}

const SetForm: React.FC<SetFormProps> = ({ set }) => {
  const [form] = Form.useForm<ISetFormData>()
  const { goBack } = useGoBack('sets')
  const onSuccess = () => {
    message.success('Данные успешно обновлены!')
    goBack()
  }
  const onError = () => {
    message.error('Произошла ошибка! Попробуйте снова, либо обратитесь в службу поддержки')
  }
  const create = useMutation(SetsService.create, { onSuccess, onError })
  const update = useMutation(SetsService.update, { onSuccess, onError })

  const handleRemoveBanner = () => {
    const submitFunction = set ? update.mutate : create.mutate

    submitFunction({
      ...form.getFieldsValue(),
      mobile_image: {} as IImage,
      tablet_image: {} as IImage,
      desktop_image: {} as IImage,
    })
  }

  const onFinish = (data: ISetFormData) => (set ? update.mutate(data) : create.mutate(data))

  return (
    <Form form={form} layout={'vertical'} initialValues={set} onFinish={onFinish}>
      <Form.Item name={'id'} hidden>
        <Input />
      </Form.Item>
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <FormSeoInputs isPageTitleRequired={false} />
        </Col>
        <Col xs={24} xl={12}>
          <Card title={'Общая информация'}>
            <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={'slug'} label={'Ссылка'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <FormContentInput />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card
            title={'Изображения'}
            extra={
              <Button onClick={handleRemoveBanner} danger>
                Удалить баннер
              </Button>
            }
          >
            <ImageWithFallback src={set?.mobile} />
            <FormFileManagerSelect
              initialPath={set?.mobile_image?.path}
              formInstance={form}
              label={'Мобильная версия'}
              filenameFieldName={['mobile_image', 'filename']}
              pathFieldName={['mobile_image', 'path']}
            />
            <ImageWithFallback src={set?.tablet} />
            <FormFileManagerSelect
              initialPath={set?.tablet_image?.path}
              formInstance={form}
              label={'Планшетная версия'}
              filenameFieldName={['tablet_image', 'filename']}
              pathFieldName={['tablet_image', 'path']}
            />
            <ImageWithFallback src={set?.desktop} />
            <FormFileManagerSelect
              initialPath={set?.desktop_image?.path}
              formInstance={form}
              label={'Версия для ПК'}
              filenameFieldName={['desktop_image', 'filename']}
              pathFieldName={['desktop_image', 'path']}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <FormButtons isLoading={create.isLoading || update.isLoading} />
        </Col>
      </Row>
    </Form>
  )
}

export default SetForm
