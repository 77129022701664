import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { UsersService } from '@app/api'
import { ILKUser, ILKUsersResponse } from '@app/interfaces/users'
import { idType } from '@app/interfaces'

export const LK_USERS_KEY = 'lk-users-key'

export function useUsers(options?: UseQueryOptions<ILKUsersResponse, Error>) {
  return useQuery<ILKUsersResponse, Error>(
    LK_USERS_KEY,
    () => UsersService.all().then((response: AxiosResponse<ILKUsersResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useUser(id: idType, options?: UseQueryOptions<ILKUser, Error>) {
  return useQuery<ILKUser, Error>(
    [LK_USERS_KEY, id],
    () => UsersService.byId(id).then((response: AxiosResponse<ILKUser>) => response.data),
    {
      ...options,
    },
  )
}
