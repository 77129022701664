import React from 'react'
import { Divider, Space } from 'antd'
import styles from './space-with-divider.module.scss'

interface props {}

const SpaceWithDivider: React.FC<props> = ({ children }) => {
  return (
    <Space split={<Divider type={'vertical'} />} align={'center'} className={styles.space}>
      {children}
    </Space>
  )
}

export default SpaceWithDivider
