import React from 'react'
import { PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, SetForm } from '@app/shared'
import { useParams } from 'react-router-dom'
import { useSet } from '@app/hooks/query/sets'

export interface EditSetPageProps {}

const EditSetPage: React.FC<EditSetPageProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useSet(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <>
      <PageHeader title={'Изменить подборку'} />
      <SetForm set={data} />
    </>
  )
}

export default EditSetPage
