import React from 'react'
import { idType } from '@app/interfaces'
import { privateRoutes } from '@app/routes'
import { Link } from 'react-router-dom'

interface props {
  title: string
  id: idType
}

const BannerGroupLink: React.FC<props> = ({ title, id }) => {
  return <Link to={privateRoutes.banners_groups.path + '/details/' + id}>{title}</Link>
}

export default BannerGroupLink
