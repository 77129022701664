import { AutoComplete, Form } from 'antd'
import React from 'react'
import { useSizes } from '@app/hooks/query/sizes'

interface props {
  name?: string | [number, string]
  label?: string
  required?: boolean
}

const FormSelectSize: React.FC<props> = ({ name = 'size_id', label = 'Размер', required }) => {
  const { isLoading, data } = useSizes()

  const style = { width: '100%' }
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <AutoComplete
        disabled={isLoading}
        style={style}
        options={data?.map((size) => ({ value: size.title }))}
        placeholder="Введите размер или выберите из списка ниже"
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  )
}

export default FormSelectSize
