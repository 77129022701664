import { useQuery, UseQueryOptions } from 'react-query'
import { IBrand, IBrandsResponse } from '@app/interfaces/brands'
import { BrandsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'

export const BRANDS_KEY = 'brands_key'

export function useBrands(options?: UseQueryOptions<IBrandsResponse, Error>) {
  return useQuery<IBrandsResponse, Error>(
    BRANDS_KEY,
    () => BrandsService.getAll().then((response: AxiosResponse<IBrandsResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useBrand(id: idType, options?: UseQueryOptions<IBrand, Error>) {
  return useQuery<IBrand, Error>(
    [BRANDS_KEY, id],
    () => BrandsService.getById(id).then((response: AxiosResponse<IBrand>) => response.data),
    {
      ...options,
    },
  )
}
