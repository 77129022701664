import React from 'react'
import { Button, Col, message, Popconfirm, Row, Spin, Tabs, Typography } from 'antd'
import {
  PromoTag,
  SelectionImportProducts,
  SelectionProductsList,
  SelectionSelectedProductsList,
  SpaceWithDivider,
} from '@app/shared'
import { CategoryBlockModal } from '@app/pages/category-page/components'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { ProductBlocksService } from '@app/api'
import { queryClient } from '@app/index'
import { CATEGORY_PRODUCTS_BLOCK, useCategoryProductsBlock } from '@app/hooks/query/product-blocks'
import { idType } from '@app/interfaces'
import { CATEGORIES_KEY } from '@app/hooks/query/categories'
import { useParams } from 'react-router-dom'

interface props {
  id: idType
}

const CategoryBlock: React.FC<props> = ({ id }) => {
  const { id: categoryId } = useParams<{ id: string }>()
  const { data, isLoading } = useCategoryProductsBlock(id)

  const deleteBlock = useMutation(ProductBlocksService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([CATEGORIES_KEY, categoryId])
    },
    onError: () => {
      message.error('Ошибка удаления блока')
    },
  })

  const onSuccess = () => {
    message.success('Подборка успешно обновлена')
    queryClient.invalidateQueries([CATEGORY_PRODUCTS_BLOCK, id])
  }
  const onError = () => {
    message.error('Ошибка обновления подорки! Обратитесь к системному администратору')
  }
  const importMutation = useMutation(ProductBlocksService.importProducts, {
    onSuccess,
    onError,
  })
  const attachMutation = useMutation(ProductBlocksService.attachProducts, {
    onSuccess,
    onError,
  })
  const detachMutation = useMutation(ProductBlocksService.dettachProducts, {
    onSuccess,
    onError,
  })

  if (isLoading) return <Spin />

  const onDelete = (id: idType) => deleteBlock.mutate(id)
  const handleDelete = () => onDelete(data!.id)

  const productsIds = data?.products.map((product) => product.id)
  return (
    <Row gutter={[32, 32]}>
      <Col xs={24}>
        <SpaceWithDivider>
          <Typography.Text style={{ fontSize: 24, fontWeight: 'bold' }}>
            {data?.title}
            <CategoryBlockModal block={data} />
          </Typography.Text>
          <Popconfirm title={'Уверены?'} onConfirm={handleDelete}>
            <Button type={'primary'} icon={<DeleteOutlined />} danger size={'small'} />
          </Popconfirm>
          {data?.promotag && (
            <PromoTag
              color={data?.promotag.color}
              id={data?.promotag.id}
              title={data?.promotag.title}
            />
          )}
        </SpaceWithDivider>
      </Col>
      <Col xs={24}>
        <Tabs
          tabBarExtraContent={{
            right: (
              <SelectionImportProducts
                importHandler={importMutation.mutateAsync}
                isLoading={importMutation.isLoading}
                id={id}
              />
            ),
          }}
        >
          <Tabs.TabPane tab={'Выбранные товары'} key={'selected'}>
            <SelectionSelectedProductsList
              detachProductsHandler={detachMutation.mutateAsync}
              isLoading={detachMutation.isLoading}
              selectedProducts={data?.products}
              id={id}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Добавить из списка товаров'} key={'products'}>
            <SelectionProductsList
              attachProductsHandler={attachMutation.mutateAsync}
              isLoading={attachMutation.isLoading}
              productIds={productsIds}
              id={id}
            />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  )
}

export default CategoryBlock
