import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { ISmi } from '@app/interfaces/smi'
import queryString from 'query-string'
import { history } from '@api/history'

const url = '/api/smi'

class SmiService {
  getList() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: Partial<ISmi>) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: Partial<ISmi>) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }
}

export default new SmiService()
