import { StaticPagesService } from '@app/api'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { ITemplatePageResponse } from '@app/interfaces/static-pages'

export const STATIC_PAGES_KEY = 'static-pages-key'

export function useStaticPages(options?: UseQueryOptions<ITemplatePageResponse, Error>) {
  return useQuery<ITemplatePageResponse, Error>(
    STATIC_PAGES_KEY,
    () =>
      StaticPagesService.getAll().then(
        (response: AxiosResponse<ITemplatePageResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
