import React from 'react'
import { usePromotags } from '@app/hooks/query/promotags'
import { PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo } from '@app/shared'
import {PromotagsTable} from "@app/pages/promotags-page/components"

interface props {}

const PromotagsPage: React.FC<props> = () => {
  const { isLoading, isError, data: response } = usePromotags()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const {data,  ...pagination} = response!
  return (
    <React.Fragment>
      <PageInfo title={'Промотеги'} />
      <PageHeader title={'Промотеги'} />
      <PromotagsTable promotags={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default PromotagsPage
