import React from 'react'
import { useMutation } from 'react-query'
import { BannerGroupsService } from '@app/api'
import { message, PageHeader, Skeleton } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import { BannersGroupForm, ErrorBoundary, PageInfo } from '@app/shared'
import {BANNER_GROUPS_KEY, useBannerGroup} from '@app/hooks/query/banner-groups'
import {idType} from "@app/interfaces"
import {queryClient} from "@app/index"

interface props {}

const EditBannersGroupPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { goBack } = useHistory()
  const { isLoading, isError, data } = useBannerGroup(id, {
    refetchOnMount: false
  })
  const { isLoading: isMutateLoading, mutateAsync } = useMutation(BannerGroupsService.update, {
    onSuccess: () => {
      message.success('Группа успешно изменена')
      queryClient.invalidateQueries([BANNER_GROUPS_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая группа уже существует')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const submitFunction = (group: IBannerGroup, id?: idType) => mutateAsync({ group, id: id! })

  return (
    <React.Fragment>
      <PageInfo title={'Изменить группу баннеров'} />
      <PageHeader title={'Изменить группу баннеров'} />
      <BannersGroupForm isLoading={isMutateLoading} group={data!} submitFunction={submitFunction} />
    </React.Fragment>
  )
}

export default EditBannersGroupPage
