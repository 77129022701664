import React from 'react'
import { Divider } from 'antd'

interface props {
  date_start: string
  date_finish: string
}

const BannerPeriod: React.FC<props> = ({ date_start, date_finish }) => {
  return (
    <React.Fragment>
      с {date_start} <Divider type={'vertical'} />
      по {date_finish}
    </React.Fragment>
  )
}

export default React.memo(BannerPeriod)
