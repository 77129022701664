import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { ISetFormData } from '@app/interfaces/sets'

const url = '/api/sets'

class SetsService {
  getAll() {
    return makeRequest({ url })
  }

  getById(id: idType) {
    return makeRequest({ url: url + '/' + id })
  }

  getList() {
    return makeRequest({ url: url + '/list' })
  }

  create(data: ISetFormData) {
    return makeRequest({ url, method: 'post', data })
  }

  update(data: ISetFormData) {
    return makeRequest({ url: url + '/' + data.id, method: 'patch', data })
  }

  delete(id: idType) {
    return makeRequest({ url: url + '/' + id, method: 'delete' })
  }

  import({ id, data }: { id: idType; data: FormData }) {
    return makeRequest({
      url: url + '/' + id + '/importProducts',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=something',
      },
      data,
    })
  }
}

export default new SetsService()
