import { IProductSize } from '@app/interfaces/product'
import { SizesService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'

export const SIZES_KEY = 'sizes_key'

export function useSizes(options?: UseQueryOptions<IProductSize[], Error>) {
  return useQuery<IProductSize[], Error>(
    SIZES_KEY,
    () => SizesService.list().then((response: AxiosResponse<IProductSize[]>) => response.data),
    {
      ...options,
    },
  )
}
