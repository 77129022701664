import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import queryString from 'query-string'
import { history } from '@api/history'
import { ILKManagerData } from '@app/interfaces/managers'

const url = '/api/managers'

class ManagersService {
  all() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  byId(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  updateImage(data: ILKManagerData) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }
}

export default new ManagersService()
