import React from 'react'
import { Image, Table } from 'antd'
import SearchInput from '@app/shared/search-input/search-input'
import {
  BannerGroupLink,
  CategoryLink,
  DeleteModal,
  EditLink,
  ImageWithFallback,
  Pagination,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'
import { ColumnsType } from 'antd/es/table'
import { idType, IPagination } from '@app/interfaces'
import { SLOTS_KEY } from '@app/hooks/query/slots'
import { IBanner } from '@app/interfaces/banners'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { ICroppedCategory } from '@app/interfaces/category'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import moment from 'moment'
import { BannersService } from '@app/api'

interface props {
  data: IBanner[]
  pagination: IPagination
}

const SlotsTable: React.FC<props> = ({ data, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={SLOTS_KEY} />
  const tableFooter = () => <Pagination fetchingQueryKey={SLOTS_KEY} pagination={pagination} />

  const columns: ColumnsType<IBanner> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: 'Заголовок',
      key: 'title',
      render: ({ link_title, id }: IBanner) => (
        <Link to={privateRoutes.banners.path + '/details/' + id}>
          {link_title ? link_title : 'Заголовок отсутствует'}
        </Link>
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'desktop',
      render: (link: string) => <ImageWithFallback src={link} height={75} />,
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (category: ICroppedCategory) =>
        category && <CategoryLink id={category.id} title={category.title} />,
    },
    {
      title: 'Группа баннеров',
      dataIndex: 'banner_group',
      render: ({ title, id }: IBannerGroup) => <BannerGroupLink title={title} id={id} />,
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text: string) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: ({ id }: IBanner) => (
        <SpaceWithDivider>
          <EditLink id={id} route={'banners'} />
          <DeleteModal
            id={id}
            invalidateQueryKey={SLOTS_KEY}
            deleteFunction={BannersService.delete}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      title={tableTitle}
      footer={tableFooter}
      bordered
      scroll={{ x: true }}
      pagination={false}
      rowKey={'id'}
      columns={columns}
      dataSource={data}
    />
  )
}

export default SlotsTable
