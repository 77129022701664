import React from 'react'
import { RcFile } from 'antd/es/upload'
import { AxiosResponse } from 'axios'
import { Button, Upload } from 'antd'
import { ImportOutlined } from '@ant-design/icons'

export interface ImportFromFileProps {
  importFn: (file: RcFile) => void
  isLoading?: boolean
}

const ImportFromFile: React.FC<ImportFromFileProps> = ({ importFn, isLoading }) => {
  const handleBeforeUpload = async (file: RcFile) => {
    await importFn(file)
    return false
  }
  return (
    <Upload
      beforeUpload={handleBeforeUpload}
      showUploadList={false}
      disabled={isLoading}
      multiple={false}
      accept={
        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      }
    >
      <Button type={'dashed'} icon={<ImportOutlined />}>
        Импорт из .xls
      </Button>
    </Upload>
  )
}

export default ImportFromFile
