import React from 'react'
import { IPromotion } from '@app/interfaces/promotions'
import { idType, IPagination } from '@app/interfaces'
import {
  DeleteModal,
  EditLink,
  ImageWithFallback,
  Pagination,
  PromoTag,
  SearchInput,
  SpaceWithDivider
} from '@app/shared'
import { PROMOTIONS_KEY } from '@app/hooks/query/promotions'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import { IPromoTag } from '@app/interfaces/promotags'
import {PromotionsService} from "@app/api"

interface props {
  promotions: IPromotion[]
  pagination: IPagination
}

const PromotionsTable: React.FC<props> = ({ promotions, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={PROMOTIONS_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={PROMOTIONS_KEY} />

  const columns: ColumnsType<IPromotion> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Баннер',
      dataIndex: 'image320x370',
      render: (image: string) => <ImageWithFallback src={image} width={75} />,
    },
    {
      title: 'Промотег',
      dataIndex: 'promotag',
      render: ({ color, id, title }: IPromoTag) => <PromoTag color={color} id={id} title={title} />,
    },
    {
      title: 'Период активности',
      key: 'date',
      render: ({ date_start, date_finish }: IPromotion) => (
        <span>
          {new Date(date_start).toLocaleDateString()} - {new Date(date_finish).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: ({id}:IPromotion) => (
        <SpaceWithDivider>
          <EditLink id={id} route={'promotions'} />
          <DeleteModal id={id} invalidateQueryKey={PROMOTIONS_KEY} deleteFunction={PromotionsService.delete} />
        </SpaceWithDivider>
      )
    }
  ]

  return (
    <Table
      title={tableTitle}
      footer={tableFooter}
      columns={columns}
      dataSource={promotions}
      scroll={{ x: true }}
      rowKey={'id'}
      pagination={false}
      bordered
    />
  )
}

export default PromotionsTable
