import { useQuery, UseQueryOptions } from 'react-query'
import { CategoriesService } from '@app/api'
import { AxiosResponse } from 'axios'
import {
  ICategory,
  ICategoryFilter,
  ICategoryResponse,
  ICategoryTextField,
} from '@app/interfaces/category'
import { idType } from '@app/interfaces'

export const CATEGORIES_KEY = 'categories_key'
export const CATEGORIES_FILTERS = 'categories_filters'
export const CATEGORY_TEXT_FIELD = 'category_text_field'

export function useCategories(options?: UseQueryOptions<ICategoryResponse, Error>) {
  return useQuery<ICategoryResponse, Error>(
    CATEGORIES_KEY,
    () =>
      CategoriesService.getAll().then(
        (response: AxiosResponse<ICategoryResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useCategory(id: idType, options?: UseQueryOptions<ICategory, Error>) {
  return useQuery<ICategory, Error>(
    [CATEGORIES_KEY, id],
    () => CategoriesService.getById(id).then((response: AxiosResponse<ICategory>) => response.data),
    {
      ...options,
    },
  )
}

export function useCategoriesFilters(options?: UseQueryOptions<ICategoryFilter[], Error>) {
  return useQuery<ICategoryFilter[], Error>(
    CATEGORIES_FILTERS,
    () =>
      CategoriesService.getFilterCategories().then(
        (response: AxiosResponse<ICategoryFilter[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
