import React from 'react'
import { useLkManagers } from '@app/hooks/query/managers'
import { PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { LkManagersTable } from '@app/pages/lk-managers-page/components'

export interface LkManagersPageProps {}

const LkManagersPage: React.FC<LkManagersPageProps> = () => {
  const { isLoading, data: response, isError } = useLkManagers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!
  return (
    <React.Fragment>
      <PageInfo title={'Менеджеры ЛК'} />
      <PageHeader title={'Менеджеры ЛК'} />
      <LkManagersTable data={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default LkManagersPage
