import { useQuery, UseQueryOptions } from 'react-query'
import { AchievementsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import {AchievementsResponse, IAchievement} from "@app/interfaces/achievements"

export const ACHIEVEMENTS_KEY = 'achievements_key'

export function useAchievements(options?: UseQueryOptions<AchievementsResponse, Error>) {
  return useQuery<AchievementsResponse, Error>(
    ACHIEVEMENTS_KEY,
    () => AchievementsService.getAll().then((response: AxiosResponse<AchievementsResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useAchievement(id: idType, options?: UseQueryOptions<IAchievement, Error>) {
  return useQuery<IAchievement, Error>(
    [ACHIEVEMENTS_KEY, id],
    () => AchievementsService.getById(id).then((response: AxiosResponse<IAchievement>) => response.data),
    {
      ...options,
    },
  )
}
