import React from 'react'
import { INews } from '@app/interfaces/news'
import { Image, Table } from 'antd'
import { Link } from 'react-router-dom'
import { DeleteModal, EditLink, Pagination, SearchInput, SpaceWithDivider } from '@app/shared'
import { NewsService } from '@app/api'
import { NEWS_KEY } from '@app/hooks/query/news'
import { privateRoutes } from '@app/routes'
import { idType, IPagination } from '@app/interfaces'
import { ColumnsType } from 'antd/es/table'

interface props {
  news: INews[]
  pagination: IPagination
}

const NewsTable: React.FC<props> = ({ news, pagination }) => {
  const renderHeader = () => {
    return <SearchInput fetchingQueryKey={NEWS_KEY} />
  }

  const renderFooter = () => {
    return <Pagination pagination={pagination} fetchingQueryKey={NEWS_KEY} />
  }

  const columns: ColumnsType<INews> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (title: string, record: INews) => (
        <Link to={privateRoutes.news.path + '/details/' + record.id}>{title}</Link>
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'image_path',
      render: (image_path: string) => <Image width={50} height={50} src={image_path} alt={''} />,
    },
    {
      title: 'Дата публикации',
      dataIndex: 'date',
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (record: INews) => (
        <SpaceWithDivider>
          <EditLink route={'news'} id={record.id} />
          <DeleteModal
            deleteFunction={NewsService.delete}
            id={record.id}
            invalidateQueryKey={NEWS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      title={renderHeader}
      footer={renderFooter}
      rowKey={'id'}
      bordered
      columns={columns}
      dataSource={news}
      pagination={false}
    />
  )
}

export default NewsTable
