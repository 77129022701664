import React from 'react'
import { useProducts } from '@app/hooks/query/products'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { ProductsTable } from '@app/pages/products-page/components'

interface props {}

const ProductsPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useProducts()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Товары'} />
      <PageHeader title={'Товары'} />
      <ProductsTable products={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default ProductsPage
