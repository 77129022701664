import React from 'react'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { useForms } from '@app/hooks/query/forms'
import { FormsTable } from './components'

interface props {}

const FormsPage: React.FC<props> = () => {
  const { isLoading, isError, data } = useForms()
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <React.Fragment>
      <PageInfo title={'Формы'} />
      <PageHeader title={'Формы'} />
      <FormsTable data={data!} />
    </React.Fragment>
  )
}

export default FormsPage
