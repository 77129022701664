import makeRequest from '@api/makeRequest'

const url = '/api/export'

class ExportService {
  subscribers(params: { from: string; to: string }) {
    return makeRequest({
      url: url + '/subscribers',
      responseType: 'blob',
      params,
    })
  }
}

export default new ExportService()
