import React from 'react'
import { Form, Select } from 'antd'
import { FormListFieldData } from 'antd/es/form/FormList'
import { IAttribute, IFilterType } from '@app/interfaces/filter-attributes'

interface props {
  restField: Pick<FormListFieldData, never>
  name: number
  fieldKey: number
  edit: boolean
  options: IAttribute[] | IFilterType[]
  fieldName: string
  placeholder: string
}

const FilterSelect: React.FC<props> = ({
  restField,
  name,
  fieldKey,
  fieldName,
  edit,
  options,
  placeholder,
}) => {

  return (
    <Form.Item
      {...restField}
      name={[name, fieldName]}
      fieldKey={[fieldKey, fieldName]}
      rules={[{ required: true, message: 'Заполните поле' }]}
    >
      <Select
        style={{ width: 240 }}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        disabled={!edit}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterSelect
