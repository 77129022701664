import { idType } from '@app/interfaces'
import makeRequest from '@api/makeRequest'
import { IFilterAttribute } from '@app/interfaces/filter-attributes'

const url = '/api/filter-attributes'

class FilterAttributesService {
  getCategoryFilterAttributes(id: idType) {
    return makeRequest({
      url: url + '/category/' + id,
    })
  }

  setCategoryFilterAttributes(data: { id: idType; filter_attributes: IFilterAttribute[] }) {
    return makeRequest({
      url: url + '/category/' + data.id + '/upsert',
      method: 'post',
      data: {
        filter_attributes: data.filter_attributes,
      },
    })
  }

  removeCategoryFilterAttribute(categoryId: idType, attributeId: idType) {
    return makeRequest({
      url: url + '/delete/' + categoryId + '/' + attributeId,
      method: 'delete',
    })
  }

  removeCategoryChildrenFilterAttribute(categoryId: idType, attributeId: idType) {
    return makeRequest({
      url: url + '/delete-children/' + categoryId + '/' + attributeId,
      method: 'delete',
    })
  }
}

export default new FilterAttributesService()
