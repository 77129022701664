import { Button, Divider, Modal, Result, Spin } from 'antd'
import React, { useCallback, useState } from 'react'
import { FileSearchOutlined } from '@ant-design/icons'
import styles from './file-manager.module.scss'
import { useFileManager } from '@app/hooks/query/file-manager'
import { Files, Footer, Navigation } from './components'
import { IFIleManagerQueryParams } from '@app/interfaces/file-manager'
import {
  FileManagerContext,
  IFileManagerContent,
} from '@app/shared/file-manager/file-manager.context'

interface props {
  initialPath?: string
  onSelect?: (path: string, fileName: string) => void
  onReset?: () => void
}

const FileManager: React.FC<props> = ({ initialPath = '', onReset, onSelect }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [path, setPath] = useState<string>(initialPath)
  const [pathFilter, setPathFilter] = useState('')
  const [selectedFile, setSelectedFile] = useState<string | undefined>()
  const [params, setParams] = useState<IFIleManagerQueryParams>({
    sortBy: 'createdAt',
    sortOrder: 'desc',
  })
  const { isLoading, isFetching, data, isError } = useFileManager(
    { path, params },
    {
      enabled: open,
    },
  )

  const handleChangeParams = useCallback((params: IFIleManagerQueryParams) => {
    setParams(params)
  }, [])
  const handleChangeFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setPathFilter(e.target.value),
    [],
  )
  const handleShowPrevPath = useCallback(() => {
    setSelectedFile(undefined)
    setPathFilter('')
    setPath((prevState) => prevState.replace(/\/[^/]*$/, ''))
  }, [])

  const handleShowNextPath = useCallback((path: string) => {
    setSelectedFile(undefined)
    setPathFilter('')
    setPath((prevState) => prevState + '/' + path)
  }, [])

  const handleSelectFile = useCallback((fileName: string) => setSelectedFile(fileName), [])

  const onSelectFile = useCallback(() => {
    onSelect && onSelect(path, selectedFile!)
    setOpen(false)
  }, [selectedFile, path])

  const handleResetPathToRoot = () => setPath('/')
  const handleOpen = () => setOpen(true)
  const handleCancel = useCallback(() => {
    setSelectedFile(undefined)
    setPath(initialPath)
    setPathFilter('')
    setOpen(false)
    onReset && onReset()
  }, [])

  const contextValue: IFileManagerContent = React.useMemo(
    () => ({
      files: data?.files,
      path,
      pathFilter,
      isFetching,
      directories: data?.directories,
      selectedFile,
      params,
      handleCancel,
      handleChangeFilter,
      handleShowNextPath,
      handleShowPrevPath,
      handleSelectFile,
      handleChangeParams,
      onSelectFile,
    }),
    [
      data,
      pathFilter,
      path,
      isFetching,
      selectedFile,
      params,
      handleCancel,
      handleChangeFilter,
      handleShowNextPath,
      handleShowPrevPath,
      handleSelectFile,
      handleChangeParams,
      onSelectFile,
    ],
  )

  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        icon={<FileSearchOutlined />}
        block
        type={'dashed'}
        disabled={isLoading}
        loading={isLoading}
      >
        {selectedFile ? `Выбран файл: ${selectedFile}` : 'Открыть файловый менеджер'}
      </Button>
      <FileManagerContext.Provider value={contextValue}>
        <Modal
          visible={open}
          onCancel={handleCancel}
          width={1150}
          title={'Выберите файл'}
          centered={true}
          bodyStyle={{
            height: '70vh',
          }}
          footer={<Footer />}
        >
          <div className={styles.body}>
            <div className={styles.row}>
              <Navigation />
              <div className={styles.divider}>
                <Divider type={'vertical'} style={{ height: '100%' }} />
              </div>
              {isLoading ? (
                <Spin tip={'Загрузка директории. Пожалуйста, подождите!'} size={'large'} />
              ) : isError ? (
                <Result
                  status={'error'}
                  title={'Ошибка загрузки файлов директории'}
                  subTitle={'Указанная директория не была найдена'}
                  extra={
                    <Button type={'default'} onClick={handleResetPathToRoot}>
                      Открыть корневую директорию
                    </Button>
                  }
                />
              ) : (
                <Files />
              )}
            </div>
          </div>
        </Modal>
      </FileManagerContext.Provider>
    </React.Fragment>
  )
}

export default FileManager
