import React, { useState } from 'react'
import { Drawer } from 'antd'
import { IRedirect } from '@app/interfaces/redirects'
import { DrawerButton, RedirectForm } from '@app/shared'

interface props {
  redirect?: IRedirect
}

const RedirectsDrawer: React.FC<props> = ({ redirect }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerHandler = () => setVisible(!visible)

  return (
    <React.Fragment>
      <DrawerButton isEditing={!!redirect} clickHandler={drawerHandler} />
      <Drawer
        title={redirect ? 'Изменить редирект' : 'Добавить редирект'}
        width={450}
        onClose={drawerHandler}
        visible={visible}
      >
        <RedirectForm drawerHandler={drawerHandler} redirect={redirect} />
      </Drawer>
    </React.Fragment>
  )
}

export default RedirectsDrawer
