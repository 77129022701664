import React from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { PlusOutlined } from '@ant-design/icons'
import {
  IHelpPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const HelpPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'help_index'
  const { isLoading, isError, data } = useStaticContent<IStaticContentResponse<IHelpPage>>(pageType)
  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IHelpPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader title={'Помощь'} extra={[<SiteLink key={1} entityType={'servis'} />]} />

      <Form form={form} onFinish={onFinish} initialValues={data?.data} layout={'vertical'}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Form.Item name={'prompt_text'} label={'Текстовая подсказка'}>
          <TextEditor placeholder={'Введите текстовую подсказку'} />
        </Form.Item>
        <Form.Item label={'Категории вопроса'}>
          <Form.List name={'blocks'}>
            {(fields, { add, remove, move }) => (
              <React.Fragment>
                <Row gutter={[24, 24]}>
                  {fields.map((field) => {
                    const { name, ...rest } = field

                    return (
                      <Col key={name} xs={24} lg={12}>
                        <Row gutter={[12, 12]} align={'middle'}>
                          <Col xs={24}>
                            <Row align={'middle'} justify={'space-between'}>
                              <FormListMovingArrows move={move} name={name} />
                              <DeleteFormListBlock remove={remove} name={name} />
                            </Row>
                          </Col>
                          <Col xs={24}>
                            <Form.Item name={[name, 'image_url']} valuePropName={'src'}>
                              <ImageWithFallback height={50} />
                            </Form.Item>
                            <FormFileManagerSelect
                              fieldKey={rest.fieldKey}
                              formInstance={form}
                              pathFieldName={['blocks', name, 'image_url']}
                              label={'Изображение'}
                              initialPath={getStaticPageImagePath(
                                data?.data?.blocks[name]?.image_url,
                              )}
                            />
                          </Col>
                          <Col flex={1}>
                            <Form.Item
                              {...rest}
                              label="Заголовок"
                              name={[name, 'title']}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col flex={1}>
                            <Form.Item
                              {...rest}
                              label="Адрес ссылки"
                              name={[name, 'url']}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...rest}
                              label={'Подзаголовок'}
                              name={[name, 'subtitle']}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    )
                  })}

                  <Col xs={24}>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                        Добавить ссылку
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Form.List>
        </Form.Item>
        <FeedbackFormRadio />
        <FormButtons isLoading={update.isLoading} />
      </Form>
    </React.Fragment>
  )
}

export default HelpPage
