import React from 'react'
import { Form, Select, SelectProps } from 'antd'
import { SelectValue } from 'antd/es/select'
import { usePartnersList } from '@app/hooks/query/partners'

const { Option } = Select

interface props {
  label?: string
  name?: string | any[]
  required?: boolean
  selectProps?: SelectProps<SelectValue>
}

const FormSelectPartners: React.FC<props> = ({
  label = 'Партнер',
  name = 'partner_id',
  required,
  selectProps,
}) => {
  const { isLoading, data } = usePartnersList({
    refetchOnMount: false,
  })

  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <Select
        showSearch={true}
        loading={isLoading}
        disabled={isLoading}
        allowClear={!required}
        placeholder={'Выберите партнера'}
        filterOption={(input, option) =>
          ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
        }
        {...selectProps}
      >
        {data?.map(({ id, title }) => (
          <Option key={id} value={id} label={title}>
            {title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSelectPartners
