import { PartnersService } from '@app/api'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { IPartner } from '@app/interfaces/partner'

export const PARTNERS_QUERY_KEY = 'partners-query-key'

export function usePartnersList(options?: UseQueryOptions<IPartner[], Error>) {
  return useQuery<IPartner[], Error>(
    [PARTNERS_QUERY_KEY],
    () => PartnersService.list().then((response: AxiosResponse<IPartner[]>) => response.data),
    {
      ...options,
    },
  )
}
