import React, { useState } from 'react'
import { queryClient } from '@app/index'
import { Button, Drawer, Form, Input, message, Space } from 'antd'
import { useMutation } from 'react-query'
import { ColorsService } from '@app/api'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { COLORS_KEY } from '@app/hooks/query/colors'
import { idType } from '@app/interfaces'
import { IProductColor } from '@app/interfaces/product'

interface props {
  color?: IProductColor
}

type Fields = {
  id: idType
  title: string
}
const ColorDrawer: React.FC<props> = ({ color }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleChangeVisible = () => setVisible(!visible)

  const onSuccess = async () => {
    await queryClient.invalidateQueries(COLORS_KEY)
    message.success('Изменения прошли успешно')
    handleChangeVisible()
  }
  const onError = () => {
    message.success('Произошла ошибка!')
  }

  const create = useMutation(ColorsService.create, { onSuccess, onError })
  const update = useMutation(ColorsService.update, { onSuccess, onError })

  const onFinish = (data: Fields) =>
    color ? update.mutateAsync(data) : create.mutateAsync(data.title)

  const isEditColor = !!color

  const title = isEditColor ? 'Изменить' : 'Добавить'
  return (
    <React.Fragment>
      <Button
        type={'primary'}
        icon={isEditColor ? <EditOutlined /> : <PlusCircleOutlined />}
        onClick={handleChangeVisible}
      >
        {title}
      </Button>
      <Drawer
        destroyOnClose={true}
        closable={true}
        footer={
          <Space>
            <Button
              type={'primary'}
              form={'colors-form'}
              htmlType={'submit'}
              disabled={create.isLoading || update.isLoading}
              loading={create.isLoading || update.isLoading}
            >
              Сохранить
            </Button>
            <Button type={'default'} onClick={handleChangeVisible}>
              Отменить
            </Button>
          </Space>
        }
        visible={visible}
        onClose={handleChangeVisible}
        width={350}
        title={title}
      >
        <Form id={'colors-form'} onFinish={onFinish} layout={'vertical'} initialValues={color}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
            <Input placeholder={'Введите заголовок'} />
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default ColorDrawer
