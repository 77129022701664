import React from 'react'
import { PageHeader, Skeleton, Table, TableColumnType } from 'antd'
import { TemplatePageActionDrawer } from './components'
import { DeleteModal, ErrorBoundary, Pagination, SiteLink, SpaceWithDivider } from '@app/shared'
import { STATIC_PAGES_KEY, useStaticPages } from '@app/hooks/query/static-pages'
import { StaticPagesService } from '@app/api'
import { ITemplatePage } from '@app/interfaces/static-pages'

export interface TemplatePagesProps {}

const TemplatePages: React.FC<TemplatePagesProps> = () => {
  const { data, isLoading, isError } = useStaticPages()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const columns: TableColumnType<any>[] = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'title',
      title: 'Название страницы',
    },
    {
      dataIndex: 'created_at',
      title: 'Дата создания',
    },
    {
      dataIndex: 'slug',
      title: 'Ссылка',
      render: (slug: string) => <SiteLink slug={slug} />,
    },
    {
      key: 'actions',
      title: 'Действия',
      align: 'right',
      render: (entity: ITemplatePage) => (
        <SpaceWithDivider>
          <TemplatePageActionDrawer page={entity} />
          <DeleteModal
            id={entity.id}
            deleteFunction={StaticPagesService.delete}
            invalidateQueryKey={STATIC_PAGES_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  const { data: pages, ...pagination } = data!

  return (
    <React.Fragment>
      <PageHeader title={'Шаблонные страницы'} extra={[<TemplatePageActionDrawer key={'1'} />]} />
      <Table
        rowKey={'id'}
        scroll={{ x: true }}
        columns={columns}
        pagination={false}
        dataSource={pages}
        bordered
        footer={() => <Pagination pagination={pagination} fetchingQueryKey={STATIC_PAGES_KEY} />}
      />
    </React.Fragment>
  )
}

export default TemplatePages
