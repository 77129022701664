import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd'
import React, { ChangeEvent, useState } from 'react'
import { ARTICLE_TAGS_LIST, useArticleTagsList } from '@app/hooks/query/articleTags'
import { ArticleTagsService } from '@app/api'
import { queryClient } from '@app/index'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

interface props {
  name?: string
}

const FormSelectTags: React.FC<props> = ({ name = 'tags' }) => {
  const [value, setValue] = useState<string>('')
  const handleValueChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setValue(value)

  const { isLoading, data } = useArticleTagsList()

  const createTag = useMutation(ArticleTagsService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLE_TAGS_LIST)
      setValue('')
    },
    onError: (e: AxiosError) => {
      message.error(e.message)
    },
  })

  const onClick = () => createTag.mutateAsync(value)

  const style = { padding: '5px 12px' }
  return (
    <Form.Item name={name} label={'Тэги'}>
      <Select
        mode={'multiple'}
        disabled={isLoading}
        loading={isLoading}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        allowClear={true}
        placeholder={'Выберите тэги для статьи'}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <Row gutter={[0, 8]} style={style}>
              <Col xs={24}>
                <Input
                  disabled={isLoading}
                  value={value}
                  onChange={handleValueChange}
                  placeholder={'Введите название тэга'}
                />
              </Col>
              <Col xs={24}>
                <Button
                  type={'primary'}
                  onClick={onClick}
                  block
                  loading={createTag.isLoading}
                  disabled={createTag.isLoading || !value.length}
                >
                  Создать новый тэг
                </Button>
              </Col>
            </Row>
          </div>
        )}
      >
        {data?.map((tag) => (
          <Select.Option key={tag.id} value={tag.id}>
            {tag.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSelectTags
