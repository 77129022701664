import React, { useState } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { transliterate } from '@app/utils/translit'
import { EditOutlined } from '@ant-design/icons'
import { idType } from '@app/interfaces'

interface props {
  form: FormInstance
  id: idType | undefined
}

const FormTitleWithSlugInputs: React.FC<props> = ({ form, id }) => {
  const [isSlugDisabled, setIsSlugDisabled] = useState<boolean>(!id)
  const changeSlugDisabledStatus = () => setIsSlugDisabled(!isSlugDisabled)

  const setNewsUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSlugDisabled) {
      form.setFieldsValue({
        slug: transliterate(event.target.value),
      })
    }
  }

  return (
    <React.Fragment>
      <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
        <Input onChange={setNewsUrl} placeholder={'Заголовок'} />
      </Form.Item>
      <Form.Item name={'slug'} label={'URL адрес'} rules={[{ required: true }]}>
        <Input
          placeholder={'URL адрес'}
          disabled={isSlugDisabled}
          addonBefore={<EditOutlined onClick={changeSlugDisabledStatus} />}
        />
      </Form.Item>
    </React.Fragment>
  )
}

export default FormTitleWithSlugInputs
