import React from 'react'
import { message, PageHeader } from 'antd'
import { PageInfo, ProductSelectionForm, SiteLink } from '@app/shared'
import { ISelection } from '@app/interfaces/selections'
import { useMutation } from 'react-query'
import { SelectionsService } from '@app/api'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { AxiosResponse } from 'axios'

interface props {}

const AddProductSelectionPage: React.FC<props> = () => {
  const { push } = useHistory()
  const { isLoading, mutateAsync } = useMutation(SelectionsService.create, {
    onSuccess: (response: AxiosResponse<ISelection>) => {
      message.success('Подборка успешно добавлена')
      push(privateRoutes.product_selection.path + '/details/' + response.data.id)
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const submitFunction = (selection: Partial<ISelection>) => mutateAsync(selection)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить подборку товаров'} />
      <PageHeader title={'Добавить подборку товаров'} />
      <ProductSelectionForm isLoading={isLoading} submitFunction={submitFunction} />
    </React.Fragment>
  )
}

export default AddProductSelectionPage
