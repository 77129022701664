import React from 'react'
import { idType } from '@app/interfaces'
import { useParams } from 'react-router-dom'
import { CATEGORY_VIDEO_BLOCK, useCategoryVideoBlock } from '@app/hooks/query/video-block'
import { Button, Col, message, Popconfirm, Row, Space, Spin, Table, Tabs, Typography } from 'antd'
import { SelectionImportProducts, SelectionProductsList, SpaceWithDivider } from '@app/shared'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { VideoBlockService, VideosService } from '@app/api'
import { queryClient } from '@app/index'
import { CATEGORIES_KEY } from '@app/hooks/query/categories'
import { AddVideoModal } from '@app/pages/category-page/components'
import { IVideo } from '@app/interfaces/video'
import { IProduct } from '@app/interfaces/product'

interface props {
  id: idType
}

const CategoryVideoBlock: React.FC<props> = ({ id }) => {
  const { id: categoryId } = useParams<{ id: string }>()
  const { isLoading, data } = useCategoryVideoBlock(id)
  const deleteVideo = useMutation(VideoBlockService.delete, {
    onSuccess: () => {
      message.success('Блок был успешно удален')
      queryClient.invalidateQueries([CATEGORIES_KEY, categoryId])
    },
    onError: () => {
      message.error('Ошибка удаления блока')
    },
  })

  const onSuccess = () => {
    message.success('Подборка успешно обновлена')
    queryClient.invalidateQueries([CATEGORIES_KEY, categoryId])
    queryClient.invalidateQueries([CATEGORY_VIDEO_BLOCK, id])
  }
  const onError = () => {
    message.error('Ошибка обновления подборки! Обратитесь к системному администратору')
  }
  const updateMutation = useMutation(VideoBlockService.update, { onSuccess, onError })
  const importMutation = useMutation(VideoBlockService.importProducts, {
    onSuccess,
    onError,
  })
  const attachMutation = useMutation(VideoBlockService.attachProducts, {
    onSuccess,
    onError,
  })
  const deleteMutation = useMutation(VideosService.delete, {
    onSuccess,
    onError,
  })
  const detachMutation = useMutation(VideoBlockService.detachVideos, {
    onSuccess,
    onError,
  })

  const updateVideoName = useMutation(VideosService.update, { onSuccess, onError })

  const handleUpdateVideoName = (title: string) =>
    updateMutation.mutateAsync({ id, category_id: categoryId, title })
  const handleDelete = () => deleteVideo.mutate(id)

  if (isLoading) return <Spin />

  const productsIds = data?.videos.map(({ product }) => product?.id)

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24}>
        <SpaceWithDivider>
          <Typography.Title
            level={3}
            editable={{ onChange: handleUpdateVideoName }}
            style={{ marginBottom: 0 }}
          >
            {data?.title}
          </Typography.Title>
          <Popconfirm title={'Вы уверены?'} onConfirm={handleDelete} disabled={isLoading}>
            <Button type={'primary'} size={'small'} icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </SpaceWithDivider>
      </Col>
      <Col xs={24}>
        <Tabs
          tabBarExtraContent={
            <Space>
              <SelectionImportProducts
                id={id}
                importHandler={importMutation.mutateAsync}
                isLoading={importMutation.isLoading}
              />
              <AddVideoModal id={id} />
            </Space>
          }
        >
          <Tabs.TabPane key={'selected'} tab={'Выбранные товары'}>
            <Table
              size={'small'}
              pagination={false}
              rowKey={'id'}
              dataSource={data?.videos}
              columns={[
                {
                  title: 'Название видео',
                  key: 'video',
                  render: ({ title, id, link }: IVideo) => {
                    const handleChangeTitle = (title: string) =>
                      title.length && updateVideoName.mutateAsync({ title, link, id })

                    return (
                      <Typography.Text editable={{ onChange: handleChangeTitle }}>
                        {title || ''}
                      </Typography.Text>
                    )
                  },
                },
                {
                  title: 'Ссылка',
                  key: 'link',
                  render: ({ title, id, link }: IVideo) => {
                    const handleChangeUrl = (link: string) =>
                      link.length && updateVideoName.mutateAsync({ link, title: title || '', id })
                    return (
                      <Typography.Link
                        href={link}
                        target={'_blank'}
                        rel={'noopenner noreferrer'}
                        editable={{ onChange: handleChangeUrl }}
                      >
                        {link}
                      </Typography.Link>
                    )
                  },
                },
                {
                  title: 'Товар',
                  dataIndex: 'product',
                  render: (product: IProduct) => (product ? product.title : 'Отсутствует'),
                },
                {
                  title: 'Действия',
                  key: 'actions',
                  align: 'right',
                  render: (entity: IVideo) => {
                    const onConfirm = () =>
                      entity.product
                        ? detachMutation.mutate({
                            id,
                            products: [entity.id],
                          })
                        : deleteMutation.mutate(entity.id)

                    return (
                      <Popconfirm title={'Вы уверены?'} onConfirm={onConfirm}>
                        <Button size={'small'} danger type={'primary'} icon={<DeleteOutlined />}>
                          Удалить
                        </Button>
                      </Popconfirm>
                    )
                  },
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={'selection'} tab={'Добавить из списка товаров'}>
            <SelectionProductsList
              productIds={productsIds as idType[]}
              attachProductsHandler={attachMutation.mutateAsync}
              isLoading={attachMutation.isLoading}
              id={id}
            />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  )
}

export default CategoryVideoBlock
