import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import { FileManager } from '@app/shared'
import { FieldData } from 'rc-field-form/es/interface'

interface props {
  initialPath?: string
  formInstance: FormInstance
  label?: string
  pathFieldName: string | (string | number)[]
  filenameFieldName?: string | (string | number)[]
  required?: boolean
  fieldKey?: string | number
}

const FormFileManagerSelect: React.FC<props> = ({
  initialPath,
  formInstance,
  label,
  pathFieldName,
  filenameFieldName,
  required,
  fieldKey,
}) => {
  const onSelectFile = (path: string, fileName: string) => {
    const fields: FieldData[] = []

    if (filenameFieldName) {
      fields.push(
        {
          name: pathFieldName,
          value: path,
        },
        {
          name: filenameFieldName,
          value: fileName,
        },
      )
    } else {
      fields.push({
        name: pathFieldName,
        value:
          'https://resource.prod.bradex.ru/api/file-manager/show?filename=' + path + '/' + fileName,
      })
    }

    formInstance.setFields(fields)
  }

  const onReset = () => {
    const fields = [pathFieldName]
    if (filenameFieldName) fields.push(filenameFieldName)
    formInstance.resetFields(fields)
  }

  return (
    <React.Fragment>
      {filenameFieldName && (
        <Form.Item hidden name={filenameFieldName}>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        fieldKey={fieldKey}
        name={pathFieldName}
        rules={[{ required, message: 'Выберите файл' }]}
        label={label}
      >
        <FileManager onSelect={onSelectFile} onReset={onReset} initialPath={initialPath} />
      </Form.Item>
    </React.Fragment>
  )
}

export default FormFileManagerSelect
