import React from 'react'
import { message, PageHeader } from 'antd'
import { PageInfo, SmiForm } from '@app/shared'
import { useMutation } from 'react-query'
import { SmiService } from '@app/api'
import { ISmi } from '@app/interfaces/smi'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from '@app/routes'
import { AxiosError } from 'axios'

interface props {}

const AddSmiPage: React.FC<props> = () => {
  const { push } = useHistory()
  const { isLoading, mutateAsync } = useMutation(SmiService.create, {
    onSuccess: () => {
      message.success('Запись успешно создана')
      push(privateRoutes.smi.path)
    },
    onError: (e: AxiosError) => {
      message.error(e.message)
    },
  })
  const onSubmit = (smi: Partial<ISmi>) => mutateAsync(smi)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить запись "СМИ о нас"'} />
      <PageHeader title={'Добавить запись "СМИ о нас"'} />
      <SmiForm handleSubmit={onSubmit} isLoading={isLoading} />
    </React.Fragment>
  )
}

export default AddSmiPage
