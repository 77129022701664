import React from 'react'
import { ISelection } from '@app/interfaces/selections'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import {
  FormButtons,
  FormContentInput,
  FormFileManagerSelect,
  FormSelectCategories,
  FormSeoInputs,
  FormTitleWithSlugInputs,
  ImageWithFallback,
  TextEditor,
} from '@app/shared'

interface props {
  isLoading: boolean
  submitFunction: (entity: ISelection) => void
  selection?: ISelection
}

const ProductSelectionForm: React.FC<props> = ({ isLoading, submitFunction, selection }) => {
  const [form] = Form.useForm()
  const onFinish = (values: ISelection) => {
    submitFunction({
      ...values,
      page: {
        ...values.page,
        content: values.page.content || '',
      },
    })
  }

  const handleRemoveBanner = () => {
    submitFunction({
      ...form.getFieldsValue(),
      mobile_image: {},
      tablet_image: {},
      desktop_image: {},
    })
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={selection} layout={'vertical'}>
      <Form.Item hidden name={'id'}>
        <Input />
      </Form.Item>
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={14}>
          <Card bordered={false} title={'Общая инофрмация'}>
            <FormTitleWithSlugInputs form={form} id={selection?.id} />
            <FormSelectCategories required={false} />
            <Form.Item name={['page', 'short_content']} label={'Короткое описание'}>
              <TextEditor placeholder={'Введите короткое описание'} />
            </Form.Item>
            <FormContentInput required={false} />
          </Card>
        </Col>
        <Col xs={24} lg={10}>
          <Card bordered={false} title={'SEO'}>
            <FormSeoInputs />
          </Card>
          <Card
            bordered={false}
            title={'Изображения'}
            extra={
              <Button onClick={handleRemoveBanner} danger>
                Удалить баннер
              </Button>
            }
          >
            <ImageWithFallback src={selection?.mobile} />
            <FormFileManagerSelect
              initialPath={selection?.mobile_image?.path}
              formInstance={form}
              label={'Мобильная версия'}
              filenameFieldName={['mobile_image', 'filename']}
              pathFieldName={['mobile_image', 'path']}
            />
            <ImageWithFallback src={selection?.tablet} />
            <FormFileManagerSelect
              initialPath={selection?.tablet_image?.path}
              formInstance={form}
              label={'Планшетная версия'}
              filenameFieldName={['tablet_image', 'filename']}
              pathFieldName={['tablet_image', 'path']}
            />
            <ImageWithFallback src={selection?.desktop} />
            <FormFileManagerSelect
              initialPath={selection?.desktop_image?.path}
              formInstance={form}
              label={'Версия для ПК'}
              filenameFieldName={['desktop_image', 'filename']}
              pathFieldName={['desktop_image', 'path']}
            />
          </Card>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default ProductSelectionForm
