import React from 'react'
import { Button, ButtonProps } from 'antd'
import styles from './folder-button.module.scss'

const FolderButton: React.FC<{ children: React.ReactNode } & ButtonProps> = (props) => {
  return (
    <Button type={'text'} block className={styles.folder} {...props}>
      {props.children}
    </Button>
  )
}

export default FolderButton
