import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'

const url = '/api/setProductPartnerLinks'

class SetProductsPartnersLinkService {
  addLink(data: any) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  editLink(data: any) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }

  deleteMultiple(ids: idType[]) {
    return makeRequest({
      url: url + '/delete',
      method: 'DELETE',
      data: { ids },
    })
  }
}

export default new SetProductsPartnersLinkService()
