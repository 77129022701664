import { StaticContentService } from '@app/api'
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { StaticContentType } from '@app/interfaces/static-content'

export const STATIC_CONTENT_KEY = 'static_content_key'

export function useStaticContent<T>(
  pageType: StaticContentType,
  options?: UseQueryOptions<T, Error>,
) {
  return useQuery<T, Error>(
    [STATIC_CONTENT_KEY, pageType],
    () =>
      StaticContentService.getLayout(pageType).then((response: AxiosResponse<T>) => response.data),
    {
      ...options,
    },
  )
}
