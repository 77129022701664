import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import queryString from 'query-string'
import { history } from '@api/history'
import { IExportFormData } from '@app/interfaces/forms'

const url = '/api/forms'

class FormsService {
  getAll() {
    return makeRequest({
      url,
      method: 'GET',
      params: queryString.parse(history.location.search),
    })
  }

  update({ id, to }: { id: idType; to: string }) {
    return makeRequest({
      url: url + '/' + id,
      method: 'PATCH',
      data: { to },
    })
  }

  export(data: IExportFormData) {
    return makeRequest({
      url: url + '/' + 'export',
      responseType: 'blob',
      params: data,
    })
  }
}

export default new FormsService()
