import React from 'react'
import { Card, Form, Input } from 'antd'
import { FormButtons } from '@app/shared'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import {idType} from "@app/interfaces"

interface props {
  isLoading: boolean
  group?: IBannerGroup
  submitFunction: (group: IBannerGroup, id?: idType) => void
}

const BannersGroupForm: React.FC<props> = ({ isLoading, group, submitFunction }) => {
  const onFinish = (values: any) => submitFunction(values, group?.id)

  return (
    <Card bordered={false}>
      <Form onFinish={onFinish} layout={'vertical'} initialValues={group}>
        <Form.Item label={'Заголовок'} name={'title'} rules={[{ required: true }]}>
          <Input placeholder={'Введите заголовок'} />
        </Form.Item>
        <Form.Item label={'Наименование'} name={'name'} rules={[{ required: true }]}>
          <Input placeholder={'Введите наименование'} />
        </Form.Item>
        <FormButtons isLoading={isLoading} />
      </Form>
    </Card>
  )
}

export default BannersGroupForm
