import React from 'react'
import { ExportFeedbackForms, ExportMailingList, SpaceWithDivider } from '@app/shared'
import { Col, Row } from 'antd'

interface props {}

const ExportsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Row gutter={[32, 32]}>
        <Col span={8}>
          <ExportMailingList />
        </Col>
        <Col span={8}>
          <ExportFeedbackForms />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ExportsPage
