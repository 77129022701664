export function getStaticPageImagePath(url: string | undefined): string {
  let result = url || ''
  // Удаление домена
  result = result.replace(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^\/\n]+)/, '')
  // Удаление дополнительного пути после домена
  result = result.replace('/api/file-manager/show?filename=', '')
  // Удаление названия файла
  result = result.replace(/\/[^/]*$/, '')

  return result
}
