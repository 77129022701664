import React from 'react'
import { AdvertisementForm, PageInfo } from '@app/shared'
import { message, PageHeader } from 'antd'
import { useMutation } from 'react-query'
import { AdvertisementsService } from '@app/api'
import { IAdvertisement } from '@app/interfaces/advertisements'
import { useHistory } from 'react-router-dom'

interface props {}

const AddAdvertisementPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { isLoading, mutateAsync } = useMutation(AdvertisementsService.create, {
    onSuccess: () => {
      message.success('Рекламный материал успешно добавлен')
      goBack()
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте повторить попытку позже')
    },
  })
  const title = 'Добавить рекламный материал'

  const submitFunction = (ads: IAdvertisement) => mutateAsync(ads)

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <PageHeader title={title} />
      <AdvertisementForm isLoading={isLoading} submitFunction={submitFunction} />
    </React.Fragment>
  )
}

export default AddAdvertisementPage
