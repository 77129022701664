import React from 'react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'

interface props {
  move: (curr: number, prev: number) => void
  name: number
}

const FormListMovingArrows: React.FC<props> = ({ move, name }) => {
  return (
    <Space size={12}>
      <Button
        type={'dashed'}
        size={'small'}
        icon={<ArrowUpOutlined />}
        onClick={() => move(name, name - 1)}
      />
      <Button
        type={'dashed'}
        size={'small'}
        icon={<ArrowDownOutlined />}
        onClick={() => move(name, name + 1)}
      />
    </Space>
  )
}

export default FormListMovingArrows
