import React from 'react'
import { Form, Input } from 'antd'

interface props {
  isPageTitleRequired?: boolean
}

const FormSeoInputs: React.FC<props> = ({ isPageTitleRequired = true }) => {
  return (
    <React.Fragment>
      <Form.Item
        name={['page', 'title']}
        label={'Meta заголовок'}
        rules={[{ required: isPageTitleRequired }]}
      >
        <Input placeholder={'Введите заголовок'} />
      </Form.Item>
      <Form.Item name={['page', 'description']} label={'Meta описание'}>
        <Input placeholder={'Введите описание'} />
      </Form.Item>
      <Form.Item name={['page', 'keywords']} label={'Meta ключевые слова'}>
        <Input placeholder={'Введите ключевые слова'} />
      </Form.Item>
    </React.Fragment>
  )
}

export default FormSeoInputs
