import React from 'react'
import { ISmi } from '@app/interfaces/smi'
import { Card, Col, Form, Input, Row } from 'antd'
import { FormButtons, FormFileManagerSelect, FormOrderInput, ImageWithFallback } from '@app/shared'

interface props {
  smi?: ISmi
  handleSubmit: (smi: Partial<ISmi>) => void
  isLoading: boolean
}

const SmiForm: React.FC<props> = ({ smi, handleSubmit, isLoading }) => {
  const [form] = Form.useForm()

  const onFinish = (values: Partial<ISmi>) => handleSubmit(values)

  return (
    <Form form={form} onFinish={onFinish} initialValues={smi} layout={'vertical'}>
      <Card bordered={false} title={'Общая информация'}>
        <Form.Item hidden name={'id'}>
          <Input />
        </Form.Item>
        <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
          <Input placeholder={'Введите заголовок'} />
        </Form.Item>
        <FormOrderInput required={false} />
      </Card>
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={12}>
          <Card bordered={false} title={'Превью'}>
            <ImageWithFallback height={256} src={smi?.image_url || 'error'} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['image', 'path']}
              filenameFieldName={['image', 'filename']}
              initialPath={smi?.image.path || '/smi'}
              required
            />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card bordered={false} title={'Полное изображение'}>
            <ImageWithFallback height={256} src={smi?.full_image_url || 'error'} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['full_image', 'path']}
              filenameFieldName={['full_image', 'filename']}
              initialPath={smi?.full_image.path || '/smi'}
              required
            />
          </Card>
        </Col>
        <Col xs={24}>
          <FormButtons isLoading={isLoading} />
        </Col>
      </Row>
    </Form>
  )
}

export default SmiForm
