import React from 'react'
import {
  INewsPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { Form, Input, Skeleton, Typography } from 'antd'
import {
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormSeoInputs,
  TextEditor,
} from '@app/shared'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'

interface props {}

const NewsPageEditor: React.FC<props> = () => {
  const pageType: StaticContentType = 'press_center_news_index'
  const { isLoading, isError, data } = useStaticContent<IStaticContentResponse<INewsPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: INewsPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <Form onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Form.Item name={'top_text'} label={'Текст вверху страницы'}>
          <TextEditor />
        </Form.Item>
        <FeedbackFormRadio />
        <FormButtons isLoading={isLoading} />
      </Form>
    </React.Fragment>
  )
}

export default NewsPageEditor
