import React from 'react'
import { Button } from 'antd'
import env from '@sanchik97/react-dotenv-public-url'
import { ButtonType } from 'antd/es/button'

interface props {
  slug?: string
  entityType?: string
  small?: boolean
  type?: ButtonType
}

const SiteLink: React.FC<props> = ({ slug, entityType, small, type }) => {
  return (
    <Button
      type={type}
      href={`${env.SHOP_URL}${entityType ? `/${entityType}` : ''}${slug ? '/' + slug : ''}`}
      target={'_blank'}
      rel={'noopener noreferrer'}
      size={small ? 'small' : 'middle'}
    >
      Посмотреть на сайте
    </Button>
  )
}

export default SiteLink
