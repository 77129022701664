import React from 'react'
import {AchievementForm, PageInfo} from "@app/shared"
import {message, PageHeader} from "antd"
import {useMutation} from "react-query"
import {AchievementsService} from "@app/api"
import {useHistory} from "react-router-dom"
import {IAchievement} from "@app/interfaces/achievements"
import {idType} from "@app/interfaces"

interface props {

}

const AddAchievementPage: React.FC<props> = () => {
	const {goBack} = useHistory()
	const {isLoading, mutateAsync} = useMutation(AchievementsService.create, {
		onSuccess: () => {
			message.success('Достижение успешно добавлено')
			goBack()
		},
		onError: () => {
			message.error('Ошибка! Что-то пошло не так.')
		}
	})

	const submitFunction = (achievement: IAchievement, id?:idType) => mutateAsync(achievement)

	return (
		<React.Fragment>
			<PageInfo title={'Добавить достижение'}/>
			<PageHeader title={'Добавить достижение'} />
			<AchievementForm submitFunction={submitFunction} isLoading={isLoading}/>
		</React.Fragment>
	)
}

export default AddAchievementPage
