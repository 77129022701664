import React from 'react'
import { IPromoTag } from '@app/interfaces/promotags'
import { idType, IPagination } from '@app/interfaces'
import {
  AttachPromotagDrawer,
  ImageWithFallback,
  Pagination,
  PromoTag,
  SearchInput,
  SiteLink,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'
import { PROMOTAGS_KEY } from '@app/hooks/query/promotags'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

interface props {
  promotags: IPromoTag[]
  pagination: IPagination
}

const PromotagsTable: React.FC<props> = ({ promotags, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={PROMOTAGS_KEY} />
  const tableFooter = () => <Pagination fetchingQueryKey={PROMOTAGS_KEY} pagination={pagination} />

  const columns: ColumnsType<IPromoTag> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Наименование',
      key: 'title',
      render: ({ id, title, color }: IPromoTag) => <PromoTag color={color} id={id} title={title} />,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Изображение',
      dataIndex: 'image_url',
      render: (image: string) => <ImageWithFallback src={image} height={75} />,
    },
    {
      title: 'Отображение в карточке товара',
      dataIndex: 'public',
      render: (status: boolean) => <StatusTag status={status} />,
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (promotag: IPromoTag) => (
        <SpaceWithDivider>
          <SiteLink entityType={'promotions/' + promotag.slug} />
          <AttachPromotagDrawer
            initialPath={promotag?.image?.path}
            id={promotag.id}
            invalidateQueryKey={PROMOTAGS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      rowKey={'id'}
      pagination={false}
      scroll={{ x: true }}
      title={tableTitle}
      footer={tableFooter}
      columns={columns}
      dataSource={promotags}
    />
  )
}

export default PromotagsTable
