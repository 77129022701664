import React from 'react'
import { IArticleTag } from '@app/interfaces/articleTags'
import { Space, Tag } from 'antd'

interface props {
  tags: IArticleTag[]
}

const ArticleTags: React.FC<props> = ({ tags }) => {
  if (!tags.length) return <span>Тэги отсутствует</span>

  return (
    <Space>
      {tags.map((tag) => (
        <Tag key={tag.id}>{tag.title}</Tag>
      ))}
    </Space>
  )
}

export default ArticleTags
