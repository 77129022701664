import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import { IBrand } from '@app/interfaces/brands'

const url = '/api/brands'

class BrandsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(brand: IBrand) {
    return makeRequest({
      url,
      method: 'POST',
      data: brand,
    })
  }

  update(brand: IBrand) {
    return makeRequest({
      url: url + '/' + brand.id,
      method: 'PATCH',
      data: brand,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }
}

export default new BrandsService()
