import makeRequest from '@api/makeRequest'
import { IStaticContentUpdateData, StaticContentType } from '@app/interfaces/static-content'
import { idType } from '@app/interfaces'

const url = '/api/static-content'

class StaticContent {
  getLayout(pageType: StaticContentType) {
    return makeRequest({ url: url + '/' + pageType })
  }

  updateLayout({ pageType, data }: IStaticContentUpdateData) {
    return makeRequest({ url: url + '/' + pageType, method: 'PUT', data: { [pageType]: data } })
  }

  attachProducts(data: { id: idType; products: idType[] }) {
    return makeRequest({
      url: url + '/' + data.id + '/attachProducts',
      method: 'POST',
      data: {
        products: data.products,
      },
    })
  }

  detachProducts(data: { id: idType; products: idType[] }) {
    return makeRequest({
      url: url + '/' + data.id + '/detachProducts',
      method: 'DELETE',
      data: {
        products: data.products,
      },
    })
  }

  importProducts(data: { id: idType; file: FormData }) {
    return makeRequest({
      url: url + '/' + data.id + '/importProducts',
      method: 'post',
      data: data.file,
    })
  }
}

export default new StaticContent()
