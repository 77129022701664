import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {PROMOTIONS_KEY, usePromotion} from '@app/hooks/query/promotions'
import { message, PageHeader, Skeleton } from 'antd'
import { ErrorBoundary, PageInfo, PromotionsForm } from '@app/shared'
import { IPromotion } from '@app/interfaces/promotions'
import { idType } from '@app/interfaces'
import { useMutation } from 'react-query'
import { PromotionsService } from '@app/api'
import {queryClient} from "@app/index"

interface props {}

const EditPromotionPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = usePromotion(id)
  const { isLoading: isEditLoading, mutateAsync } = useMutation(PromotionsService.update, {
    onSuccess: () => {
      message.success('Промоакция успешно изменена')
      queryClient.invalidateQueries([PROMOTIONS_KEY, id])
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Попробуйте снова')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const submitFunction = (promotion: IPromotion, id?: idType) => mutateAsync({ promotion, id: id! })

  return (
    <React.Fragment>
      <PageInfo title={'Изменить промоакцию'} />
      <PageHeader title={'Изменить промоакцию'} />
      <PromotionsForm isLoading={isEditLoading} submitFunction={submitFunction} promotion={data!} />
    </React.Fragment>
  )
}

export default EditPromotionPage
