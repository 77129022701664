import { IArticleTag } from '@app/interfaces/articleTags'
import { ArticleTagsService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'

export const ARTICLE_TAGS_LIST = 'article_tags_list'

export function useArticleTagsList(options?: UseQueryOptions<IArticleTag[], Error>) {
  return useQuery<IArticleTag[], Error>(
    ARTICLE_TAGS_LIST,
    () =>
      ArticleTagsService.getList().then((response: AxiosResponse<IArticleTag[]>) => response.data),
    {
      ...options,
    },
  )
}
