import React from 'react'
import { message, PageHeader } from 'antd'
import { PageInfo, PromotionsForm } from '@app/shared'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { PromotionsService } from '@app/api'
import { idType } from '@app/interfaces'
import { IPromotion } from '@app/interfaces/promotions'

interface props {}

const AddPromotionPage: React.FC<props> = () => {
  const { goBack } = useHistory()

  const { isLoading, mutateAsync } = useMutation(PromotionsService.create, {
    onSuccess: () => {
      message.success('Промоакция успешно создана')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Проверьте данные и попробуйте снова')
    },
  })

  const submitFunction = (promotion: IPromotion, id?: idType) => mutateAsync(promotion)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить промоакцию'} />
      <PageHeader title={'Добавить промоакцию'} />
      <PromotionsForm submitFunction={submitFunction} isLoading={isLoading} />
    </React.Fragment>
  )
}

export default AddPromotionPage
