import React from 'react'
import { ErrorBoundary, PageInfo, RedirectsDrawer, SpaceWithDivider } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { useRedirects } from '@app/hooks/query/redirects'
import { RedirectsImport, RedirectsTable } from '@app/pages/redirects-page/components'

interface props {}

const RedirectsPage: React.FC<props> = () => {
  const { isLoading, isError, data: response } = useRedirects()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Редиректы'} />
      <PageHeader
        title={'Редиректы'}
        extra={[
          <SpaceWithDivider key={'1'}>
            <RedirectsImport />
            <RedirectsDrawer key={'1'} />
          </SpaceWithDivider>,
        ]}
      />
      <RedirectsTable redirects={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default RedirectsPage
