import React, { useState } from 'react'
import { ILkManager, ILKManagerData } from '@app/interfaces/managers'
import { useMutation } from 'react-query'
import { ManagersService } from '@app/api'
import { Button, Drawer, Form, Input, message } from 'antd'
import { queryClient } from '@app/index'
import { LK_MANAGERS_KEY } from '@app/hooks/query/managers'
import { EditOutlined } from '@ant-design/icons'
import { FormFileManagerSelect, SpaceWithDivider } from '@app/shared'

export interface LkManagersDrawerProps {
  manager: ILkManager
}

const LkManagerDrawer: React.FC<LkManagersDrawerProps> = ({ manager }) => {
  const [form] = Form.useForm()
  const formId = 'lk-manager-drawer'
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)
  const { isLoading, mutate } = useMutation(ManagersService.updateImage, {
    onSuccess: () => {
      message.success('Данные успешно обновлены')
      queryClient.invalidateQueries(LK_MANAGERS_KEY)
      handleChangeVisible()
    },
    onError: () => {
      message.error('Ошибка обновления данных')
    },
  })
  const onFinish = (data: ILKManagerData) => mutate(data)

  return (
    <React.Fragment>
      <Button type={'primary'} icon={<EditOutlined />} onClick={handleChangeVisible}>
        Изменить
      </Button>
      <Drawer
        width={400}
        visible={visible}
        onClose={handleChangeVisible}
        title={'Изменить менеджера'}
        destroyOnClose
        footer={
          <SpaceWithDivider>
            <Button
              type={'primary'}
              htmlType={'submit'}
              form={formId}
              disabled={isLoading}
              loading={isLoading}
            >
              Сохранить
            </Button>
            <Button type={'default'} htmlType={'button'} onClick={handleChangeVisible}>
              Закрыть
            </Button>
          </SpaceWithDivider>
        }
      >
        <Form.Item label={'ID'}>
          <Input disabled value={manager.id} />
        </Form.Item>
        <Form.Item label={'ФИО'}>
          <Input disabled value={manager.full_name} />
        </Form.Item>
        <Form.Item label={'Дата создания'}>
          <Input disabled value={new Date(manager.created_at).toLocaleDateString()} />
        </Form.Item>

        <Form
          id={formId}
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={manager}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <FormFileManagerSelect
            label={'Фотография менеджера'}
            formInstance={form}
            pathFieldName={['image', 'path']}
            filenameFieldName={['image', 'filename']}
            initialPath={manager?.image?.path}
            required
          />
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default LkManagerDrawer
