import React from 'react'
import { idType } from '@app/interfaces'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import { privateRoutes } from '@app/routes'

interface props {
  withTitle?: boolean
  brandName: string | undefined
  brandId: idType | undefined
}

const BrandLink: React.FC<props> = ({ withTitle = false, brandId, brandName }) => {
  if (!brandId) return <React.Fragment />

  return (
    <Typography.Text type={'secondary'}>
      {withTitle && 'Бренд:'}{' '}
      <Link to={privateRoutes.brands.path + '/details/' + brandId}>{brandName}</Link>
    </Typography.Text>
  )
}

export default React.memo(BrandLink)
