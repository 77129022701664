import React from 'react'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { privateRoutes } from '@app/routes'

type Keys = keyof typeof privateRoutes

interface props {
  route: Keys
}

const AddLink: React.FC<props> = ({ route }) => {
  return (
    <Link
      to={privateRoutes[route].path}
      className={'ant-btn ant-btn-primary ant-btn-round ant-btn-lg ant-btn-background-ghost'}
    >
      <PlusOutlined />
      <span>Добавить</span>
    </Link>
  )
}

export default AddLink
