import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'

const url = '/api/static-pages'

class StaticPagesService {
  getAll() {
    return makeRequest({
      url,
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: any) {
    return makeRequest({
      url,
      method: 'POST',
      data,
    })
  }

  update(data: any) {
    return makeRequest({
      url: url + '/' + data.id,
      method: 'PATCH',
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'DELETE',
    })
  }
}

export default new StaticPagesService()
