import React from 'react'
import { useArticle } from '@app/hooks/query/articles'
import { useParams } from 'react-router-dom'
import { Card, Col, Divider, Row, Skeleton, Typography } from 'antd'
import {
  ArticleTags,
  CategoryLink,
  EditLink,
  ErrorBoundary,
  ImageWithFallback,
  PageInfo,
  SecondaryText,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  Subtitle,
} from '@app/shared'

const { Title } = Typography

interface props {}

const ArticlePage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useArticle(id)

  if (isLoading) return <Skeleton active={true} />
  if (isError) return <ErrorBoundary />

  const { page, category, title, slug, categories, date, tags, image_path } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={15}>
          <Card
            title={'Общая информация'}
            extra={
              <SpaceWithDivider>
                <SiteLink entityType={'press-centr/blog/' + slug} />
                <EditLink id={id} route={'articles'} />
              </SpaceWithDivider>
            }
          >
            <ImageWithFallback src={image_path} width={500} />
            <ArticleTags tags={tags} />
            <Title level={2}>{title}</Title>

            <SpaceWithDivider>
              <SecondaryText>ID статьи: {id}</SecondaryText>
              <SecondaryText>
                Категории:{' '}
                {categories?.length
                  ? categories.map((category) => (
                      <CategoryLink title={category.title} id={category.id} />
                    ))
                  : 'Категории не выбраны'}
              </SecondaryText>

              <SecondaryText>
                Категория (устаревшее):{' '}
                {category && <CategoryLink title={category.title} id={category.id} />}
              </SecondaryText>
              <SecondaryText>Дата публикации: {date}</SecondaryText>
              <SecondaryText>Slug: {slug}</SecondaryText>
            </SpaceWithDivider>

            <Divider />
            <Subtitle>Краткое описание</Subtitle>
            <div dangerouslySetInnerHTML={{ __html: page.short_content }} />
            <Divider />
            <Subtitle>Контент</Subtitle>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </Card>
        </Col>
        <Col xs={24} xl={9}>
          <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ArticlePage
