import React from 'react'
import { ICroppedProduct, IUnit } from '@app/interfaces/product'
import { idType, IPagination } from '@app/interfaces'
import {
  BrandLink,
  ImageWithFallback,
  Pagination,
  ProductLink,
  SearchInput,
  StatusTag,
} from '@app/shared'
import { PRODUCTS_KEY } from '@app/hooks/query/products'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import { IBrand } from '@app/interfaces/brands'

interface props {
  products: ICroppedProduct[]
  pagination: IPagination
}

const ProductsTable: React.FC<props> = ({ products, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={PRODUCTS_KEY} />
  const tableFooter = () => <Pagination fetchingQueryKey={PRODUCTS_KEY} pagination={pagination} />

  const columns: ColumnsType<ICroppedProduct> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: 'Наименование',
      key: 'title',
      render: ({ title, id }: ICroppedProduct) => <ProductLink title={title} id={id} />,
    },
    {
      title: 'Изображение',
      dataIndex: 'image_path',
      render: (image_path: string) => (
        <ImageWithFallback src={image_path} preview={true} width={100} />
      ),
    },
    {
      title: 'Артикул',
      dataIndex: 'sku',
    },
    {
      title: 'Бренд',
      dataIndex: 'brand',
      render: (brand: IBrand | null) => <BrandLink brandId={brand?.id} brandName={brand?.title} />,
    },
    {
      title: 'Единица измерения',
      dataIndex: 'unit',
      render: (unit: IUnit | null) => <span>{unit?.title}</span>,
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
  ]

  return (
    <Table
      bordered
      columns={columns}
      dataSource={products}
      pagination={false}
      title={tableTitle}
      footer={tableFooter}
      scroll={{ x: true }}
      rowKey={'id'}
    />
  )
}

export default ProductsTable
