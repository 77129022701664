import React from 'react'
import { Button, Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { SpaceWithDivider } from '@app/shared'

interface props {
  isLoading: boolean
}

const FormButtons: React.FC<props> = ({ isLoading }) => {
  const { goBack } = useHistory()

  return (
    <Form.Item>
      <SpaceWithDivider>
        <Button htmlType={'submit'} type={'primary'} disabled={isLoading} loading={isLoading}>
          Сохранить
        </Button>
        <Button type={'default'} onClick={goBack} disabled={isLoading}>
          Отменить
        </Button>
      </SpaceWithDivider>
    </Form.Item>
  )
}

export default FormButtons
