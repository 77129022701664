import React from 'react'
import { ICroppedCategory } from '@app/interfaces/category'
import { List } from 'antd'
import { CategoryLink } from '@app/shared'

interface props {
  categories: ICroppedCategory[]
  title: string
}

const CategoryList: React.FC<props> = ({ categories , title}) => {
  return (
    <List
      header={title}
      dataSource={categories}
      renderItem={(item) => (
        <List.Item>
          <CategoryLink title={item.title} id={item.id} />
        </List.Item>
      )}
    />
  )
}

export default CategoryList
