import React from 'react'
import { useSlotsBanners } from '@app/hooks/query/slots'
import { Form, Select } from 'antd'

interface props {
  name: string
  required?: boolean
}

const FormSlotGroupSelect: React.FC<props> = ({ name, required = true }) => {
  const { isLoading, data } = useSlotsBanners()

  return (
    <Form.Item name={name} label={'Группа баннеров'} rules={[{ required }]}>
      <Select
        showSearch={true}
        placeholder={'Выберите группу'}
        allowClear={false}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={isLoading}
        loading={isLoading}
      >
        {data?.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormSlotGroupSelect
