import { useMutation } from 'react-query'
import { StaticContentService } from '@app/api'
import { message } from 'antd'
import { StaticContentType } from '@app/interfaces/static-content'
import { queryClient } from '@app/index'
import { STATIC_CONTENT_KEY } from '@app/hooks/query/static-content'

export function useUpdateStaticContent(pageType: StaticContentType) {
  return useMutation(StaticContentService.updateLayout, {
    onSuccess: () => {
      message.success('Статические данные успешно обновлены')
      queryClient.invalidateQueries([STATIC_CONTENT_KEY, pageType])
    },
    onError: () => {
      message.error('Ошибка обновления статических данных')
    },
  })
}
