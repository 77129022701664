import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'
import { IBannerGroup } from '@app/interfaces/banner-groups'
import queryString from 'query-string'
import { history } from '@api/history'

const url = '/api/banner-groups'

class BannerGroupsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(group: IBannerGroup) {
    return makeRequest({
      url,
      method: 'post',
      data: group,
    })
  }

  update(data: { group: IBannerGroup; id: idType }) {
    return makeRequest({
      url: url + '/' + data.id + '?_method=PUT',
      method: 'post',
      data: data.group,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }

  getFilterBannerGroups() {
    return makeRequest({
      url: url + '/list',
    })
  }
}

export default new BannerGroupsService()
