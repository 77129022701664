import { IProductPropertyFormData } from '@app/interfaces/product'
import makeRequest from '@api/makeRequest'
import { idType } from '@app/interfaces'

const url = '/api/properties'

class PropertiesService {
  create(data: IProductPropertyFormData) {
    return makeRequest({ url, method: 'post', data })
  }

  update(data: IProductPropertyFormData) {
    return makeRequest({ url: url + '/' + data.id, method: 'put', data })
  }

  delete(id: idType) {
    return makeRequest({ url: url + '/' + id, method: 'delete' })
  }
}

export default new PropertiesService()
