import React from 'react'
import { IProductAttribute, IProductProperty } from '@app/interfaces/product'
import { Card, List, Space, Typography } from 'antd'
import { ProductPropertiesForm } from '@app/pages/product-page/components'
import { DeleteModal } from '@app/shared'
import { PropertiesService } from '@app/api'
import { PRODUCTS_KEY } from '@app/hooks/query/products'

export interface ProductPropertiesProps {
  properties: IProductProperty[]
  attributes: IProductAttribute[]
}

const ProductProperties: React.FC<ProductPropertiesProps> = ({ properties, attributes }) => {
  return (
    <Card
      title={'Свойства'}
      bordered={false}
      extra={<ProductPropertiesForm attributes={attributes} />}
    >
      <List
        itemLayout={'vertical'}
        dataSource={properties}
        renderItem={(property) => (
          <List.Item
            actions={[
              <Typography.Text>Порядковый номер: {property.sort ?? 'Не задан'}</Typography.Text>,
            ]}
            extra={
              <Space>
                <ProductPropertiesForm property={property} attributes={attributes} />
                <DeleteModal
                  id={property.id}
                  deleteFunction={PropertiesService.delete}
                  invalidateQueryKey={PRODUCTS_KEY}
                />
              </Space>
            }
          >
            <List.Item.Meta
              title={<Typography.Title level={5}>{property.title}</Typography.Title>}
              description={property.value}
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export default ProductProperties
