import React from 'react'
import {
  IAdsMaterialsPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { Form, Input, Skeleton, Typography } from 'antd'
import { ErrorBoundary, FormButtons, FormSeoInputs } from '@app/shared'

interface props {}

const AdvertisementsPageEditor: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'press_center_ads_materials'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IAdsMaterialsPage>>(pageType)
  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IAdsMaterialsPage) => update.mutateAsync({ pageType, data })

  return (
    <Form form={form} layout={'vertical'} initialValues={data?.data} onFinish={onFinish}>
      <Typography.Title level={4}>Заголовок H1</Typography.Title>
      <Form.Item name={['page', 'h1']}>
        <Input placeholder={'Введите заголовок H1'} />
      </Form.Item>
      <Typography.Title level={4}>SEO</Typography.Title>
      <FormSeoInputs isPageTitleRequired={false} />
      <FormButtons isLoading={update.isLoading} />
    </Form>
  )
}

export default AdvertisementsPageEditor
