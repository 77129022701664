import { AdvertisementsService } from '@app/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import { IAdvertisement, IAdvertisementResponse } from '@app/interfaces/advertisements'

export const ADVERTISEMENTS_KEY = 'advertisements-key'

export function useAdvertisements(options?: UseQueryOptions<IAdvertisementResponse, Error>) {
  return useQuery<IAdvertisementResponse, Error>(
    ADVERTISEMENTS_KEY,
    () =>
      AdvertisementsService.getAll().then(
        (response: AxiosResponse<IAdvertisementResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useAdvertisement(id: idType, options?: UseQueryOptions<IAdvertisement, Error>) {
  return useQuery<IAdvertisement, Error>(
    [ADVERTISEMENTS_KEY, id],
    () =>
      AdvertisementsService.getById(id).then(
        (response: AxiosResponse<IAdvertisement>) => response.data,
      ),
    {
      ...options,
    },
  )
}
