import { useQuery, UseQueryOptions } from 'react-query'
import { RedirectsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import {IRedirect, IRedirectResponse} from "@app/interfaces/redirects"

export const REDIRECTS_KEY = 'redirects_key'

export function useRedirects(options?: UseQueryOptions<IRedirectResponse, Error>) {
  return useQuery<IRedirectResponse, Error>(
    REDIRECTS_KEY,
    () => RedirectsService.getAll().then((response: AxiosResponse<any>) => response.data),
    {
      ...options,
    },
  )
}

export function useRedirect(id: idType, options?: UseQueryOptions<IRedirect, Error>) {
  return useQuery<IRedirect, Error>(
    [REDIRECTS_KEY, id],
    () => RedirectsService.getById(id).then((response: AxiosResponse<IRedirect>) => response.data),
    {
      ...options,
    },
  )
}
