import React from 'react'
import { IPromoTag } from '@app/interfaces/promotags'
import { List } from 'antd'
import { PromoTag } from '@app/shared'

interface props {
  promotags: IPromoTag[]
}

const PromotagsList: React.FC<props> = ({ promotags }) => {
  return (
    <List
      header={<strong>Промотеги</strong>}
      dataSource={promotags}
      renderItem={({ color, title, id }) => (
        <List.Item>
          <PromoTag color={color} id={id} title={title} />
        </List.Item>
      )}
    />
  )
}

export default PromotagsList
