import React from 'react'
import { useBanners } from '@app/hooks/query/banners'
import { AddLink, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { BannersTable } from '@app/pages/banners-page/components'

interface props {}

const BannersPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useBanners()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Баннеры'} />
      <PageHeader title={'Баннеры'} extra={<AddLink route={'add_banner'} key={'1'} />} />
      <BannersTable banners={data} pagination={pagination} />
    </React.Fragment>
  )
}

export default BannersPage
