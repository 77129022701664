import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import styles from '@app/pages/category-page/components/filter-attributes/filter-attributes.module.scss'
import { FilterSelect } from '@app/pages/category-page/components'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { FilterAttributesService } from '@app/api'
import { ICategoryFilterAttributesResponse } from '@app/interfaces/filter-attributes'
import { idType } from '@app/interfaces'
import { queryClient } from '@app/index'
import { CATEGORY_FILTER_ATTRIBUTES_KEY } from '@app/hooks/query/filter-attributes'
import { FormListMovingArrows } from '@app/shared'

interface props {
  data: ICategoryFilterAttributesResponse
  edit: boolean
  changeEditStatus: () => void
  categoryId: idType
}

const FilterAttributesForm: React.FC<props> = ({
  data,
  edit,
  categoryId: id,
  changeEditStatus,
}) => {
  const [isRemove, setIsRemove] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { isLoading: isMutateLoading, mutateAsync } = useMutation(
    FilterAttributesService.setCategoryFilterAttributes,
    {
      onSuccess: async () => {
        message.success('Фильтры успешно сохранены')
        await queryClient.invalidateQueries(CATEGORY_FILTER_ATTRIBUTES_KEY)
        changeEditStatus()
      },
      onError: () => {
        message.error('Ошибка! Что-то пошло не так')
      },
    },
  )

  useEffect(() => {
    form.resetFields()
  }, [id, form])

  const onFinish = ({ filter_attributes }: any) => mutateAsync({ id, filter_attributes })

  const removeFieldFunction = async (
    name: number,
    cb: (name: number) => void,
    removeFromChildren?: boolean,
  ) => {
    setIsRemove(true)

    const asyncFunction = removeFromChildren
      ? FilterAttributesService.removeCategoryChildrenFilterAttribute
      : FilterAttributesService.removeCategoryFilterAttribute

    const selectedFilter = filter_attributes.find((filter, index) => index === name)

    await asyncFunction(id, selectedFilter!.attribute.id)
      .then(() => {
        message.success('Атрибут успешно удален')
        cb(name)
      })
      .catch(() => {
        message.error('Ошибка! Попробуйте, пожалуйста, снова')
      })

    setIsRemove(false)
  }

  const { attributes, filter_attributes, types } = data

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        filter_attributes: filter_attributes.map(({ id, attribute_id, type_id, title }) => ({
          id,
          attribute_id,
          type_id,
          title,
        })),
      }}
    >
      <Form.List name={'filter_attributes'}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => {
              const isFieldAlreadyExist = filter_attributes[fieldKey]

              return (
                <div key={key} className={styles.space}>
                  {edit && (
                    <Form.Item>
                      <FormListMovingArrows move={move} name={name} />
                    </Form.Item>
                  )}
                  <Form.Item {...restField} name={[name, 'id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    fieldKey={[name, 'title']}
                    rules={[{ required: true, message: 'Заполните поле' }]}
                  >
                    <Input disabled={!edit} placeholder={'Введите заголовок'} />
                  </Form.Item>
                  <FilterSelect
                    placeholder={'Выберите атрибут'}
                    restField={restField}
                    name={name}
                    fieldKey={fieldKey}
                    fieldName={'attribute_id'}
                    edit={edit}
                    options={attributes}
                  />
                  <FilterSelect
                    placeholder={'Выберите тип'}
                    restField={restField}
                    name={name}
                    fieldKey={fieldKey}
                    fieldName={'type_id'}
                    edit={edit}
                    options={types}
                  />
                  {edit && (
                    <React.Fragment>
                      {isFieldAlreadyExist ? (
                        <React.Fragment>
                          <Form.Item>
                            <Button
                              onClick={() => removeFieldFunction(fieldKey, remove, false)}
                              disabled={isRemove}
                            >
                              Удалить только из текущей категории
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              onClick={() => removeFieldFunction(fieldKey, remove, true)}
                              disabled={isRemove}
                            >
                              Удалить из текущей и из дочерних категорий
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                      ) : (
                        <Form.Item>
                          <Button onClick={() => remove(name)} disabled={isMutateLoading}>
                            <MinusCircleOutlined />
                          </Button>
                        </Form.Item>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )
            })}
            {edit && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusCircleOutlined />}
                  disabled={isMutateLoading}
                >
                  Добавить фильтр
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
      {edit && (
        <Form.Item>
          <Button
            htmlType={'submit'}
            type={'primary'}
            disabled={isMutateLoading}
            loading={isMutateLoading}
          >
            Сохранить
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}

export default FilterAttributesForm
