import React from 'react'
import { IPromotion } from '@app/interfaces/promotions'
import { idType } from '@app/interfaces'
import { Card, Col, Form, Row } from 'antd'
import {
  FormButtons,
  FormDatePeriod,
  FormFileManagerSelect,
  FormSelectPromotags,
  ImageWithFallback,
} from '@app/shared'
import moment from 'moment'

interface props {
  isLoading: boolean
  submitFunction: (promotion: IPromotion, id?: idType) => void
  promotion?: IPromotion
}

const PromotionsForm: React.FC<props> = ({ isLoading, submitFunction, promotion }) => {
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    const promotionValues = {
      ...values,
      date_start: moment(values.date[0]).format('YYYY-MM-DD'),
      date_finish: moment(values.date[1]).format('YYYY-MM-DD'),
    }

    submitFunction(promotionValues, promotion?.id)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{
        ...promotion,
        date: [moment(promotion?.date_start), moment(promotion?.date_finish)],
      }}
    >
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={14}>
          <Card bordered={false} title={'Общая информация'}>
            <FormDatePeriod required={true} />
            <FormSelectPromotags formInstance={form} required={true} />
          </Card>
        </Col>
        <Col xs={24} xl={10}>
          <Card bordered={false} title={'Изображение'}>
            <ImageWithFallback src={promotion?.image320x370} height={200} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['image', 'path']}
              filenameFieldName={['image', 'filename']}
              initialPath={promotion?.image.path || '/promotions'}
              required
            />
          </Card>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default PromotionsForm
