import React from 'react'
import { useParams } from 'react-router-dom'
import { useAchievement } from '@app/hooks/query/achievements'
import { Card, Skeleton, Typography } from 'antd'
import {ErrorBoundary, ImageWithFallback, PageInfo, SecondaryText, SpaceWithDivider} from '@app/shared'

interface props {}

const AchievementPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data } = useAchievement(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { title, image_full, order } = data!

  return (
    <React.Fragment>
      <PageInfo title={data!.title} />
      <Card title={'Общая информация'}>
        <ImageWithFallback src={image_full} height={150} />
        <Typography.Title>{title}</Typography.Title>
				<SpaceWithDivider>
					<SecondaryText>ID: {id}</SecondaryText>
					<SecondaryText>Порядковый номер: {order}</SecondaryText>
				</SpaceWithDivider>
      </Card>
    </React.Fragment>
  )
}

export default AchievementPage
