import React from 'react'
import { IProductAttribute } from '@app/interfaces/product'
import { Descriptions, Typography } from 'antd'

interface props {
  attributes: IProductAttribute[]
}

const ProductAttributes: React.FC<props> = ({ attributes }) => {
  return (
    <Descriptions title={'Атрибуты'} column={1} bordered size={'small'}>
      {attributes && attributes.length ? (
        attributes.map(({ option, measure, value, id }) => (
          <Descriptions.Item key={id} label={option}>
            {value} {measure}
          </Descriptions.Item>
        ))
      ) : (
        <Typography.Text>Данные отсутствуют</Typography.Text>
      )}
    </Descriptions>
  )
}

export default ProductAttributes
