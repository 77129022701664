import React, { useContext } from 'react'
import styles from '../../file-manager.module.scss'
import { Button, Col, Input, Row } from 'antd'
import {
  BoldOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { Folders } from '@app/shared/file-manager/components'
import { FileManagerContext } from '@app/shared/file-manager/file-manager.context'

interface props {}

const Navigation: React.FC<props> = () => {
  const { pathFilter, handleChangeFilter, handleChangeParams, params } =
    useContext(FileManagerContext)

  const handleSortBy = () => {
    handleChangeParams({
      sortOrder: params.sortOrder,
      sortBy: params.sortBy === 'name' ? 'createdAt' : 'name',
    })
  }
  const handleSortOrder = () => {
    handleChangeParams({
      sortBy: params.sortBy,
      sortOrder: params.sortOrder === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <div className={styles.folders}>
      <Row>
        <Col xs={18}>
          <Input
            value={pathFilter}
            style={{ marginBottom: 10 }}
            onChange={handleChangeFilter}
            placeholder={'Поиск подпапок'}
            bordered={false}
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col xs={6}>
          <Button.Group size={'small'}>
            <Button
              onClick={handleSortBy}
              type={'default'}
              icon={params.sortBy === 'name' ? <BoldOutlined /> : <ClockCircleOutlined />}
            />
            <Button
              onClick={handleSortOrder}
              type={'default'}
              icon={
                params.sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />
              }
            />
          </Button.Group>
        </Col>
      </Row>
      <Folders />
    </div>
  )
}

export default Navigation
