import React from 'react'
import { PROMOTAG_PRODUCTS_KEY, usePromotagProducts } from '@app/hooks/query/promotags'
import { useParams } from 'react-router-dom'
import { Skeleton, Table } from 'antd'
import { ErrorBoundary, Pagination, ProductLink, SearchInput, StatusTag } from '@app/shared'
import { ColumnsType } from 'antd/es/table'
import { ICroppedProduct } from '@app/interfaces/product'
import { idType } from '@app/interfaces'

interface props {}

const PromotagProductsTable: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, data: response } = usePromotagProducts(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  const tableTitle = () => <SearchInput fetchingQueryKey={PROMOTAG_PRODUCTS_KEY} />
  const tableFooter = () => (
    <Pagination fetchingQueryKey={PROMOTAG_PRODUCTS_KEY} pagination={pagination} />
  )

  const columns: ColumnsType<ICroppedProduct> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => <span># {id}</span>,
    },
    {
      title: 'Наименование',
      key: 'title',
      render: ({ id, title }: ICroppedProduct) => <ProductLink id={id} title={title} />,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      render: (status: boolean) => <StatusTag status={status} />,
    },
  ]

  return (
    <Table
      columns={columns}
      bordered
      rowKey={'id'}
      title={tableTitle}
      footer={tableFooter}
      scroll={{ x: true }}
      pagination={false}
      dataSource={data}
    />
  )
}

export default PromotagProductsTable
