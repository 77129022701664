import React from 'react'
import {privateRoutes} from "@app/routes"
import {Link} from "react-router-dom"
import {idType} from "@app/interfaces"

interface props {
	title: string
	id: idType
}

const ProductLink: React.FC<props> = ({title, id}) => {
	return (
		<Link to={privateRoutes.products.path + '/details/' + id}>{title}</Link>
	)
}

export default React.memo(ProductLink)
