import React, { useState } from 'react'
import { IChangeLkUserData, ILKUser } from '@app/interfaces/users'
import { Button, Drawer, Form, Input, message, Switch } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { UsersService } from '@app/api'
import { queryClient } from '@app/index'
import { LK_USERS_KEY } from '@app/hooks/query/users'
import { SpaceWithDivider } from '@app/shared'

export interface LkUsersDrawerProps {
  user: ILKUser
}

const LkUserDrawer: React.FC<LkUsersDrawerProps> = ({ user }) => {
  const formId = 'lk-user-drawer'
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const { isLoading, mutate } = useMutation(UsersService.update, {
    onSuccess: () => {
      message.success('Данные успешно обновлены')
      queryClient.invalidateQueries(LK_USERS_KEY)
      handleChangeVisible()
    },
    onError: () => {
      message.error('Ошибка обновления данных')
    },
  })

  const onFinish = (data: IChangeLkUserData) => mutate(data)

  return (
    <>
      <Button type={'primary'} onClick={handleChangeVisible} icon={<EditOutlined />}>
        Изменить
      </Button>
      <Drawer
        visible={visible}
        width={400}
        destroyOnClose
        title={'Изменить пользователя'}
        onClose={handleChangeVisible}
        footer={
          <SpaceWithDivider>
            <Button
              disabled={isLoading}
              loading={isLoading}
              form={formId}
              htmlType={'submit'}
              type={'primary'}
            >
              Сохранить
            </Button>
            <Button htmlType={'button'} onClick={handleChangeVisible} type={'default'}>
              Отменить
            </Button>
          </SpaceWithDivider>
        }
      >
        <Form.Item label={'ID'}>
          <Input disabled value={user.id} />
        </Form.Item>
        <Form.Item label={'ФИО'}>
          <Input disabled value={user.name} />
        </Form.Item>
        <Form.Item label={'Email'}>
          <Input disabled value={user.email} />
        </Form.Item>
        <Form.Item label={'Должность'}>
          <Input disabled value={user.position} />
        </Form.Item>
        <Form.Item label={'Номер телефона'}>
          <Input disabled value={user.phone} />
        </Form.Item>
        <Form.Item label={'Дата создания'}>
          <Input disabled value={new Date(user.created_at).toLocaleDateString()} />
        </Form.Item>
        <Form id={formId} layout={'vertical'} onFinish={onFinish} initialValues={user}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item label={'Статус пользователя'} name={'active'} valuePropName={'checked'}>
            <Switch checkedChildren={'Включен'} unCheckedChildren={'Отключен'} />
          </Form.Item>
          <Form.Item name={'password'} label={'Пароль'} rules={[{ min: 6 }]}>
            <Input.Password placeholder={'Введите пароль'} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default LkUserDrawer
