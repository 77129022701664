import React from 'react'
import { Form, Input, Skeleton, Typography } from 'antd'
import { ISmiPage, IStaticContentResponse, StaticContentType } from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { ErrorBoundary, FormButtons, FormSeoInputs } from '@app/shared'

interface props {}

const SmiPageEditor: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'press_center_smi'
  const { isLoading, isError, data } = useStaticContent<IStaticContentResponse<ISmiPage>>(pageType)
  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: ISmiPage) => update.mutateAsync({ pageType, data })

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={data?.data}>
      <Typography.Title level={4}>Заголовок H1</Typography.Title>
      <Form.Item name={['page', 'h1']}>
        <Input placeholder={'Введите заголовок H1'} />
      </Form.Item>
      <Typography.Title level={4}>SEO</Typography.Title>
      <FormSeoInputs isPageTitleRequired={false} />
      <FormButtons isLoading={update.isLoading} />
    </Form>
  )
}

export default SmiPageEditor
