import React, { useState } from 'react'
import { idType } from '@app/interfaces'
import { Button, Form, Input, message, Modal } from 'antd'
import { VideoData } from '@app/interfaces/video'
import { useMutation } from 'react-query'
import { VideosService } from '@app/api'
import { queryClient } from '@app/index'
import { CATEGORY_VIDEO_BLOCK } from '@app/hooks/query/video-block'
import { VideoCameraOutlined } from '@ant-design/icons'

interface props {
  id: idType
}

const AddVideoModal: React.FC<props> = ({ id }) => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)
  // const { id: categoryId } = useParams<{ id: string }>()
  const [form] = Form.useForm()
  const { isLoading, mutate } = useMutation(VideosService.create, {
    onSuccess: () => {
      message.success('Видео успешно добавлено')
      queryClient.invalidateQueries([CATEGORY_VIDEO_BLOCK, id])
      handleChangeVisible()
      form.resetFields()
    },
    onError: () => {
      message.error('Ошибка добавления видео в подборку')
    },
  })
  const onFinish = (data: VideoData) => mutate(data)

  const formId = 'add-video-form'
  return (
    <React.Fragment>
      <Button icon={<VideoCameraOutlined />} onClick={handleChangeVisible} type={'primary'}>
        Добавить видео вручную
      </Button>
      <Modal
        title={'Добавить видео вручную'}
        onCancel={handleChangeVisible}
        confirmLoading={isLoading}
        okText={'Добавить'}
        okButtonProps={{ form: formId, htmlType: 'submit' }}
        visible={visible}
        destroyOnClose
      >
        <Form id={formId} form={form} onFinish={onFinish} layout={'vertical'}>
          <Form.Item name={'video_block_id'} hidden initialValue={id}>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={'Заголовок'} rules={[{ required: true }]}>
            <Input placeholder={'Введите заголовок'} />
          </Form.Item>
          <Form.Item name={'link'} label={'Ссылка'} rules={[{ required: true }]}>
            <Input placeholder={'Введите ссылку'} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default AddVideoModal
