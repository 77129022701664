import makeRequest from '@api/makeRequest'
import { IFileManagerData } from '@app/interfaces/file-manager'

const resourceUrl = 'https://resource.prod.bradex.ru/api/file-manager'

class FileManagerService {
  getPath({ path, params }: IFileManagerData) {
    return makeRequest({
      url: resourceUrl + '/dirs-files',
      method: 'POST',
      data: { path },
      params,
    })
  }

  uploadFile(data: FormData) {
    return makeRequest({
      url: resourceUrl + '/upload-file',
      method: 'POST',
      data,
    })
  }

  renameFile(data: { filePath: string; newFilePath: string }) {
    return makeRequest({
      url: resourceUrl + '/rename-file',
      method: 'POST',
      data,
    })
  }

  deleteFile(data: { filePath: string }) {
    return makeRequest({
      url: resourceUrl + '/delete-file',
      method: 'POST',
      data,
    })
  }

  createDirectory(dir: string) {
    return makeRequest({
      url: resourceUrl + '/create-directory',
      method: 'POST',
      data: { dir },
    })
  }

  renameDirectory(data: { currentPath: string; newPath: string }) {
    return makeRequest({
      url: resourceUrl + '/rename-directory',
      method: 'POST',
      data,
    })
  }

  deleteDirectory(dir: string) {
    return makeRequest({
      url: resourceUrl + '/delete-directory',
      method: 'POST',
      data: { dir },
    })
  }
}

export default new FileManagerService()
