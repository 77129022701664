import React, { useState } from 'react'
import { SET_PRODUCT_QUERY_KEY, useSetProduct } from '@app/hooks/query/set-products'
import { useParams } from 'react-router-dom'
import { Button, Col, Descriptions, message, PageHeader, Row, Skeleton, Space, Table } from 'antd'
import { DeleteModal, ErrorBoundary, SeoCard, SiteLink } from '@app/shared'
import { SetProductsPartnersLinkService } from '@app/api'
import { IPartner } from '@app/interfaces/partner'
import { ISetProductPartnerLink } from '@app/interfaces/sets'
import SetProductPartnerLinkForm from '../../shared/set-product-partner-link-form/set-product-partner-link-form'
import { idType } from '@app/interfaces'
import { useMutation } from 'react-query'
import { DeleteOutlined } from '@ant-design/icons'

export interface SetProductPageProps {}

const SetProductPage: React.FC<SetProductPageProps> = () => {
  const [selectedPartners, setSelectedPartners] = useState<idType[]>([])
  const onSelectItem = async (selectedRowKeys: React.ReactText[]) => {
    setSelectedPartners(selectedRowKeys)
  }
  const { productId } = useParams<{ productId: string }>()
  const { isLoading, isError, data, refetch } = useSetProduct(productId)

  const deleteMultiple = useMutation(SetProductsPartnersLinkService.deleteMultiple, {
    onSuccess: () => {
      refetch()
      message.success('Партнеры успешно удалены')
      setSelectedPartners([])
    },
    onError: () => {
      message.error('Ошибка удаления')
    },
  })

  const handleDeleteMultiple = () => deleteMultiple.mutate(selectedPartners)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  return (
    <>
      <PageHeader
        title={data?.product.title}
        extra={<SiteLink slug={'sets/' + data?.set.slug + '/product/' + data?.product.slug} />}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={16}>
          <Descriptions column={1} bordered>
            <Descriptions.Item label={'ID'}>{data?.id}</Descriptions.Item>
            <Descriptions.Item label={'ID продукта'}>{data?.product_id}</Descriptions.Item>
            <Descriptions.Item label={'Наименование'}>{data?.product.title}</Descriptions.Item>
            <Descriptions.Item label={'Старая цена'}>{data?.old_price}</Descriptions.Item>
            <Descriptions.Item label={'Новая цена'}>{data?.price}</Descriptions.Item>
            <Descriptions.Item label={'Порядковый номер'}>{data?.sort_order}</Descriptions.Item>
            <Descriptions.Item label={'Артикул'}>{data?.product.sku}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} xl={8}>
          {data && (
            <SeoCard
              title={data.page.title}
              description={data.page.description}
              keywords={data.page.keywords}
            />
          )}
        </Col>
        <Col xs={24}>
          <PageHeader
            title={'Список партнеров'}
            extra={<SetProductPartnerLinkForm productId={productId} />}
          />
          <Table
            rowKey={'id'}
            dataSource={data?.set_product_partner_links}
            title={() => (
              <Button
                disabled={!selectedPartners.length || deleteMultiple.isLoading}
                loading={deleteMultiple.isLoading}
                type={'primary'}
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteMultiple}
              >
                Удалить несколько
              </Button>
            )}
            rowSelection={{
              selectedRowKeys: selectedPartners,
              onChange: onSelectItem,
            }}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
              },
              {
                title: 'Наименование',
                dataIndex: 'partner',
                render: (partner: IPartner) => partner.title,
              },
              {
                title: 'Ссылка',
                dataIndex: 'link',
              },
              {
                title: 'Порядковый номер',
                dataIndex: 'order',
              },
              {
                title: 'Действия',
                align: 'right',
                render: (entity: ISetProductPartnerLink) => (
                  <Space>
                    <SetProductPartnerLinkForm partnerLink={entity} productId={productId} />
                    <DeleteModal
                      id={entity.id}
                      deleteFunction={SetProductsPartnersLinkService.delete}
                      invalidateQueryKey={SET_PRODUCT_QUERY_KEY}
                    />
                  </Space>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  )
}

export default SetProductPage
