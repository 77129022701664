import React, { useState } from 'react'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  IHomePage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { Button, Col, Drawer, Form, Input, Row, Skeleton, Typography } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  SpaceWithDivider,
} from '@app/shared'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const HomePageEditor: React.FC<props> = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)
  const pageType: StaticContentType = 'home'
  const formId = 'home-page-editor-form'
  const update = useUpdateStaticContent(pageType)

  const { isLoading, isError, data } = useStaticContent<IStaticContentResponse<IHomePage>>(
    pageType,
    {
      enabled: visible,
    },
  )
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IHomePage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <Button type={'primary'} icon={<EditOutlined />} onClick={handleChangeVisible}>
        Редактор главной страницы
      </Button>
      <Drawer
        visible={visible}
        onClose={handleChangeVisible}
        width={'100%'}
        title={
          <SpaceWithDivider>
            Редактор главной страницы
            <SiteLink entityType={''} />
          </SpaceWithDivider>
        }
        destroyOnClose
        footer={
          <SpaceWithDivider>
            <Button form={formId} type={'primary'} htmlType={'submit'}>
              Сохранить
            </Button>
            <Button type={'default'} onClick={handleChangeVisible}>
              Отменить
            </Button>
          </SpaceWithDivider>
        }
      >
        <Form
          form={form}
          onFinish={onFinish}
          id={formId}
          layout={'vertical'}
          initialValues={data?.data}
        >
          <Typography.Title level={4}>Заголовок H1</Typography.Title>
          <Form.Item name={['page', 'h1']}>
            <Input placeholder={'Введите заголовок H1'} />
          </Form.Item>
          <Typography.Title level={4}>SEO</Typography.Title>
          <FormSeoInputs isPageTitleRequired={false} />
          <Typography.Title level={4}>Слайдер</Typography.Title>
          <Form.Item label={'Слайды'}>
            <Form.List name={'slides'}>
              {(fields, { add, move, remove }) => (
                <Row gutter={[24, 24]}>
                  {fields.map(({ fieldKey, key, name }) => (
                    <Col xs={24} xl={12} key={key}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <Form.Item
                            name={[name, 'slide_name']}
                            label={'Название слайда'}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder={'Введите название слайда'} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                          <Typography.Title level={5}>Основной блок</Typography.Title>
                          <Form.Item name={[name, 'mobile_image']} valuePropName={'src'}>
                            <ImageWithFallback preview height={50} />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'mobile_image']}
                            label={'Изображение (смартфоны)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.mobile_image,
                            )}
                          />
                          <Form.Item name={[name, 'tablet_portrait_image']} valuePropName={'src'}>
                            <ImageWithFallback preview height={50} />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'tablet_portrait_image']}
                            label={'Изображение (планшет портретный)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.tablet_portrait_image,
                            )}
                          />
                          <Form.Item name={[name, 'tablet_landscape_image']} valuePropName={'src'}>
                            <ImageWithFallback preview height={50} />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'tablet_landscape_image']}
                            label={'Изображение (планшет ландшафтный)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.tablet_landscape_image,
                            )}
                          />
                          <Form.Item name={[name, 'pc_image']} valuePropName={'src'}>
                            <ImageWithFallback preview height={50} />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'pc_image']}
                            label={'Изображение (ПК)'}
                            initialPath={getStaticPageImagePath(data?.data.slides[name]?.pc_image)}
                          />

                          <Form.Item
                            fieldKey={fieldKey}
                            label={'Заголовок'}
                            name={[name, 'title']}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            fieldKey={fieldKey}
                            label={'Подзаголовок'}
                            name={[name, 'subtitle']}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            fieldKey={fieldKey}
                            label={'Ссылка подробнее'}
                            name={[name, 'url']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                          <Typography.Title level={5}>Фиксированный блок</Typography.Title>

                          <Form.Item name={[name, 'fixed', 'mobile_image']} valuePropName={'src'}>
                            <ImageWithFallback height={50} preview />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'fixed', 'mobile_image']}
                            label={'Изображение (смартфоны)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.fixed.mobile_image,
                            )}
                          />
                          <Form.Item
                            name={[name, 'fixed', 'tablet_portrait_image']}
                            valuePropName={'src'}
                          >
                            <ImageWithFallback height={50} preview />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'fixed', 'tablet_portrait_image']}
                            label={'Изображение (планшет портретный)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.fixed.tablet_portrait_image,
                            )}
                          />

                          <Form.Item
                            name={[name, 'fixed', 'tablet_landscape_image']}
                            valuePropName={'src'}
                          >
                            <ImageWithFallback height={50} preview />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'fixed', 'tablet_landscape_image']}
                            label={'Изображение (планшет ландшафтный)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.fixed.tablet_landscape_image,
                            )}
                          />
                          <Form.Item name={[name, 'fixed', 'pc_image']} valuePropName={'src'}>
                            <ImageWithFallback height={50} preview />
                          </Form.Item>
                          <FormFileManagerSelect
                            fieldKey={fieldKey}
                            formInstance={form}
                            pathFieldName={['slides', name, 'fixed', 'pc_image']}
                            label={'Изображение (ПК)'}
                            initialPath={getStaticPageImagePath(
                              data?.data.slides[name]?.fixed.pc_image,
                            )}
                          />

                          <Form.Item
                            fieldKey={fieldKey}
                            label={'Заголовок'}
                            name={[name, 'fixed', 'title']}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                  <Col xs={24}>
                    <Button type={'dashed'} onClick={() => add()} icon={<PlusOutlined />} block>
                      Добавить слайд
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default HomePageEditor
