import { useQuery, UseQueryOptions } from 'react-query'
import { PromotionsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import {IPromotion, IPromotionsResponse} from "@app/interfaces/promotions"

export const PROMOTIONS_KEY = 'promotions_key'

export function usePromotions(options?: UseQueryOptions<IPromotionsResponse, Error>) {
  return useQuery<IPromotionsResponse, Error>(
    PROMOTIONS_KEY,
    () => PromotionsService.getAll().then((response: AxiosResponse<IPromotionsResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function usePromotion(id: idType, options?: UseQueryOptions<IPromotion, Error>) {
  return useQuery<IPromotion, Error>(
    [PROMOTIONS_KEY, id],
    () => PromotionsService.getById(id).then((response: AxiosResponse<IPromotion>) => response.data),
    {
      ...options,
    },
  )
}
