import React from 'react'
import { Card, Col, Divider, Form, Input, Row, Space, Switch } from 'antd'
import {
  FormButtons,
  FormDatePeriod,
  FormFileManagerSelect,
  FormOrderInput,
  FormSelectBannerGroups,
  FormSelectCategories,
  ImageWithFallback,
} from '@app/shared'
import { IBanner } from '@app/interfaces/banners'
import { idType } from '@app/interfaces'
import moment from 'moment'
import FormBannerLink from '@app/shared/form-banner-link/form-banner-link'

interface props {
  banner?: IBanner
  submitFunction: (banner: IBanner, id?: idType) => void
  isLoading: boolean
}

const BannerForm: React.FC<props> = ({ banner, submitFunction, isLoading }) => {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const bannerValues: IBanner = {
      ...values,
      link: values.link || null,
      link_category_id: values.link_category_id || null,
      promotag_id: values.promotag_id || null,
      external_link: values.external_link || false,
      date_start: values.date && moment(values.date[0]).format('YYYY-MM-DD'),
      date_finish: values.date && moment(values.date[1]).format('YYYY-MM-DD'),
    }
    submitFunction(bannerValues, banner?.id)
  }

  const handleLinkTypeChange = () => {
    form.setFieldsValue({
      link: null,
      category_link_id: null,
      external_link: false,
      promotag_id: null,
    })
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{
        external_link: false,
        active: true,
        ...banner,
        date:
          banner?.date_start && banner.date_finish
            ? [moment(banner?.date_start), moment(banner?.date_finish)]
            : undefined,
      }}
    >
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={14}>
          <Card bordered={false} title={'Общая информация'}>
            <Form.Item name={'link_title'} label={'Заголовок'}>
              <Input placeholder={'Введите заголовок'} />
            </Form.Item>
            <FormBannerLink
              formInstance={form}
              isCategoryLinkSelected={!!banner?.link_category_id}
              categoryLinkName={'link_category_id'}
              customLinkName={'link'}
              isExternalLinkName={'external_link'}
              promotagName={'promotag_id'}
              handleLinkTypeChange={handleLinkTypeChange}
            />
            <Space split={<Divider type={'vertical'} />}>
              <FormOrderInput />
              <FormDatePeriod required={false} />
            </Space>
            <FormSelectCategories />
            <FormSelectBannerGroups />
            <Form.Item
              name={'active'}
              label={'Статус'}
              rules={[{ required: true }]}
              valuePropName={'checked'}
            >
              <Switch checkedChildren={'Включен'} unCheckedChildren={'Отключен'} />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} xl={10}>
          <Card bordered={false} title={'Изображения'}>
            <ImageWithFallback src={banner?.mobile} />
            <FormFileManagerSelect
              initialPath={banner?.mobile_image.path}
              formInstance={form}
              label={'Мобильная версия'}
              filenameFieldName={['mobile_image', 'filename']}
              pathFieldName={['mobile_image', 'path']}
              required
            />
            <ImageWithFallback src={banner?.tablet} />
            <FormFileManagerSelect
              initialPath={banner?.tablet_image.path}
              formInstance={form}
              label={'Планшетная версия'}
              filenameFieldName={['tablet_image', 'filename']}
              pathFieldName={['tablet_image', 'path']}
              required
            />
            <ImageWithFallback src={banner?.desktop} />
            <FormFileManagerSelect
              initialPath={banner?.desktop_image.path}
              formInstance={form}
              label={'Версия для ПК'}
              filenameFieldName={['desktop_image', 'filename']}
              pathFieldName={['desktop_image', 'path']}
              required
            />
          </Card>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default BannerForm
