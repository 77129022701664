import React from 'react'
import { message, PageHeader } from 'antd'
import { BannersGroupForm, PageInfo } from '@app/shared'
import { useMutation } from 'react-query'
import { BannerGroupsService } from '@app/api'
import { useHistory } from 'react-router-dom'
import { IBannerGroup } from '@app/interfaces/banner-groups'

interface props {}

const AddBannersGroupPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { isLoading, mutateAsync } = useMutation(BannerGroupsService.create, {
    onSuccess: () => {
      message.success('Группа успешно добавлена')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая группа уже существует')
    },
  })

	const submitFunction = (group: IBannerGroup) => mutateAsync(group)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить группу баннеров'} />
      <PageHeader title={'Добавить группу баннеров'} />
      <BannersGroupForm isLoading={isLoading} submitFunction={submitFunction}/>
    </React.Fragment>
  )
}

export default AddBannersGroupPage
