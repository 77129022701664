import React from 'react'
import { useBannerGroups } from '@app/hooks/query/banner-groups'
import { PageHeader, Skeleton } from 'antd'
import { AddLink, ErrorBoundary, PageInfo } from '@app/shared'
import { BannerGroupsTable } from '@app/pages/banners-groups-page/component'

interface props {}

const BannersGroupsPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useBannerGroups()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Группы баннеров'} />
      <PageHeader
        title={'Группы баннеров'}
        extra={[<AddLink route={'add_banners_group'} key={'1'} />]}
      />
      <BannerGroupsTable pagination={pagination} banner_groups={data} />
    </React.Fragment>
  )
}

export default BannersGroupsPage
