import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { ISmi, ISmiResponse } from '@app/interfaces/smi'
import { SmiService } from '@app/api'
import { idType } from '@app/interfaces'

export const SMI_LIST_KEY = 'smi_list_key'
export const SMI_KEY = 'smi_key'

export function useSmiList(options?: UseQueryOptions<ISmiResponse, Error>) {
  return useQuery<ISmiResponse, Error>(
    SMI_LIST_KEY,
    () => SmiService.getList().then((response: AxiosResponse<ISmiResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useSmi(id: idType, options?: UseQueryOptions<ISmi, Error>) {
  return useQuery<ISmi, Error>(
    [SMI_KEY, id],
    () => SmiService.getById(id).then((response: AxiosResponse<ISmi>) => response.data),
    {
      ...options,
    },
  )
}
