import { useQuery, UseQueryOptions } from 'react-query'
import { BannerGroupsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { IBannerGroup, IBannerGroupsResponse } from '@app/interfaces/banner-groups'
import { idType } from '@app/interfaces'

export const BANNER_GROUPS_KEY = 'banner_groups_key'
export const BANNER_GROUPS_FILTER_KEY = 'banner_groups_filter_key'

export function useBannerGroups(options?: UseQueryOptions<IBannerGroupsResponse, Error>) {
  return useQuery<IBannerGroupsResponse, Error>(
    BANNER_GROUPS_KEY,
    () =>
      BannerGroupsService.getAll().then(
        (response: AxiosResponse<IBannerGroupsResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useBannerGroup(id: idType, options?: UseQueryOptions<IBannerGroup, Error>) {
  return useQuery<IBannerGroup, Error>(
    [BANNER_GROUPS_KEY, id],
    () =>
      BannerGroupsService.getById(id).then(
        (response: AxiosResponse<IBannerGroup>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useBannerGroupsFilter(options?: UseQueryOptions<IBannerGroup[], Error>) {
  return useQuery<IBannerGroup[], Error>(BANNER_GROUPS_FILTER_KEY, () =>
    BannerGroupsService.getFilterBannerGroups().then(
      (response: AxiosResponse<IBannerGroup[]>) => response.data,
    ),
  )
}
