import React from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router-dom'

interface props {}

const NotFoundPage: React.FC<props> = () => {
  const { goBack } = useHistory()


  return (
    <Result
      status="404"
      title="404"
      subTitle="Ошибка! Запрашиваемая страница не найдена."
      extra={
        <Button onClick={goBack} type="primary">
					Вернуться назад
        </Button>
      }
    />
  )
}

export default NotFoundPage
