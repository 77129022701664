import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Radio, Row } from 'antd'
import { FormSelectCategories, FormSelectPromotags } from '@app/shared'
import { SelectValue } from 'antd/es/select'
import { useCategoriesFilters } from '@app/hooks/query/categories'
import { NamePath } from 'antd/lib/form/interface'
import env from '@sanchik97/react-dotenv-public-url'

type InputFieldName = string | (number | string)[]
interface props {
  name?: number
  formInstance: FormInstance
  isCategoryLinkSelected?: boolean
  categoryLinkName?: InputFieldName
  customLinkName?: InputFieldName
  isExternalLinkName?: InputFieldName
  promotagName?: InputFieldName
  handleLinkTypeChange: () => void
}

const FormBannerLink: React.FC<props> = ({
  name,
  formInstance,
  isCategoryLinkSelected = true,
  categoryLinkName,
  customLinkName,
  isExternalLinkName,
  promotagName,
  handleLinkTypeChange,
}) => {
  const { data: categories } = useCategoriesFilters({
    refetchOnMount: false,
  })

  const getSelectedCategory = (v: SelectValue): any | undefined => {
    return categories?.find(({ id }) => id === Number(v))
  }
  const getSelectedCategorySlug = () => {
    return getSelectedCategory(formInstance.getFieldValue(categoryLinkName as NamePath))?.slug
  }

  const [isCategory, setIsCategory] = useState<boolean>(isCategoryLinkSelected)
  const handleSetIsCategory = () => {
    setIsCategory(!isCategory)
    handleLinkTypeChange()
  }
  const [selectedCategorySlug, setSelectedCategorySlug] = useState<string | undefined>(
    getSelectedCategorySlug(),
  )

  useEffect(() => {
    setSelectedCategorySlug(getSelectedCategorySlug())
  }, [categories])

  const handleCategoryChange = (v: SelectValue) => {
    const selectedCategory = getSelectedCategory(v)

    if (name) {
      formInstance.setFieldsValue([
        {
          name: ['slots', name, 'link_title'],
          value: selectedCategory?.title,
        },
      ])
    } else {
      formInstance.setFieldsValue({
        link_title: selectedCategory?.title,
      })
    }

    setSelectedCategorySlug(selectedCategory?.slug)
  }

  return (
    <Form.Item label={'Ссылка'}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Radio.Group defaultValue={isCategory} onChange={handleSetIsCategory}>
            <Radio.Button value={true}>Ссылка на категорию</Radio.Button>
            <Radio.Button value={false}>Указать ссылку вручную</Radio.Button>
          </Radio.Group>
        </Col>
        {isCategory ? (
          <Col xs={24}>
            <Form.Item>
              <FormSelectCategories
                name={categoryLinkName}
                selectProps={{ onChange: handleCategoryChange }}
                required={true}
              />
              {selectedCategorySlug && (
                <a
                  href={env.SHOP_URL + env.SHOP_CATEGORY_URL + '/' + selectedCategorySlug}
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                >
                  {env.SHOP_URL + env.SHOP_CATEGORY_URL + '/' + selectedCategorySlug}
                </a>
              )}
            </Form.Item>
            <FormSelectPromotags formInstance={formInstance} required={false} name={promotagName} />
          </Col>
        ) : (
          <React.Fragment>
            <Col flex={1}>
              <Form.Item name={customLinkName} label={'URL'}>
                <Input placeholder={'Введите URL'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={'Тип ссылки'} name={isExternalLinkName}>
                <Radio.Group>
                  <Radio.Button value={false}>Внутренняя</Radio.Button>
                  <Radio.Button value={true}>Внешняя</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </Form.Item>
  )
}

export default FormBannerLink
