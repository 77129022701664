import React from 'react'
import { NewsForm, PageInfo } from '@app/shared'
import { message, PageHeader } from 'antd'
import { useMutation } from 'react-query'
import { NewsService } from '@app/api'
import { useHistory } from 'react-router-dom'

interface props {}

const AddNewsPage: React.FC<props> = () => {
  const { goBack } = useHistory()
  const { isLoading, mutateAsync } = useMutation(NewsService.create, {
    onSuccess: () => {
      message.success('Новость успешно добавлена')
      goBack()
    },
    onError: () => {
      message.error('Ошибка! Возможно, такая новость уже существует')
    },
  })

  const submitFunction = (data: any) => mutateAsync(data)

  return (
    <React.Fragment>
      <PageInfo title={'Добавить новость'} />
      <PageHeader title={'Добавить новость'} />
      <NewsForm submitFunction={submitFunction} isLoading={isLoading} />
    </React.Fragment>
  )
}

export default AddNewsPage
